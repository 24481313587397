import { defineComponent as _defineComponent } from 'vue'
import { createElementVNode as _createElementVNode, unref as _unref, createTextVNode as _createTextVNode, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

import { useBulkPermissionWizard } from '@/store/modules/useBulkPermissionWizard';
import { BCard, BFormCheckbox } from 'bootstrap-vue-next';


export default /*@__PURE__*/_defineComponent({
  __name: 'BulkIntroduction',
  setup(__props) {

const store = useBulkPermissionWizard();

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", null, [
    _cache[1] || (_cache[1] = _createElementVNode("p", { class: "mb-0" }, " This wizard will guide you through the process of adding users to multiple tenants at once. ", -1)),
    _cache[2] || (_cache[2] = _createElementVNode("p", null, null, -1)),
    _cache[3] || (_cache[3] = _createElementVNode("p", null, " Here are the steps: ", -1)),
    _cache[4] || (_cache[4] = _createElementVNode("ol", null, [
      _createElementVNode("li", null, " Select the users you want to onboard "),
      _createElementVNode("li", null, " Select the roles per tenant you want to assign to all selected users "),
      _createElementVNode("li", null, " Review new permissions "),
      _createElementVNode("li", null, " Start the execution ")
    ], -1)),
    _createVNode(_unref(BCard), null, {
      default: _withCtx(() => [
        _createVNode(_unref(BFormCheckbox), {
          checked: _unref(store).skipIntro,
          switch: "",
          onChange: _unref(store).toggleSkipIntro
        }, {
          default: _withCtx(() => _cache[0] || (_cache[0] = [
            _createTextVNode(" Don't show this page again ")
          ])),
          _: 1
        }, 8, ["checked", "onChange"])
      ]),
      _: 1
    })
  ]))
}
}

})