<template>
  <div id="tenantContacts">
    <h5>Contacts</h5>

    <BRow class="gap-2">
      <BCol
        v-if="approversData.length > 0"
      >
        <CollapsibleComponent
          :is-header="false"
          header="Approvers"
          :is-visible="false"
          :toggle-id="'tenantContactsApprovers'"
        >
          <BTable
            striped
            hover
            :items="approversData"
            :fields="contactsFields"
          />
        </CollapsibleComponent>
      </BCol>

      <BCol
        v-if="escalatorsData.length > 0"
      >
        <CollapsibleComponent
          :is-header="false"
          header="Escalation Contacts"
          :is-visible="false"
          :toggle-id="'tenantContactsEscalators'"
        >
          <BTable
            striped
            hover
            :items="escalatorsData"
            :fields="contactsFields"
          />
        </CollapsibleComponent>
      </BCol>

      <BCol
        v-if="responsiblesData.length > 0"
      >
        <CollapsibleComponent
          :is-header="false"
          header="Responsibles"
          :is-visible="false"
          :toggle-id="'tenantContactsResponsibles'"
        >
          <BTable
            striped
            hover
            :items="responsiblesData"
            :fields="contactsFields"
          />
        </CollapsibleComponent>
      </BCol>
    </BRow>
  </div>
</template>

<script setup lang="ts">
import { computed } from 'vue';
import { BRow, BCol, BTable } from 'bootstrap-vue-next';
import CollapsibleComponent from '@/components/CollapsibleComponent.vue';

const props = defineProps({
  approvers: {
    type: Array,
    default() {
      return [];
    },
  },
  escalators: {
    type: Array,
    default() {
      return [];
    },
  },
  responsibles: {
    type: Array,
    default() {
      return [];
    },
  },
});

const contactsFields = [
  {
    key: 'email',
    label: 'Email',
    sortable: true,
    sortDirection: 'asc',
    class: 'align-middle',
  },
];

const approversData = computed(() => props.approvers.map((a) => ({ email: a })));

const escalatorsData = computed(() => props.escalators.map((a) => ({ email: a })));

const responsiblesData = computed(() => props.responsibles.map((a) => ({ email: a })));

</script>
