<template>
  <div id="addFirefighter">
    <div class="row">
      <div class="col-offset-2 col-12">
        <div>
          <div class="form-group">
            <div class="row-cols-md-8">
              <autocomplete
                ref="emailAutocomplete"
                base-class="uc-autocomplete"
                placeholder="Email address"
                :search="search"
                :get-result-value="(u: Suggestion) => u.username"
                @submit="onSelectEmail"
                @change="onUpdateEmail"
              />
              <div
                v-if="typeaheadProcess"
                style="position: absolute; top: 25px; right: 36px; z-index: 1000"
              >
                <BSpinner
                  v-if="typeaheadProcess"
                  label="Loading..."
                  small
                />
              </div>
              <div
                v-if="!usernameIsValid"
                class="invalid-feedback d-block"
              >
                Please enter a valid email address.
              </div>
              <div
                v-if="usernameIsValid && userNotFound"
                id="ffUserNotFoundError"
                class="invalid-feedback d-block"
              >
                We couldn't find any users with this email address.
              </div>
            </div>
            <br>
            <div class="row-cols-md-8">
              <DropDownListComponent
                id="roleSelectorFirefighter"
                v-model="role"
                class="mb-3"
                :default-option="SelectOptionsDropDown.ROLE"
                :options="roleOptions"
                @select-value="updateRole"
              />
              <div
                v-if="!roleIsValid"
                class="invalid-feedback d-block"
              >
                You need to choose a role that should be granted to the user.
              </div>
            </div>
          </div>
          <div class="form-row form-group mt-1">
            <BRow>
              <div class="col-6">
                <DropDownListComponent
                  v-model="duration"
                  name="duration"
                  class="mb-3"
                  :options="durationOptions"
                  :default-option="SelectOptionsDropDown.DURATION"
                  @select-value="updateDuration"
                />
                <div
                  v-if="!durationIsValid"
                  class="invalid-feedback d-block"
                >
                  Please enter a duration.
                </div>
              </div>
              <div class="col-6">
                <BFormTextarea
                  v-model="reason"
                  placeholder="Reason..."
                />
                <div
                  v-if="!reasonIsValid"
                  class="invalid-feedback d-block"
                >
                  Please enter a reason.
                </div>
              </div>
            </BRow>
          </div>
          <div class="form-row form-group">
            <BFormCheckbox
              v-model="isGrantNow"
              switch
              name="isGrantNow"
            >
              Grant now
            </BFormCheckbox>
            <div
              v-if="!scheduledTimestampIsValid"
              class="invalid-feedback d-block"
            >
              The chosen date and time need to be valid and in the future.
            </div>
          </div>
          <div
            v-if="!isGrantNow"
            id="scheduledFirefighterPicker"
            class="form-row form-group"
          >
            <BContainer>
              <BRow>
                <!-- eslint-disable-next-line vue/max-len -->
                <!-- eslint-disable-next-line vuejs-accessibility/click-events-have-key-events -->
                <p
                  id="time-zone"
                  class="text-muted"
                  @click="resetTimeZone"
                >
                  Time zone: {{ timeZone }}

                  <font-awesome-icon
                    v-if="timeZone !== localTimeZone"
                    icon="exclamation-triangle"
                    color="orange"
                    class="ms-1"
                  />
                </p>

                <BTooltip
                  v-if="timeZone !== localTimeZone"
                  target="time-zone"
                  triggers="hover"
                >
                  You followed a firefighter request link with a different time zone. Click to
                  use your local time zone instead
                </BTooltip>
              </BRow>
            </BContainer>
            <div class="col">
              <BFormInput
                id="datePicker"
                v-model="scheduledDate"
                type="date"
                name="scheduledDate"
                locale="en"
              />

              <div
                v-if="!scheduledDateIsValid"
                class="invalid-feedback d-block"
              >
                Please enter a valid date
              </div>
            </div>
            <div class="col">
              <BFormInput
                id="scheduled-time"
                v-model="scheduledTime"
                type="time"
                button-only
                right
                now-button
                label-now-button="Now"
                name="scheduledTime"
                aria-controls="scheduled-time"
              />
              <div
                v-if="!scheduledTimeIsValid"
                class="invalid-feedback d-block"
              >
                Please enter a valid time (HH:mm)
              </div>
            </div>
          </div>
          <div class="d-flex flex-row-reverse gap-2 mt-4">
            <AsyncButton
              id="addFirefighterAction"
              size="md"
              variant="primary"
              class="ml-4"
              :name="`Add Firefighter`"
              initial-icon="user-plus"
              :confirm-modal="`Confirm`"
              :disabled="!showAddButton"
              :confirm-modal-config="'No message'"
              :fn="() => sendForm()"
              modal-size="lg"
              @status="updateStatus"
            >
              <div class="mb-4">
                <p>
                  Please take a few seconds to review the information. You are about to grant the
                  following critical production access
                </p>
                <BTable
                  small
                  :fields="[
                    { key: 'username', label: 'User' },
                    { key: 'tenantName', label: 'Tenant' },
                    { key: 'role', label: 'Role' },
                    { key: 'duration', label: 'Duration' },
                  ]"
                  :items="[{
                    username, tenantName, role, duration,
                  }]"
                />
                <p v-if="!isGrantNow && scheduledTimestampIsValid">
                  The access will be granted at
                  {{ getScheduledString() }}.
                </p>
                <BAlert
                  id="external-member-warning"
                  :model-value="!userIsTenantMember"
                  variant="danger"
                >
                  <strong>Attention:</strong>
                  User does not have any roles in your tenant.
                  Please take this into account when deciding whether to
                  grant the Firefighter access. Granting Firefighter access to a user who
                  is not currently in the tenant will also grant them temporary access
                  to non-production stages and resources.
                </BAlert>
                <p>Do you want to continue?</p>
              </div>
            </AsyncButton>
            <BButton
              variant="secondary"
              @click="onHide(); emit('hide')"
            >
              Cancel
            </BButton>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">

import {
  computed, nextTick, onBeforeMount, PropType, reactive, ref, watch,
  defineProps,
  watchEffect,
} from 'vue';
import {
  BAlert, BButton, BCollapse, BSpinner, BFormTextarea, BFormCheckbox, BContainer,
  BRow, BTooltip, BFormInput, BTable,
} from 'bootstrap-vue-next';
import axios from 'axios';
import { parseISO } from 'date-fns';
import { formatInTimeZone, zonedTimeToUtc } from 'date-fns-tz';
import { useRoute } from 'vue-router';
import Autocomplete from '@trevoreyre/autocomplete-vue';
import AsyncButton from '@/components/AsyncButton.vue';
import DropDownListComponent from '@/components/DropDownListComponent.vue';
import { getKeycloakRequestConfig } from '@/utils/http';
import { AsyncButtonStatus } from '@/models/asyncButton';
import { Permission } from '@/models/keycloak';
import { SelectOptionsDropDown, TenantInformation } from '@/models/tenant';
import useTenantComponent from '@/composable/tenantComponent';
import Validator from '@/utils/validator';
import { FirefighterAccessRepresentation } from '@/models/firefighter';
import { userStore } from '@/store/modules/user';
import { BackendError } from '@/models/errors';
import { useAsyncButton } from '@/composable/apiStates';
import { Suggestion } from '@/models/typeahead';
import { useTenantRoles } from '@/composable/useTenantRoles';
import { createDuration } from '@/composable/durationSelector';
import Utils from '@/utils/utils';

const props = defineProps({
  tenantName: {
    type: String,
    required: true,
  },
  tenantInfo: {
    type: Object as PropType<TenantInformation>,
    required: true,
  },
  tenantMembers: {
    type: Array,
    required: true,
  },
  visible: {
    type: Boolean,
    required: true,
  },
  successfulEvent: {
    type: Function,
    required: true,
  },
  firefighterAccesses: {
    type: Object as PropType<FirefighterAccessRepresentation[]>,
    required: true,
  },
});

const emit = defineEmits(['success', 'error', 'successfulEvent', 'hide']);

const route = useRoute();
const user = userStore();
const scheduledTime = ref('08:00');
const isGrantNow = ref(true);
const scheduledDate = ref(null);
const timeZone = ref(null);
const localTimeZone = Intl.DateTimeFormat()
  .resolvedOptions().timeZone || 'UTC';
const status = useAsyncButton();
const emailAutocomplete = ref(null);
const { roleOptions } = useTenantRoles(props.tenantInfo.roles, true);
const error: BackendError = reactive({
  type: null,
  message: null,
});
let { userTypeaheadSuggestions } = useTenantComponent();
const {
  username, duration, reason, typeaheadProcess,
  usernameIsValid, showUserMissing, getTypeaheadUser,
  role, roleIsValid,
} = useTenantComponent();

const userIsTenantMember = computed(() => props.tenantMembers
  .map((m: any) => m.username)
  .includes(username.value));

const { durationOptions } = createDuration();

const scheduledTimestampIsValid = computed(() => {
  if (isGrantNow.value) {
    return true;
  }
  return Validator.isTimestampValid(scheduledDate.value, scheduledTime.value);
});

const scheduledDateIsValid = computed(() => {
  if (isGrantNow.value) {
    return true;
  }
  return Validator.isDateIsValid(scheduledDate.value);
});

const userNotFound = ref(false);

// Debounced check for user existence
const debouncedCheckUser = Utils.debounce(async (input: string) => {
  if (input.length >= 3) {
    userTypeaheadSuggestions = await getTypeaheadUser(input);
    const found = showUserMissing(userTypeaheadSuggestions);
    userNotFound.value = !found;
  } else {
    userNotFound.value = false;
  }
}, 100);

const updateRole = (selectedRole: string) => {
  role.value = selectedRole;
  status.status = AsyncButtonStatus.INITIAL;
  status.message = null;
  error.type = null;
  error.message = null;
};

const updateDuration = (selectedDuration: string) => {
  // @ts-ignore
  duration.value = selectedDuration;
};

const getScheduledString = () => {
  if (!scheduledTimestampIsValid.value) {
    return '';
  }

  return `${scheduledDate.value} ${scheduledTime.value} (${timeZone.value})`;
};

const hasActiveFirefighterAccess = computed(() => {
  if (props.firefighterAccesses === undefined) return false;

  if (!role || !username) return false;

  return (
    props.firefighterAccesses.find(
      (access) => access.user.email === username.value && access.role === role.value,
    ) !== undefined
  );
});

const scheduledTimeIsValid = computed(() => {
  if (isGrantNow.value) {
    return true;
  }
  return Validator.isTimeIsValid(scheduledTime.value);
});

const durationIsValid = computed(() => {
  if (duration.value !== SelectOptionsDropDown.DURATION) {
    // @ts-ignore
    return duration.value > 0;
  }
  return false;
});

const reasonIsValid = computed(() => {
  if (reason.value != null) {
    return reason.value.trim().length > 0;
  }
  return false;
});

// Watch for changes in scheduledTime
// eslint-disable-next-line @typescript-eslint/no-unused-vars
watch(scheduledTime, (newValue, oldValue) => {
  // Remove seconds from scheduledTime on change
  scheduledTime.value = newValue ? newValue.split(':')
    .slice(0, 2)
    .join(':')
    : scheduledTime.value;
});

/**
* Resets the time zone to the users local timezone
*/
function resetTimeZone() {
  timeZone.value = Intl.DateTimeFormat()
    .resolvedOptions().timeZone || 'UTC';
}

/**
* Parses and decodes an anchor part of an URL (window.location.hash style) to a map.
* Example: '#key1=value1&key2=value2' -> { key: value1, key2: value2 }
* @param anchor part of a URL encoded key value pairs
* @returns key/value map
*/
function parseAnchor(encodedAnchorPart: string) {
  return encodedAnchorPart
    .slice(1)
    .split('&')
    .reduce((acc, pair) => {
      const [, key, value] = /^([^=]*)=(.*)$/g.exec(pair);
      acc[key] = decodeURIComponent(value);
      return acc;
    }, {});
}

async function onUpdateEmail(input: any) {
  userNotFound.value = false;
  if (input === null) {
    username.value = null;
    userNotFound.value = true;
  } else {
    username.value = input.target.value;
    debouncedCheckUser(username.value);
  }
}

async function onSelectEmail(input: Suggestion) {
  username.value = input.username;
}

const updateStatus = (statusBtn: AsyncButtonStatus, message: string) => {
  status.status = statusBtn;
  status.message = message;
  if (status.status === AsyncButtonStatus.INITIAL) {
    role.value = SelectOptionsDropDown.ROLE;
    username.value = '';
    emailAutocomplete.value.setValue({ username: '' });
    duration.value = SelectOptionsDropDown.DURATION;
    reason.value = '';
    scheduledTime.value = '';
    scheduledDate.value = '';
  }
};

onBeforeMount(async () => {
  resetTimeZone();
  // pre-populates the fields with values of hash parameters
  nextTick(async () => {
    let prefill: {
          username: string;
          role: string;
          duration: string;
          reason: string;
          startNow: string;
          startAt: string;
          timeZone: string;
        };

    try {
      prefill = parseAnchor(window.location.hash) as any;
    } catch (err) {
      // this can happen if there is nothing to parse in the URL
      return;
    }
    if (prefill.username) {
      const suggestions = await getTypeaheadUser(prefill.username);
      userTypeaheadSuggestions.push(...suggestions);
      username.value = prefill.username;
      emailAutocomplete.value.setValue({ username: prefill.username });

      // If the prefill includes a time zone, use that instead
      if (prefill.timeZone) {
        timeZone.value = prefill.timeZone;
      }

      try {
        const startAt = parseISO(prefill.startAt);
        scheduledTime.value = formatInTimeZone(startAt, timeZone.value, 'HH:mm');
        scheduledDate.value = formatInTimeZone(startAt, timeZone.value, 'yyyy-MM-dd');
      } catch (err) {
        scheduledDate.value = null;
        scheduledTime.value = '08:00';
      }

      const isValidRoleOption = roleOptions.value.map((o) => o.title).includes(prefill.role);
      if (isValidRoleOption) {
        role.value = prefill.role;
      }
      reason.value = prefill.reason;
      // @ts-ignore
      duration.value = parseInt(prefill.duration, 10);
      isGrantNow.value = prefill.startNow === 'true';
    }
  });
});

/**
* Resets all form fields
*/
function reset() {
  console.log('reset');
  // Reset form fields
  role.value = SelectOptionsDropDown.ROLE;
  username.value = '';
  if (emailAutocomplete.value) {
    emailAutocomplete.value.setValue({ username: '' });
  }

  duration.value = SelectOptionsDropDown.DURATION;
  reason.value = '';
  scheduledTime.value = '';
  scheduledDate.value = '';

  // Reset time zone in case it was changed, mainly from the prefill URL
  resetTimeZone();
}

function onHide() {
  reset();
}

async function search(input: string): Promise<Suggestion[]> {
  if (input.length < 3) {
    username.value = null;
    return [];
  }
  username.value = input;
  userTypeaheadSuggestions = await getTypeaheadUser(input);
  return userTypeaheadSuggestions;
}

async function sendForm() {
  const suggestion = userTypeaheadSuggestions.find((u) => u.username === username.value);

  const url = `/firefighter/tenants/${route.params.tenantName}/users/${suggestion.userId}/roles/${role.value}`;
  const body = {
    duration: Number(duration.value),
    reason: reason.value,
    startNow: isGrantNow.value,
    startAt: isGrantNow.value
      ? undefined
      : zonedTimeToUtc(
        `${scheduledDate.value} ${scheduledTime.value}:00`,
        timeZone.value,
      )
        .toISOString(),
    timeZone: timeZone.value,
  };
  try {
    const response = await axios.put<Permission>(
      url,
      body,
      getKeycloakRequestConfig(user.xsrfToken),
    );
    resetTimeZone();
    if (props.successfulEvent) {
      props.successfulEvent(response.data, !isGrantNow.value);
      emit('successfulEvent', response.data, !isGrantNow.value);

      reset();
    }
  } catch (err) {
    if (err.response && err.response.data) {
      error.message = err.response.data.message;
      emit('error', error.message);
    }
    throw error;
  }
}

const formIsValid = computed(() => {
  let hasActiveFirefighter = hasActiveFirefighterAccess.value;

  if (!isGrantNow.value) {
    // When trying to schedule a firefighter, it should
    // not matter whether the user already has the role
    //
    // In case of conflicts, the backend will throw an
    // error for this.
    hasActiveFirefighter = false;
  }

  return (
    usernameIsValid.value
    && !hasActiveFirefighter
    && roleIsValid.value
    && durationIsValid.value
    && reasonIsValid.value
    && scheduledDateIsValid.value
    && scheduledTimeIsValid.value
    && scheduledTimestampIsValid.value
    && !userNotFound.value
  );
});

watch(() => props.visible, () => {
  if (!props.visible) {
    reset();
  }
});

const showAddButton = computed(() => formIsValid.value);

</script>
