<template>
  <BButton
    :size="size"
    :class="buttonClass"
    :disabled="disabled || status !== AsyncButtonStatus.INITIAL"
    :variant="variant"
    @click="click"
  >
    <span v-if="status === AsyncButtonStatus.INITIAL">
      <font-awesome-icon
        class="cert-button-icon"
        :icon="initialIcon"
      />
    </span>
    <span
      v-if="status === AsyncButtonStatus.IN_PROGRESS"
      class="spinner-border spinner-border-sm"
      role="status"
      aria-hidden="true"
    />
    <span v-if="status === AsyncButtonStatus.SUCCESSFUL">
      <font-awesome-icon
        class="cert-button-icon"
        icon="check-circle"
      />
    </span>
    <span v-if="status === AsyncButtonStatus.ERROR">
      <font-awesome-icon
        class="cert-button-icon"
        icon="times-circle"
      />
    </span>
    {{ props.name }}
    <BModal
      v-if="!!modalData"
      :id="modalId"
      ref="modalRef"
      title="Confirmation"
      :size="modalSize"
      @ok="onConfirmation"
    >
      <slot>
        {{ modalData.message }}
      </slot>
      <template #modal-footer="{ ok, cancel }">
        <BButton
          v-model="modalData"
          @click="cancel()"
        >
          {{ modalData.cancelButton }}
        </BButton>
        <BButton
          :variant="variant"
          @click="ok()"
        >
          {{ modalData.confirmButton }}
        </BButton>
      </template>
    </BModal>
  </BButton>
</template>

<script setup lang="ts">
import {
  computed, PropType, reactive, Ref, ref, toRefs, onUnmounted,
} from 'vue';
import { BModal, BButton } from 'bootstrap-vue-next';
import { AsyncButtonStatus } from '@/models/asyncButton';

interface ModalConfig {
  message: string;
  confirmButton?: string;
  cancelButton?: string;
}

const props = defineProps({
  fn: {
    type: Function,
    required: true,
  },
  name: {
    type: String,
    required: true,
  },
  id: {
    type: String,
    required: true,
  },
  initialIcon: {
    type: String,
    required: true,
  },
  disabled: {
    default: false,
    type: Boolean,
  },
  restoreTimeout: {
    type: Number,
    default: 3000,
  },
  restoreAfterError: {
    type: Boolean,
    default: true,
  },
  size: {
    type: String,
    default: 'sm',
  },
  variant: {
    type: String,
    default: 'primary',
  },
  modalSize: {
    type: String,
    default: 'sm',
  },
  confirmModal: {
    type: [Object as PropType<ModalConfig>, String] as PropType<ModalConfig | string>,
    default: null,
  },
});

const emits = defineEmits(['status', 'success', 'error']);

const modalRef = ref('') as unknown as Ref<BModal>;
const status = ref(AsyncButtonStatus.INITIAL);
const timeout = ref(0);
const { confirmModal } = toRefs(props);

const modalData = reactive({
  message: '',
  confirmButton: 'Ok',
  cancelButton: 'Cancel',
});

const modalId = `${props.id}Modal`;

onUnmounted(() => {
  if (timeout.value) {
    clearTimeout(timeout.value);
  }
});

function setTimeoutProp() {
  if (props.restoreTimeout === -1) {
    return;
  }
  timeout.value = setTimeout(() => {
    status.value = AsyncButtonStatus.INITIAL;
    emits('status', status.value, null);
  }, props.restoreTimeout);
}

const buttonClass = computed(() => ({
  'btn-danger': status.value === AsyncButtonStatus.ERROR,
  [`btn-${props.variant}`]:
        // eslint-disable-next-line max-len
        status.value === AsyncButtonStatus.INITIAL
        || status.value === AsyncButtonStatus.IN_PROGRESS,
  'btn-success': status.value === AsyncButtonStatus.SUCCESSFUL,
}));

async function run() {
  status.value = AsyncButtonStatus.IN_PROGRESS;

  try {
    emits('status', status.value, '');
    props
      .fn()
      .then(() => {
        status.value = AsyncButtonStatus.SUCCESSFUL;
        emits('status', status.value, '');
        emits('success', status.value);
        setTimeoutProp();
      })
      .catch((e) => {
        status.value = AsyncButtonStatus.ERROR;
        emits('status', status.value, e);
        emits('error', e);
        setTimeoutProp();
      });
  } catch (e) {
    setTimeoutProp();
    throw e;
  }
}

function click() {
  if (props.confirmModal) {
    if (typeof props.confirmModal === 'string') {
      modalData.message = confirmModal.value as string;
    }
    modalRef.value.show();
  } else {
    run();
  }
}

function onConfirmation() {
  run();
}

</script>
