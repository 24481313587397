import { defineComponent as _defineComponent } from 'vue'
import { createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, vShow as _vShow, withDirectives as _withDirectives, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment } from "vue"

const _hoisted_1 = { id: "tenantDetails" }
const _hoisted_2 = { class: "ub-1" }
const _hoisted_3 = { key: 0 }
const _hoisted_4 = { key: 0 }
const _hoisted_5 = { key: 0 }


export default /*@__PURE__*/_defineComponent({
  __name: 'TenantDetailComponent',
  props: {
  complianceProfile: {
    type: String,
    required: true,
  },
  region: {
    type: String,
    required: true,
  },
  quickSight: {
    type: Boolean,
  },
  artifactoryRepositories: {
    type: Object,
    required: true,
  },
},
  setup(__props) {





return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _cache[13] || (_cache[13] = _createElementVNode("h5", null, "Tenant", -1)),
    _createElementVNode("div", null, [
      _createElementVNode("p", _hoisted_2, [
        _cache[0] || (_cache[0] = _createElementVNode("b", null, "Compliance Profile:", -1)),
        _cache[1] || (_cache[1] = _createElementVNode("br", null, null, -1)),
        _createTextVNode(" " + _toDisplayString(__props.complianceProfile), 1)
      ]),
      _createElementVNode("p", null, [
        _cache[2] || (_cache[2] = _createElementVNode("b", null, "Main Region:", -1)),
        _cache[3] || (_cache[3] = _createElementVNode("br", null, null, -1)),
        _createTextVNode(" " + _toDisplayString(__props.region), 1)
      ]),
      _withDirectives(_createElementVNode("p", null, [
        _cache[4] || (_cache[4] = _createElementVNode("b", null, "QuickSight:", -1)),
        _cache[5] || (_cache[5] = _createElementVNode("br", null, null, -1)),
        _createTextVNode(" " + _toDisplayString(__props.quickSight ? "Activated" : "Inactive"), 1)
      ], 512), [
        [_vShow, false]
      ]),
      _createElementVNode("p", null, [
        _cache[12] || (_cache[12] = _createElementVNode("b", null, "Artifactory Repositories:", -1)),
        _createElementVNode("ul", null, [
          _createElementVNode("li", null, [
            _cache[7] || (_cache[7] = _createTextVNode(" Virtual: ")),
            (__props.artifactoryRepositories.virtualRepo.length <= 0)
              ? (_openBlock(), _createElementBlock("span", _hoisted_3, "Not configured"))
              : (_openBlock(true), _createElementBlock(_Fragment, { key: 1 }, _renderList(__props.artifactoryRepositories.virtualRepo, (rep, idx) => {
                  return (_openBlock(), _createElementBlock("span", { key: idx }, [
                    _createTextVNode(_toDisplayString(rep), 1),
                    _cache[6] || (_cache[6] = _createElementVNode("br", null, null, -1))
                  ]))
                }), 128))
          ]),
          _createElementVNode("li", null, [
            _cache[9] || (_cache[9] = _createTextVNode(" Snapshot: ")),
            (__props.artifactoryRepositories.snapshotRepo.length <= 0)
              ? (_openBlock(), _createElementBlock("span", _hoisted_4, "Not configured"))
              : (_openBlock(true), _createElementBlock(_Fragment, { key: 1 }, _renderList(__props.artifactoryRepositories.snapshotRepo, (rep, idx) => {
                  return (_openBlock(), _createElementBlock("span", { key: idx }, [
                    _createTextVNode(_toDisplayString(rep), 1),
                    _cache[8] || (_cache[8] = _createElementVNode("br", null, null, -1))
                  ]))
                }), 128))
          ]),
          _createElementVNode("li", null, [
            _cache[11] || (_cache[11] = _createTextVNode(" Release: ")),
            (__props.artifactoryRepositories.releaseRepo.length <= 0)
              ? (_openBlock(), _createElementBlock("span", _hoisted_5, "Not configured"))
              : (_openBlock(true), _createElementBlock(_Fragment, { key: 1 }, _renderList(__props.artifactoryRepositories.releaseRepo, (rep, idx) => {
                  return (_openBlock(), _createElementBlock("span", { key: idx }, [
                    _createTextVNode(_toDisplayString(rep), 1),
                    _cache[10] || (_cache[10] = _createElementVNode("br", null, null, -1))
                  ]))
                }), 128))
          ])
        ])
      ])
    ])
  ]))
}
}

})