import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, withCtx as _withCtx, createVNode as _createVNode, resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, normalizeStyle as _normalizeStyle, resolveDirective as _resolveDirective, createElementBlock as _createElementBlock, withDirectives as _withDirectives } from "vue"

const _hoisted_1 = ["id"]

import { storeToRefs } from 'pinia';
import { computed } from 'vue';
import { useBulkPermissionAssignment, RequestState } from '@/store/modules/useBulkPermissionAssignment';
import {
  BTable, BSpinner, BProgressBar, BProgress,
} from 'bootstrap-vue-next';


export default /*@__PURE__*/_defineComponent({
  __name: 'BulkRun',
  setup(__props) {

const store = useBulkPermissionAssignment();
const {
  assignPermissionRequests, error, done, pending,
} = storeToRefs(store);

const stateReasonStyle = {
  'border-bottom': 'black 2px dotted',
  'padding-bottom': '0px',
  cursor: 'pointer',
};

const icon = (state: RequestState) => {
  switch (state) {
    case RequestState.DONE:
      return { icon: 'check', color: 'green' };
    case RequestState.ERROR:
      return { icon: 'exclamation-triangle', color: 'red' };
    default:
      return { icon: 'spinner', color: 'grey' };
  }
};

const items = computed(() => assignPermissionRequests.value
  .map(({ permission: { user, role, tenant }, state, stateReason }) => ({
    user: user.email,
    tenant: tenant.name,
    role: role.name,
    state,
    stateReason,
  })));

const fields = [
  { key: 'user', sortable: true },
  { key: 'tenant', sortable: true },
  { key: 'role', sortable: true },
  { key: 'state', sortable: true },
];

return (_ctx: any,_cache: any) => {
  const _component_font_awesome_icon = _resolveComponent("font-awesome-icon")!
  const _directive_b_tooltip = _resolveDirective("b-tooltip")!

  return (_openBlock(), _createElementBlock("div", null, [
    _createVNode(_unref(BProgress), {
      value: _unref(done) + _unref(error),
      max: _unref(assignPermissionRequests).length
    }, {
      default: _withCtx(() => [
        _createVNode(_unref(BProgressBar), {
          value: _unref(done),
          variant: "success"
        }, {
          default: _withCtx(() => [
            _createTextVNode(_toDisplayString(_unref(done)) + " successful ", 1)
          ]),
          _: 1
        }, 8, ["value"]),
        _createVNode(_unref(BProgressBar), {
          value: _unref(error),
          variant: "danger"
        }, {
          default: _withCtx(() => [
            _createTextVNode(_toDisplayString(_unref(error)) + " " + _toDisplayString(_unref(error) > 1 ? 'errors' : 'error'), 1)
          ]),
          _: 1
        }, 8, ["value"]),
        _createVNode(_unref(BProgressBar), {
          animated: _unref(pending) > 0,
          value: _unref(pending),
          variant: "info"
        }, {
          default: _withCtx(() => [
            _createTextVNode(_toDisplayString(_unref(pending)) + " pending ", 1)
          ]),
          _: 1
        }, 8, ["animated", "value"])
      ]),
      _: 1
    }, 8, ["value", "max"]),
    _createVNode(_unref(BTable), {
      items: items.value,
      fields: fields,
      small: "",
      class: "mt-2"
    }, {
      "cell(state)": _withCtx((data) => [
        _withDirectives((_openBlock(), _createElementBlock("span", {
          id: `tooltip-${data.index}`,
          style: _normalizeStyle(data.item.stateReason && stateReasonStyle)
        }, [
          (data.item.state === _unref(RequestState).DONE
              || data.item.state === _unref(RequestState).ERROR)
            ? (_openBlock(), _createBlock(_component_font_awesome_icon, {
                key: 0,
                icon: icon(data.item.state as RequestState).icon,
                color: icon(data.item.state as RequestState).color,
                title: data.item.state
              }, null, 8, ["icon", "color", "title"]))
            : (_openBlock(), _createBlock(_unref(BSpinner), {
                key: 1,
                small: ""
              }))
        ], 12, _hoisted_1)), [
          [_directive_b_tooltip, data.item.stateReason]
        ])
      ]),
      _: 1
    }, 8, ["items"])
  ]))
}
}

})