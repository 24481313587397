import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, openBlock as _openBlock, createBlock as _createBlock } from "vue"

import { storeToRefs } from 'pinia';
import { computed } from 'vue';
import { BTable } from 'bootstrap-vue-next';
import { useBulkPermissionManagement } from '@/store/modules/useBulkPermissionManagement';


export default /*@__PURE__*/_defineComponent({
  __name: 'BulkReview',
  setup(__props) {

const store = useBulkPermissionManagement();
const { permissions } = storeToRefs(store);

const items = computed(() => permissions.value.map((permission) => ({
  user: permission.user.email,
  tenant: permission.tenant.name,
  role: permission.role.name,
})));

const fields = [
  { key: 'user', sortable: true },
  { key: 'tenant', sortable: true },
  { key: 'role', sortable: true }];

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createBlock(_unref(BTable), {
    items: items.value,
    fields: fields,
    small: "",
    "show-empty": "",
    "empty-text": "No permissions to assign. Add users and roles."
  }, null, 8, ["items"]))
}
}

})