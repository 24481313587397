import { defineComponent as _defineComponent } from 'vue'
import { renderSlot as _renderSlot, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { key: 0 }
const _hoisted_2 = {
  class: "alert alert-secondary",
  role: "alert"
}
const _hoisted_3 = { key: 1 }
const _hoisted_4 = { key: 0 }
const _hoisted_5 = { key: 1 }

import type { PropType } from 'vue';
import { BubblewrapInfo } from '@/models/bubblewrap';

/**
 * This component can be used to wrap things like API calls.
 * It provides a slot for a custom error message as well as
 * for placeholders (so they look pretty!)
 */


export default /*@__PURE__*/_defineComponent({
  __name: 'BubblewrapComponent',
  props: {
  info: {
    type: Object as PropType<BubblewrapInfo>,
    required: true,
  },
},
  setup(__props) {



return (_ctx: any,_cache: any) => {
  return (__props.info.error)
    ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
        _renderSlot(_ctx.$slots, "error", {}, () => [
          _createElementVNode("div", _hoisted_2, [
            _cache[0] || (_cache[0] = _createElementVNode("h4", { class: "alert-heading" }, " An error ocurred ", -1)),
            _createElementVNode("code", null, _toDisplayString(__props.info.error.message), 1),
            _cache[1] || (_cache[1] = _createElementVNode("p", null, "We're really sorry and it is a bit embarrassing but an error ocurred.", -1))
          ])
        ])
      ]))
    : (_openBlock(), _createElementBlock("div", _hoisted_3, [
        (__props.info.loaded)
          ? (_openBlock(), _createElementBlock("div", _hoisted_4, [
              _renderSlot(_ctx.$slots, "loaded")
            ]))
          : (_openBlock(), _createElementBlock("div", _hoisted_5, [
              _renderSlot(_ctx.$slots, "placeholder")
            ]))
      ]))
}
}

})