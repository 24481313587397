import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, createVNode as _createVNode, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "mt-2"
}

import { BSpinner } from 'bootstrap-vue-next';

type Props = {
  text?: string;
  minHeight?: string | number;
}


export default /*@__PURE__*/_defineComponent({
  __name: 'ContentLoader',
  props: {
    text: {},
    minHeight: { default: '6rem' }
  },
  setup(__props: any) {



const minHeightStyle = {
  minHeight: __props.minHeight === 'number' ? `${__props.minHeight}px` : __props.minHeight,
};


return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", {
    class: "w-100 h-100 d-flex flex-column align-items-center justify-content-center",
    style: minHeightStyle
  }, [
    _createVNode(_unref(BSpinner), { type: "grow" }),
    (!!_ctx.text)
      ? (_openBlock(), _createElementBlock("h3", _hoisted_1, _toDisplayString(_ctx.text), 1))
      : _createCommentVNode("", true)
  ]))
}
}

})