import { defineComponent as _defineComponent } from 'vue'
import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "py-5" }

import { userStore } from '@/store/modules/user';


export default /*@__PURE__*/_defineComponent({
  __name: 'NotLoggedIn',
  setup(__props) {

const user = userStore();

function login() {
  user.redirectToLogin();
}

setTimeout(() => {
  login();
}, 3000);

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", null, [
    _createElementVNode("div", _hoisted_1, [
      _cache[1] || (_cache[1] = _createElementVNode("h1", { class: "display-4 px-5" }, " Not logged in ", -1)),
      _cache[2] || (_cache[2] = _createElementVNode("p", { class: "lead px-5" }, " You will be automatically redirected shortly. If the redirect does not work, please press the button below. ", -1)),
      _createElementVNode("button", {
        class: "btn btn-secondary btn-lg mb-4 mx-5",
        type: "button",
        onClick: _cache[0] || (_cache[0] = ($event: any) => (login()))
      }, " Go to login ")
    ])
  ]))
}
}

})