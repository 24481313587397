import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, createVNode as _createVNode, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, withCtx as _withCtx, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createBlock as _createBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { class: "container mt-4" }
const _hoisted_2 = { class: "row gx-5" }
const _hoisted_3 = { class: "col" }
const _hoisted_4 = { class: "row mt-4" }
const _hoisted_5 = { class: "col" }
const _hoisted_6 = { key: 1 }

import { ref, computed } from 'vue';
import { TenantRepresentation } from '@/models/keycloak';
import {
  BLink, BFormInput, BTable, TableFieldRaw,
} from 'bootstrap-vue-next';
import Utils from '@/utils/utils';
import { RouteLocationRaw, useRouter } from 'vue-router';

type Props = {
  tenants: TenantRepresentation[];
};

type TenantMenuItem = { to: RouteLocationRaw, label: string };

type TenantMenu = TenantMenuItem[];

type TenantRow = {
  tenantName: string,
  role: string,
  menu: TenantMenu
}

enum TableFieldKey {
  TENANT_NAME = 'tenantName',
  ROLE = 'role',
  ACTIONS = 'actions'
}


export default /*@__PURE__*/_defineComponent({
  __name: 'TenantList',
  props: {
    tenants: {}
  },
  setup(__props: any) {


const nameFilter = ref('');
const router = useRouter();

const fields: Exclude<TableFieldRaw<TenantRow>, string>[] = [
  { key: TableFieldKey.TENANT_NAME, sortable: true },
  { key: TableFieldKey.ROLE, label: 'Portal Permission', sortable: true },
  { key: TableFieldKey.ACTIONS, label: '', tdClass: 'text-end' },
];

const filterByTenantName = ({ tenantName }: TenantRepresentation) => tenantName
  .toLocaleLowerCase()
  .includes(nameFilter.value.toLocaleLowerCase());

const mapTenantToTableItem = (tenant: TenantRepresentation): TenantRow => {
  const firstRole = Utils.sort(tenant.permissions).at(0);
  const role = firstRole === 'viewer' ? 'readonly' : firstRole;
  const menu: TenantMenu = [
    {
      label: 'Details',
      to: { name: 'tenantDetails', params: { tenantName: tenant.tenantName } },
    },
  ];

  if (tenant.permissions.includes('approver')) {
    menu.push({
      label: 'Manage',
      to: { name: 'tenantSettings', params: { tenantName: tenant.tenantName } },
    });
  }

  return {
    ...tenant,
    role,
    menu,
  };
};

const navigateToTenantDetails = ({ tenantName }: TenantRow) => {
  router.push({ name: 'tenantDetails', params: { tenantName } });
};

const items = computed(() => __props.tenants
  .filter(filterByTenantName)
  .map(mapTenantToTableItem));


return (_ctx: any,_cache: any) => {
  const _component_BDropdownItem = _resolveComponent("BDropdownItem")!
  const _component_BDropdown = _resolveComponent("BDropdown")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _createVNode(_unref(BFormInput), {
          modelValue: nameFilter.value,
          "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((nameFilter).value = $event)),
          "data-element": "tenants-searchbar",
          placeholder: "Search..."
        }, null, 8, ["modelValue"])
      ]),
      _cache[1] || (_cache[1] = _createElementVNode("div", { class: "col" }, null, -1))
    ]),
    _createElementVNode("div", _hoisted_4, [
      _createElementVNode("div", _hoisted_5, [
        (!!items.value.length)
          ? (_openBlock(), _createBlock(_unref(BTable), {
              key: 0,
              "data-element": "tenants-table",
              class: "align-middle",
              striped: "",
              "sort-by": [{ key: TableFieldKey.TENANT_NAME, order: 'asc' }],
              fields: fields,
              items: items.value,
              onRowClicked: navigateToTenantDetails
            }, {
              "cell(tenantName)": _withCtx((data) => [
                _createVNode(_unref(BLink), {
                  "data-element": "tenant-name-column",
                  class: "text-decoration-none",
                  to: { name: 'tenantDetails', params: { tenantName: data.item.tenantName } }
                }, {
                  default: _withCtx(() => [
                    _createTextVNode(_toDisplayString(data.item.tenantName), 1)
                  ]),
                  _: 2
                }, 1032, ["to"])
              ]),
              "cell(actions)": _withCtx((data) => [
                _createVNode(_component_BDropdown, {
                  text: "Actions",
                  size: "sm",
                  "data-element": "tenant-actions"
                }, {
                  default: _withCtx(() => [
                    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(data.item.menu, (menuItem) => {
                      return (_openBlock(), _createBlock(_component_BDropdownItem, {
                        key: menuItem.label
                      }, {
                        default: _withCtx(() => [
                          _createVNode(_unref(BLink), {
                            class: "btn btn-sm w-100",
                            to: menuItem.to
                          }, {
                            default: _withCtx(() => [
                              _createTextVNode(_toDisplayString(menuItem.label), 1)
                            ]),
                            _: 2
                          }, 1032, ["to"])
                        ]),
                        _: 2
                      }, 1024))
                    }), 128))
                  ]),
                  _: 2
                }, 1024)
              ]),
              _: 1
            }, 8, ["sort-by", "items"]))
          : (_openBlock(), _createElementBlock("div", _hoisted_6, _cache[2] || (_cache[2] = [
              _createElementVNode("span", { "data-element": "no-tenants-fallback" }, "No tenants to show.", -1)
            ])))
      ])
    ])
  ]))
}
}

})