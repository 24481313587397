import { defineComponent as _defineComponent } from 'vue'
import { createTextVNode as _createTextVNode, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { div: "logged-in" }
const _hoisted_2 = { key: 1 }

import Cookies from 'js-cookie';
import { onMounted, ref } from 'vue';
import { useRoute, useRouter } from 'vue-router';
import { userStore } from '@/store/modules/user';


export default /*@__PURE__*/_defineComponent({
  __name: 'OAuthCallback',
  setup(__props) {

const error = ref(false);
const route = useRoute();
const router = useRouter();
const user = userStore();

onMounted(() => {
  const id = Cookies.get('userId');

  if (id === undefined) {
    error.value = true;
    return;
  }

  const xsrfToken = Cookies.get('XSRF-Token');

  if (xsrfToken === undefined) {
    error.value = true;
    return;
  }

  user.login({ id, xsrfToken });
  if (route.query.go) {
    router.push(decodeURIComponent(route.query.go as string));
  } else {
    router.push('/profile');
  }
});


return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (error.value)
      ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
          _createTextVNode(" There was an error logging you in. Please contact MPS. ")
        ], 64))
      : (_openBlock(), _createElementBlock("h1", _hoisted_2, "You Logged In Successfully"))
  ]))
}
}

})