<template>
  <div>
    <div class="py-5">
      <h1 class="display-4 px-5">
        Not logged in
      </h1>
      <p class="lead px-5">
        You will be automatically redirected shortly. If the redirect does not work, please press
        the button below.
      </p>

      <button class="btn btn-secondary btn-lg mb-4 mx-5" type="button" @click="login()">
        Go to login
      </button>
    </div>
  </div>
</template>

<script setup lang="ts">
import { userStore } from '@/store/modules/user';

const user = userStore();

function login() {
  user.redirectToLogin();
}

setTimeout(() => {
  login();
}, 3000);
</script>
