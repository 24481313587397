import { defineComponent as _defineComponent } from 'vue'
import { createElementVNode as _createElementVNode, unref as _unref, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  id: "profile",
  class: "container"
}

import axios from 'axios';
import { onBeforeMount, reactive } from 'vue';
import { BCol, BRow } from 'bootstrap-vue-next';
import Bubblewrap from '@/components/BubblewrapComponent.vue';
import TenantList from '@/components/TenantList.vue';
import { useApiCall } from '@/composable/apiStates';
import { TenantRepresentation } from '@/models/keycloak';
import { getKeycloakRequestConfig } from '@/utils/http';
import { userStore } from '@/store/modules/user';
import ContentLoader from '@/components/ContentLoader.vue';


export default /*@__PURE__*/_defineComponent({
  __name: 'MyTenants',
  setup(__props) {

const tenants: TenantRepresentation[] = reactive([]);
const user = userStore();
const apiCall = useApiCall();

/**
     * fetchTenants loads the logged-in users permissions from the API
     */
async function fetchTenants(): Promise<TenantRepresentation[]> {
  return axios
    .get<TenantRepresentation[]>(
      `/users/${user.id}/tenants`,
      getKeycloakRequestConfig(user.xsrfToken),
    )
    .then((response) => response.data as TenantRepresentation[]);
}

onBeforeMount(async () => {
  let data: TenantRepresentation[];
  try {
    data = await fetchTenants();
    Object.assign(tenants, data);
    apiCall.loaded = true;
  } catch (err) {
    apiCall.error = err;
  }
});


return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_unref(BRow), null, {
      default: _withCtx(() => [
        _createVNode(_unref(BCol), { class: "col-offset-2" }, {
          default: _withCtx(() => [
            _cache[0] || (_cache[0] = _createElementVNode("h3", { class: "mt-4" }, " My Tenants ", -1)),
            _cache[1] || (_cache[1] = _createElementVNode("p", { class: "lead" }, " You can see tenants that you have permissions for in the list below. You are also able to look up more information for a tenant here. ", -1)),
            _createVNode(Bubblewrap, { info: _unref(apiCall) }, {
              placeholder: _withCtx(() => [
                _createVNode(ContentLoader, {
                  text: "Loading tenants...",
                  "min-height": "10rem"
                })
              ]),
              loaded: _withCtx(() => [
                _createVNode(TenantList, { tenants: tenants }, null, 8, ["tenants"])
              ]),
              _: 1
            }, 8, ["info"])
          ]),
          _: 1
        })
      ]),
      _: 1
    })
  ]))
}
}

})