import { defineComponent as _defineComponent } from 'vue'
import { createElementVNode as _createElementVNode, unref as _unref, withCtx as _withCtx, createVNode as _createVNode, createTextVNode as _createTextVNode, toDisplayString as _toDisplayString, resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock } from "vue"

import axios from 'axios';
import {
  computed, onMounted, ref,
} from 'vue';
import {
  BCol, BRow, BContainer, BTableSimple,
  BTbody, BTr, BTd, BTh,
} from 'bootstrap-vue-next';
import Bubblewrap from '@/components/BubblewrapComponent.vue';
import { UserDetails } from '@/models/userDetails';
import { getKeycloakRequestConfig } from '@/utils/http';
import { userStore } from '@/store/modules/user';
import { useApiCall } from '@/composable/apiStates';


export default /*@__PURE__*/_defineComponent({
  __name: 'ProfileComponent',
  setup(__props) {

const userDetails = ref(null);
const user = userStore();
// const isRowHeader = ref(true); // Set this to false if it's a column header
const id = computed(() => user.id);
const apiCall = useApiCall();

/**
   * fetching user details from the API
   */
async function fetchUserDetails(): Promise<UserDetails> {
  const res = await axios
    .get<UserDetails>('/user', getKeycloakRequestConfig(user.xsrfToken))
    .then((response) => response.data);

  return {
    lastLoginTime: new Date(res.lastLoginTime),
    createdTimestamp: new Date(res.createdTimestamp),
  };
}

onMounted(async () => {
  try {
    userDetails.value = await fetchUserDetails();
  } catch (e) {
    apiCall.error = e;
  }
  apiCall.loaded = true;
});


return (_ctx: any,_cache: any) => {
  const _component_ContentLoader = _resolveComponent("ContentLoader")!

  return (_openBlock(), _createBlock(_unref(BContainer), { id: "profile" }, {
    default: _withCtx(() => [
      _createVNode(_unref(BRow), null, {
        default: _withCtx(() => [
          _createVNode(_unref(BCol), { cols: "12" }, {
            default: _withCtx(() => _cache[0] || (_cache[0] = [
              _createElementVNode("h3", { class: "my-4" }, " My Profile ", -1)
            ])),
            _: 1
          }),
          _createVNode(_unref(BCol), { cols: "6" }, {
            default: _withCtx(() => [
              _createVNode(Bubblewrap, { info: _unref(apiCall) }, {
                placeholder: _withCtx(() => [
                  _createVNode(_unref(BTableSimple), null, {
                    default: _withCtx(() => [
                      _createVNode(_unref(BTbody), null, {
                        default: _withCtx(() => [
                          _createVNode(_unref(BTr), null, {
                            default: _withCtx(() => [
                              _createVNode(_unref(BTh), null, {
                                default: _withCtx(() => _cache[1] || (_cache[1] = [
                                  _createTextVNode(" User ID ")
                                ])),
                                _: 1
                              }),
                              _createVNode(_unref(BTd), null, {
                                default: _withCtx(() => [
                                  _createTextVNode(_toDisplayString(id.value), 1)
                                ]),
                                _: 1
                              })
                            ]),
                            _: 1
                          }),
                          _createVNode(_unref(BTr), null, {
                            default: _withCtx(() => [
                              _createVNode(_unref(BTh), null, {
                                default: _withCtx(() => _cache[2] || (_cache[2] = [
                                  _createTextVNode(" User Created ")
                                ])),
                                _: 1
                              }),
                              _createVNode(_unref(BTd), null, {
                                default: _withCtx(() => [
                                  _createVNode(_component_ContentLoader, {
                                    height: 5,
                                    width: 100
                                  })
                                ]),
                                _: 1
                              })
                            ]),
                            _: 1
                          }),
                          _createVNode(_unref(BTr), null, {
                            default: _withCtx(() => [
                              _createVNode(_unref(BTh), null, {
                                default: _withCtx(() => _cache[3] || (_cache[3] = [
                                  _createTextVNode(" Last Login ")
                                ])),
                                _: 1
                              }),
                              _createVNode(_unref(BTd), null, {
                                default: _withCtx(() => [
                                  _createVNode(_component_ContentLoader, {
                                    height: 5,
                                    width: 100
                                  })
                                ]),
                                _: 1
                              })
                            ]),
                            _: 1
                          })
                        ]),
                        _: 1
                      })
                    ]),
                    _: 1
                  })
                ]),
                loaded: _withCtx(() => [
                  _createVNode(_unref(BTableSimple), null, {
                    default: _withCtx(() => [
                      _createVNode(_unref(BTbody), null, {
                        default: _withCtx(() => [
                          _createVNode(_unref(BTr), null, {
                            default: _withCtx(() => [
                              _createVNode(_unref(BTh), null, {
                                default: _withCtx(() => _cache[4] || (_cache[4] = [
                                  _createTextVNode(" User ID ")
                                ])),
                                _: 1
                              }),
                              _createVNode(_unref(BTd), null, {
                                default: _withCtx(() => [
                                  _createTextVNode(_toDisplayString(id.value), 1)
                                ]),
                                _: 1
                              })
                            ]),
                            _: 1
                          }),
                          _createVNode(_unref(BTr), null, {
                            default: _withCtx(() => [
                              _createVNode(_unref(BTh), null, {
                                default: _withCtx(() => _cache[5] || (_cache[5] = [
                                  _createTextVNode(" User Created ")
                                ])),
                                _: 1
                              }),
                              _createVNode(_unref(BTd), { class: "created-timestamp" }, {
                                default: _withCtx(() => [
                                  _createTextVNode(_toDisplayString(_ctx.$filters.formatDate(userDetails.value.createdTimestamp)), 1)
                                ]),
                                _: 1
                              })
                            ]),
                            _: 1
                          }),
                          _createVNode(_unref(BTr), null, {
                            default: _withCtx(() => [
                              _createVNode(_unref(BTh), null, {
                                default: _withCtx(() => _cache[6] || (_cache[6] = [
                                  _createTextVNode(" Last Login ")
                                ])),
                                _: 1
                              }),
                              _createVNode(_unref(BTd), { class: "last-login-time" }, {
                                default: _withCtx(() => [
                                  _createTextVNode(_toDisplayString(_ctx.$filters.formatDate(userDetails.value.lastLoginTime)), 1)
                                ]),
                                _: 1
                              })
                            ]),
                            _: 1
                          })
                        ]),
                        _: 1
                      })
                    ]),
                    _: 1
                  })
                ]),
                _: 1
              }, 8, ["info"])
            ]),
            _: 1
          })
        ]),
        _: 1
      })
    ]),
    _: 1
  }))
}
}

})