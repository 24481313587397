import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, openBlock as _openBlock, createBlock as _createBlock } from "vue"

import { ref } from 'vue';
import { BFormSelect, ValidationState } from 'bootstrap-vue-next';


export default /*@__PURE__*/_defineComponent({
  __name: 'DropDownListComponent',
  props: {
  defaultOption: {
    type: String,
    required: true,
  },
  options: {
    type: Array,
    required: true,
  },
  state: {
    type: ValidationState,
    required: false,
    default: null,
  },
},
  setup(__props) {

const props = __props;

const selectedValue = ref(props.defaultOption);

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createBlock(_unref(BFormSelect), {
    modelValue: selectedValue.value,
    "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((selectedValue).value = $event)),
    options: __props.options,
    class: "custom-select",
    "value-field": "title",
    "text-field": "item",
    "disabled-field": "disabled",
    onChange: _cache[1] || (_cache[1] = ($event: any) => (_ctx.$emit('selectValue', selectedValue.value)))
  }, null, 8, ["modelValue", "options"]))
}
}

})