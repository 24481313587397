<template>
  <div
    class="container"
  >
    <BRow>
      <BCol class="col-offset-2">
        <h3 class="mt-4">
          Request Firefighter Access
        </h3>

        <BAlert :model-value="true" variant="info">
          This page only allows requesting Firefighter access for the <em>DevOps</em> and
          <em>ReadOnly</em> roles. For Firefighter access to other roles, please use the "My
          Tenants" page.
        </BAlert>

        <BCard>
          <BForm
            @submit.prevent="sendRequest"
          >
            <BFormInput
              id="tenant-name"
              v-model="tenant"
              :class="{
                'mb-3': tenantFieldValidation || tenantFieldValidation === null,
                'mb-1': !tenantFieldValidation && tenantFieldValidation !== null,
              }"
              type="text"
              placeholder="Tenant"
              :state="tenantFieldValidation"
              required
            />
            <BFormInvalidFeedback
              :state="tenantFieldValidation"
              :text="'Please enter a valid tenant name format (vwfs-du-example, without stage)'"
            />

            <FirefighterAccessForm
              :tenant-name="tenant"
              :tenant-roles="availableRoles"
              :validation-state="tenantFieldValidation === null && tenant.length > 0"
              :successful-event="(status) => handleRequestFirefighter(status)"
              :class="{ 'mt-3': !tenantFieldValidation && tenantFieldValidation !== null }"
              @error="(err) => handleFirefighterError(err)"
            />
          </BForm>
        </BCard>
      </BCol>
    </BRow>
  </div>
</template>

<script setup lang="ts">
import {
  ref, computed,
} from 'vue';
import {
  BForm, BCol, BRow, ValidationState, BaseColorVariant,
  BAlert,
  BFormInput, BCard, useToastController,
  BFormInvalidFeedback,
} from 'bootstrap-vue-next';
import FirefighterAccessForm from '@/components/FirefighterAccessForm.vue';
import { TenantRole } from '@/models/tenant';

const { show } = useToastController();
const tenant = ref('');

async function sendRequest() {
  return null;
}

const availableRoles: TenantRole[] = [
  {
    name: 'DevOps',
    technicalName: 'devops',
    hasFirefighterRole: true,
    isSelfServiceRole: true,
  },
  {
    name: 'ReadOnly',
    technicalName: 'readonly',
    hasFirefighterRole: true,
    isSelfServiceRole: true,
  },
];

const tenantFieldValidation: ValidationState = computed(() => {
  if (tenant.value.length === 0) {
    return null;
  }

  if (tenant.value.endsWith('-')) {
    return false;
  }

  const parts = tenant.value.split('-');

  if (parts.length < 3) {
    return false;
  }

  // maximum tenant name length
  if (tenant.value.length > 27) {
    return false;
  }

  // Return `null` so that there is _no_ feedback. Reasoning: we
  // don't actually validate the tenant exists, so this might create
  // confusion.
  return null;
});

const toast = (variant: string, title: string, message: string) => {
  show?.({
    props: {
      body: message,
      variant: variant as keyof BaseColorVariant,
      title,
      value: 10000,
    },
  });
};

function handleRequestFirefighter(statusCode: number) {
  if (statusCode === 204) {
    toast(
      'success',
      'Firefighter Access requested',
      `Firefighter access requested for tenant ${tenant.value}. Please ask an Approver to check
      their mails and accept the request`,
    );
  } else {
    console.warn('unexpected status code', statusCode);
  }

  tenant.value = '';
}

function handleFirefighterError(error: Error) {
  toast('danger', 'Error requesting Firefighter Access', `There was an error requesting
  Firefighter access. Please ensure that the tenant you entered is correct and try again`);
}

</script>
