import { computed } from 'vue';
import { SelectOptionsDropDown, TenantRole } from '@/models/tenant';

/**
 * Returns self-service roles as options used in dropdowns
 * @param tenantRoles `TenantRole` array
 * @param firefighterRolesOnly `true` to return only roles that can be used in firefighter sessions
 */
export function useTenantRoles(roles: TenantRole[], firefighterRolesOnly: boolean) {
  const roleOptions = computed(() => {
    const defaultOptions = SelectOptionsDropDown.ROLE;
    const opt = [];
    roles
      .filter((tenantRole: TenantRole) => tenantRole.isSelfServiceRole)
      .filter(
        (tenantRole: TenantRole) => !firefighterRolesOnly || tenantRole.hasFirefighterRole,
      )
      .forEach((tenantRole) => {
        opt.push({
          item: tenantRole.name,
          title: tenantRole.technicalName,
          disabled: false,
        });
      });

    opt.sort((el1, el2) => el1.item.localeCompare(el2.item));
    opt.unshift({
      item: defaultOptions,
      title: defaultOptions,
      disabled: true,
    });

    return opt;
  });

  return {
    roleOptions,
  };
}
