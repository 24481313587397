import { defineComponent as _defineComponent } from 'vue'
import { createElementVNode as _createElementVNode, unref as _unref, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { id: "tenantContacts" }

import { computed } from 'vue';
import { BRow, BCol, BTable } from 'bootstrap-vue-next';
import CollapsibleComponent from '@/components/CollapsibleComponent.vue';


export default /*@__PURE__*/_defineComponent({
  __name: 'TenantContacts',
  props: {
  approvers: {
    type: Array,
    default() {
      return [];
    },
  },
  escalators: {
    type: Array,
    default() {
      return [];
    },
  },
  responsibles: {
    type: Array,
    default() {
      return [];
    },
  },
},
  setup(__props) {

const props = __props;

const contactsFields = [
  {
    key: 'email',
    label: 'Email',
    sortable: true,
    sortDirection: 'asc',
    class: 'align-middle',
  },
];

const approversData = computed(() => props.approvers.map((a) => ({ email: a })));

const escalatorsData = computed(() => props.escalators.map((a) => ({ email: a })));

const responsiblesData = computed(() => props.responsibles.map((a) => ({ email: a })));


return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _cache[0] || (_cache[0] = _createElementVNode("h5", null, "Contacts", -1)),
    _createVNode(_unref(BRow), { class: "gap-2" }, {
      default: _withCtx(() => [
        (approversData.value.length > 0)
          ? (_openBlock(), _createBlock(_unref(BCol), { key: 0 }, {
              default: _withCtx(() => [
                _createVNode(CollapsibleComponent, {
                  "is-header": false,
                  header: "Approvers",
                  "is-visible": false,
                  "toggle-id": 'tenantContactsApprovers'
                }, {
                  default: _withCtx(() => [
                    _createVNode(_unref(BTable), {
                      striped: "",
                      hover: "",
                      items: approversData.value,
                      fields: contactsFields
                    }, null, 8, ["items"])
                  ]),
                  _: 1
                })
              ]),
              _: 1
            }))
          : _createCommentVNode("", true),
        (escalatorsData.value.length > 0)
          ? (_openBlock(), _createBlock(_unref(BCol), { key: 1 }, {
              default: _withCtx(() => [
                _createVNode(CollapsibleComponent, {
                  "is-header": false,
                  header: "Escalation Contacts",
                  "is-visible": false,
                  "toggle-id": 'tenantContactsEscalators'
                }, {
                  default: _withCtx(() => [
                    _createVNode(_unref(BTable), {
                      striped: "",
                      hover: "",
                      items: escalatorsData.value,
                      fields: contactsFields
                    }, null, 8, ["items"])
                  ]),
                  _: 1
                })
              ]),
              _: 1
            }))
          : _createCommentVNode("", true),
        (responsiblesData.value.length > 0)
          ? (_openBlock(), _createBlock(_unref(BCol), { key: 2 }, {
              default: _withCtx(() => [
                _createVNode(CollapsibleComponent, {
                  "is-header": false,
                  header: "Responsibles",
                  "is-visible": false,
                  "toggle-id": 'tenantContactsResponsibles'
                }, {
                  default: _withCtx(() => [
                    _createVNode(_unref(BTable), {
                      striped: "",
                      hover: "",
                      items: responsiblesData.value,
                      fields: contactsFields
                    }, null, 8, ["items"])
                  ]),
                  _: 1
                })
              ]),
              _: 1
            }))
          : _createCommentVNode("", true)
      ]),
      _: 1
    })
  ]))
}
}

})