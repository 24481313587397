import { defineComponent as _defineComponent } from 'vue'
import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

import { userStore } from '@/store/modules/user';


export default /*@__PURE__*/_defineComponent({
  __name: 'LoginComponent',
  setup(__props) {

const user = userStore();
function login() {
  user.redirectToLogin();
}


return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", null, [
    _createElementVNode("button", {
      id: "login",
      class: "btn btn-primary my-2 my-sm-0",
      type: "button",
      onClick: _cache[0] || (_cache[0] = ($event: any) => (login()))
    }, " Login ")
  ]))
}
}

})