import { defineComponent as _defineComponent } from 'vue'
import { createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, unref as _unref, createVNode as _createVNode, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "container mt-3" }
const _hoisted_2 = { class: "row" }
const _hoisted_3 = {
  id: "emptySensitiveEvents",
  class: "mt-2 container"
}
const _hoisted_4 = { id: "sensitiveMessageText" }
const _hoisted_5 = { key: 0 }
const _hoisted_6 = { class: "pt-15" }
const _hoisted_7 = { class: "table-responsive" }
const _hoisted_8 = {
  id: "sensitiveEventsTable",
  class: "table align-items-center table-bordered table-fluid"
}
const _hoisted_9 = { class: "text-xs font-weight-bold mb-0" }
const _hoisted_10 = { class: "text-xs text-secondary mb-0" }
const _hoisted_11 = {
  key: 0,
  class: "flex"
}
const _hoisted_12 = { class: "content-loader" }
const _hoisted_13 = { class: "pt-15" }
const _hoisted_14 = { class: "table-responsive" }
const _hoisted_15 = { class: "table align-items-center table-bordered table-fluid" }
const _hoisted_16 = { class: "text-secondary text-xxs font-weight-bolder opacity-7" }
const _hoisted_17 = { class: "text-secondary text-xxs font-weight-bolder opacity-7 ps-2" }
const _hoisted_18 = { class: "text-xs font-weight-bold mb-0" }
const _hoisted_19 = { class: "text-xs text-secondary mb-0" }

import {
  computed, onMounted, reactive, ref,
} from 'vue';
import {
  BPagination,
} from 'bootstrap-vue-next';

import axios from 'axios';
import { getKeycloakRequestConfig } from '@/utils/http';
import { FirefighterSensitiveEvent, FirefighterStatus } from '@/models/firefighter';
import Bubblewrap from '@/components/BubblewrapComponent.vue';
import { BubblewrapInfo } from '@/models/bubblewrap';
import { userStore } from '@/store/modules/user';

// eslint-disable-next-line no-shadow
enum SensitiveEventMessage {
  NO_SENSITIVE_EVENTS = 'By following the AWS CIS Benchmark, no sensitive events were found within the firefighter access time frame.',
  REPORT_GATHERING_LOG = 'Sensitive Events could not be loaded because the status report is in progress. Once the status is in finished state we will display the sensitive events in case we found one.',
  NO_SENSITIVE_KEY = 'No sensitive action report is available for this firefighter request. Please check the after action report to see all performed actions.',
}


export default /*@__PURE__*/_defineComponent({
  __name: 'FirefighterSensitiveActions',
  props: {
  firefighterAccessId: {
    type: String,
    required: true,
  },
  status: {
    type: String,
    required: true,
  },
},
  setup(__props) {

const props = __props;

const sensitiveEventsPerPage = ref(3);

const sensitiveEventsCurrentPage = ref(1);

const sensitiveEventUserMessageInfo = ref(null);

const user = userStore();

const sensitiveEvents: FirefighterSensitiveEvent[] = reactive([]);

const sensitiveEventsCall: BubblewrapInfo = reactive({
  error: null,
  loaded: false,
});

async function fetchSensitiveEvent() {
  // eslint-disable-next-line max-len
  if (
    props.status === FirefighterStatus.GATHERING_LOGS
      || props.status === FirefighterStatus.WAITING_FOR_LOGS
  ) {
    sensitiveEventsCall.loaded = true;
    sensitiveEventUserMessageInfo.value = SensitiveEventMessage.REPORT_GATHERING_LOG;
  } else {
    await axios
      .get<FirefighterSensitiveEvent[]>(
        `/firefighter/accesses/${props.firefighterAccessId}/sensitiveEventsReport`,
        getKeycloakRequestConfig(user.xsrfToken),
      )
      .then((response) => {
        if (response.status === 200) {
          sensitiveEventsCall.loaded = true;
          if (response.data.length > 0) {
            Object.assign(sensitiveEvents, response.data);
          } else {
            sensitiveEventUserMessageInfo.value = SensitiveEventMessage.NO_SENSITIVE_EVENTS;
          }
        }
      })
      .catch((error) => {
        if (error.response.status === 404) {
          sensitiveEventsCall.loaded = true;
          sensitiveEventUserMessageInfo.value = SensitiveEventMessage.NO_SENSITIVE_KEY;
        } else {
          sensitiveEventsCall.error = error;
        }
      });
  }
}

const sensitiveEventsPerList = computed(() => sensitiveEvents.slice(
  (sensitiveEventsCurrentPage.value - 1) * sensitiveEventsPerPage.value,
  sensitiveEventsCurrentPage.value * sensitiveEventsPerPage.value,
));

const rows = computed(() => sensitiveEvents.length);

onMounted(async () => {
  try {
    await fetchSensitiveEvent();
  } catch (e) {
    sensitiveEventsCall.error = new Error('Could not load firefighter sensitive action.');
  }
});


return (_ctx: any,_cache: any) => {
  const _component_ContentLoader = _resolveComponent("ContentLoader")!

  return (_openBlock(), _createBlock(Bubblewrap, {
    id: "sensitiveEvents",
    info: sensitiveEventsCall
  }, {
    loaded: _withCtx(() => [
      _createElementVNode("div", _hoisted_1, [
        _createElementVNode("div", _hoisted_2, [
          _cache[1] || (_cache[1] = _createElementVNode("h3", null, " Sensitive Actions ", -1)),
          _createElementVNode("div", _hoisted_3, [
            _createElementVNode("p", _hoisted_4, _toDisplayString(sensitiveEventUserMessageInfo.value), 1)
          ])
        ])
      ]),
      (sensitiveEvents.length > 0)
        ? (_openBlock(), _createElementBlock("div", _hoisted_5, [
            _createElementVNode("div", _hoisted_6, [
              _createElementVNode("div", _hoisted_7, [
                _createElementVNode("table", _hoisted_8, [
                  _cache[2] || (_cache[2] = _createElementVNode("thead", null, [
                    _createElementVNode("tr", null, [
                      _createElementVNode("th", { class: "text-secondary text-xxs font-weight-bolder opacity-7" }, " Sensitive Action "),
                      _createElementVNode("th", { class: "text-secondary text-xxs font-weight-bolder opacity-7 ps-2" }, " Occurrences ")
                    ])
                  ], -1)),
                  _createElementVNode("tbody", null, [
                    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(sensitiveEventsPerList.value, (item) => {
                      return (_openBlock(), _createElementBlock("tr", {
                        key: item.eventName
                      }, [
                        _createElementVNode("td", null, [
                          _createElementVNode("p", _hoisted_9, _toDisplayString(item.eventName), 1)
                        ]),
                        _createElementVNode("td", null, [
                          _createElementVNode("p", _hoisted_10, _toDisplayString(item.count), 1)
                        ])
                      ]))
                    }), 128))
                  ])
                ])
              ])
            ]),
            (sensitiveEvents.length > sensitiveEventsPerPage.value)
              ? (_openBlock(), _createElementBlock("div", _hoisted_11, [
                  _createVNode(_unref(BPagination), {
                    modelValue: sensitiveEventsCurrentPage.value,
                    "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((sensitiveEventsCurrentPage).value = $event)),
                    class: "justify-content-start",
                    "total-rows": rows.value,
                    "per-page": sensitiveEventsPerPage.value,
                    "aria-controls": "sensitiveEventsTable",
                    "first-number": "",
                    "last-number": ""
                  }, null, 8, ["modelValue", "total-rows", "per-page"])
                ]))
              : _createCommentVNode("", true)
          ]))
        : _createCommentVNode("", true),
      _cache[3] || (_cache[3] = _createElementVNode("div", null, null, -1))
    ]),
    placeholder: _withCtx(() => [
      _createElementVNode("div", _hoisted_12, [
        _cache[4] || (_cache[4] = _createElementVNode("div", { class: "container" }, [
          _createElementVNode("div", { class: "row" }, [
            _createElementVNode("h3", null, " Sensitive Actions ")
          ])
        ], -1)),
        _createElementVNode("div", _hoisted_13, [
          _createElementVNode("div", _hoisted_14, [
            _createElementVNode("table", _hoisted_15, [
              _createElementVNode("thead", null, [
                _createElementVNode("tr", null, [
                  _createElementVNode("th", _hoisted_16, [
                    _createVNode(_component_ContentLoader, { height: "32px" })
                  ]),
                  _createElementVNode("th", _hoisted_17, [
                    _createVNode(_component_ContentLoader, { height: "32px" })
                  ])
                ])
              ]),
              _createElementVNode("tbody", null, [
                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(sensitiveEventsPerList.value, (item) => {
                  return (_openBlock(), _createElementBlock("tr", {
                    key: item.eventName
                  }, [
                    _createElementVNode("td", null, [
                      _createElementVNode("p", _hoisted_18, [
                        _createVNode(_component_ContentLoader, { height: "52px" })
                      ])
                    ]),
                    _createElementVNode("td", null, [
                      _createElementVNode("p", _hoisted_19, [
                        _createVNode(_component_ContentLoader, { height: "52px" })
                      ])
                    ])
                  ]))
                }), 128))
              ])
            ])
          ])
        ])
      ]),
      _cache[5] || (_cache[5] = _createElementVNode("div", null, null, -1))
    ]),
    _: 1
  }, 8, ["info"]))
}
}

})