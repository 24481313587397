<template>
  <header>
    <BNavbar
      toggleable="md"
      type="dark"
      variant="dark"
      fixed="top"
      class="navbar-dark px-3"
    >
      <BNavbarToggle target="nav-collapse" />
      <BCollapse
        id="nav-collapse"
        is-nav
      >
        <BNavbarNav>
          <BNavItem
            class="navbar-brand"
            to="/"
            active-class="navbar-brand active"
          >
            MPS-NotAPortal
          </BNavItem>
          <BNavItem
            v-if="loggedIn"
            class="nav-link"
            to="/permissions"
          >
            My Permissions
          </BNavItem>
          <BNavItem
            v-if="loggedIn"
            class="nav-link"
            to="/tenants"
          >
            My Tenants
          </BNavItem>
          <BNavItem
            v-if="loggedIn && permissions.isApprover"
            class="nav-link"
            to="/bulk-permission-management"
          >
            Multi-Tenant Actions
          </BNavItem>
          <BNavItem
            class="ms-3 d-none d-md-block"
            style="border-left: 2px solid grey"
          />
          <BNavItem
            target="_blank"
            rel="noopener noreferrer"
            class="nav-link ms-md-2"
            :href="bifrostUrl"
          >
            Bifröst
          </BNavItem>
        </BNavbarNav>

        <BNavbarNav class="ms-auto">
          <BNavItem
            v-if="loggedIn"
            class="nav-link text-md-center"
            to="/firefighter"
          >
            <div
              class=" d-flex flex-row align-items-center flex-md-column gap-2"
            >
              <font-awesome-icon
                icon="fire-alt"
                class="text-danger"
                size="2x"
              />
              <div class="text-danger">
                Firefighter
              </div>
            </div>
          </BNavItem>
          <BNavItem
            v-if="loggedIn"
            class="nav-link text-md-center"
            to="/profile"
          >
            <div
              class=" d-flex flex-row align-items-center flex-md-column gap-2"
            >
              <font-awesome-icon
                icon="user-circle"
                size="2x"
              />
              <div>Profile</div>
            </div>
          </BNavItem>
          <BNavItem class="my-auto">
            <Login v-if="!loggedIn" />
            <Logout v-if="loggedIn" />
          </BNavItem>
        </BNavbarNav>
      </BCollapse>
    </BNavbar>
  </header>
</template>

<script setup lang='ts'>
import { computed } from 'vue';
import {
  BNavbar, BCollapse, BNavbarNav, BNavbarToggle, BNavItem,
} from 'bootstrap-vue-next';
import Config from '@/utils/config';
import { userStore } from '@/store/modules/user';
import { usePermissions } from '@/store/modules/usePermissions';
import Login from './LoginComponent.vue';
import Logout from './LogoutComponent.vue';

const user = userStore();
const permissions = usePermissions();

const loggedIn = computed(() => user.loggedIn);

const bifrostUrl = `${Config.BIFROST_URL}/auth/realms/digitalunit/account/`;

</script>

<style lang="scss" scoped>
.active:not(.navbar-brand) {
  border-bottom: 4px solid #8e9295;
  font-weight: bold;
}
</style>
