import { defineComponent as _defineComponent } from 'vue'
import { createTextVNode as _createTextVNode, unref as _unref, withCtx as _withCtx, createVNode as _createVNode, toDisplayString as _toDisplayString, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent } from "vue"

const _hoisted_1 = { class: "container" }
const _hoisted_2 = {
  class: "card bg-white p-3 shadow-sm mb-3",
  style: {"margin-top":"20px"}
}
const _hoisted_3 = { class: "row" }
const _hoisted_4 = { class: "col-6" }
const _hoisted_5 = { class: "row" }
const _hoisted_6 = { class: "col-2 mt-2 text-center" }
const _hoisted_7 = { class: "col-10" }
const _hoisted_8 = { class: "col-6" }
const _hoisted_9 = { class: "row" }
const _hoisted_10 = { class: "col-2 mt-2 text-center" }
const _hoisted_11 = { class: "col-10" }
const _hoisted_12 = { class: "row ContentLoader" }
const _hoisted_13 = { class: "col-6" }
const _hoisted_14 = { class: "row" }
const _hoisted_15 = { class: "col-2 mt-2 text-center" }
const _hoisted_16 = { class: "col-10" }
const _hoisted_17 = { class: "col-4" }
const _hoisted_18 = { class: "row" }
const _hoisted_19 = { class: "col-2 mt-2 text-center" }
const _hoisted_20 = { class: "col-10" }
const _hoisted_21 = { class: "pt-0 pb-28" }
const _hoisted_22 = {
  key: 0,
  class: "alert alert-danger",
  role: "alert"
}

import {
  computed, onBeforeMount, ref, watch,
  ComputedRef,
} from 'vue';
import {
  BBreadcrumb, BBreadcrumbItem, BCard, BCardText,
} from 'bootstrap-vue-next';
import axios from 'axios';
import { useRoute } from 'vue-router';
import AsyncButton from '@/components/AsyncButton.vue';
import Bubblewrap from '@/components/BubblewrapComponent.vue';
import { getKeycloakRequestConfig } from '@/utils/http';
import {
  FirefighterAccessRepresentation,
  FirefighterStatus,
} from '@/models/firefighter';
import { AsyncButtonStatus } from '@/models/asyncButton';
import { userStore } from '@/store/modules/user';
import { useApiCall, useAsyncButton } from '@/composable/apiStates';
import FirefighterSensitiveActions from './FirefighterSensitiveActions.vue';


export default /*@__PURE__*/_defineComponent({
  __name: 'FirefighterAccessDetails',
  setup(__props) {


const route = useRoute();
const report = ref(null);

let reportStatus: string = '';

const user = userStore();
const status = useAsyncButton();
const apiCall = useApiCall();

const firefighterAccessId = ref(route.params.firefighterAccessId.toString());

watch(() => route.params, () => {
  firefighterAccessId.value = route.params.firefighterAccessId.toString();
});

const hasDownloadError = computed(() => status.status === AsyncButtonStatus.ERROR);

const humanReadableStatus: ComputedRef<string> = computed(() => ({
  [FirefighterStatus.PENDING]: 'pending',
  [FirefighterStatus.ACTIVE]: 'active',
  [FirefighterStatus.WAITING_FOR_LOGS]: 'waiting for logs',
  [FirefighterStatus.GATHERING_LOGS]: 'gathering logs',
  [FirefighterStatus.FINISHED]: 'finished',
}[report.value.status] || 'n/a'));

function updateStatus(updatedStatus: AsyncButtonStatus, message: string) {
  status.status = updatedStatus;
  status.message = message;
}

async function downloadReport() {
  const response = await axios
    .get(
      `/firefighter/accesses/${firefighterAccessId.value}/report`,
      getKeycloakRequestConfig(user.xsrfToken),
    );
  window.open(response.data.url, '_blank');
}

async function fetchReport(): Promise<FirefighterAccessRepresentation> {
  const response = await axios
    .get<FirefighterAccessRepresentation>(
      `/firefighter/accesses/${firefighterAccessId.value}`,
      getKeycloakRequestConfig(user.xsrfToken),
    );
  return response.data as FirefighterAccessRepresentation;
}

onBeforeMount(async () => {
  try {
    report.value = await fetchReport();

    reportStatus = report.value.status;
    report.value.startAt = new Date(report.value.startAt);
    report.value.revokeAt = new Date(report.value.revokeAt);
    apiCall.loaded = true;
  } catch (e) {
    apiCall.error = new Error('Could not load firefighter access details.');
  }
});


return (_ctx: any,_cache: any) => {
  const _component_font_awesome_icon = _resolveComponent("font-awesome-icon")!
  const _component_ContentLoader = _resolveComponent("ContentLoader")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_unref(BBreadcrumb), null, {
      default: _withCtx(() => [
        _createVNode(_unref(BBreadcrumbItem), { to: { name: 'myTenants' } }, {
          default: _withCtx(() => _cache[0] || (_cache[0] = [
            _createTextVNode(" My Tenants ")
          ])),
          _: 1
        }),
        _createVNode(_unref(BBreadcrumbItem), {
          to: {
          name: 'tenantDetails',
          params:
            { tenantName: _unref(apiCall).loaded ? report.value.tenant : 'na' },
        }
        }, {
          default: _withCtx(() => [
            (report.value)
              ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
                  _createTextVNode(_toDisplayString(report.value.tenant), 1)
                ], 64))
              : (_openBlock(), _createElementBlock(_Fragment, { key: 1 }, [
                  _createTextVNode(" ... ")
                ], 64))
          ]),
          _: 1
        }, 8, ["to"]),
        _createVNode(_unref(BBreadcrumbItem), {
          to: {
          name: 'tenantReports',
          params:
            { tenantName: _unref(apiCall).loaded ? report.value.tenant : 'na' },
        }
        }, {
          default: _withCtx(() => _cache[1] || (_cache[1] = [
            _createTextVNode(" Reports ")
          ])),
          _: 1
        }, 8, ["to"]),
        _createVNode(_unref(BBreadcrumbItem), { active: "" }, {
          default: _withCtx(() => _cache[2] || (_cache[2] = [
            _createTextVNode(" Report Details ")
          ])),
          _: 1
        })
      ]),
      _: 1
    }),
    _cache[18] || (_cache[18] = _createElementVNode("h3", { class: "mt-4" }, " Firefighter Access Details ", -1)),
    _createElementVNode("div", _hoisted_2, [
      _createVNode(Bubblewrap, {
        id: "report",
        info: _unref(apiCall)
      }, {
        loaded: _withCtx(() => [
          _createElementVNode("div", _hoisted_3, [
            _createElementVNode("div", _hoisted_4, [
              _createElementVNode("div", _hoisted_5, [
                _createElementVNode("div", _hoisted_6, [
                  _createVNode(_component_font_awesome_icon, {
                    icon: "fire-alt",
                    size: "2x"
                  })
                ]),
                _createElementVNode("div", _hoisted_7, [
                  _createElementVNode("dl", null, [
                    _cache[3] || (_cache[3] = _createElementVNode("dt", null, "Username", -1)),
                    _createElementVNode("dd", null, _toDisplayString(report.value.user.firstName) + " " + _toDisplayString(report.value.user.lastName), 1),
                    _cache[4] || (_cache[4] = _createElementVNode("dt", null, "Start at", -1)),
                    _createElementVNode("dd", null, _toDisplayString(_ctx.$filters.formatDate(report.value.startAt)), 1),
                    _cache[5] || (_cache[5] = _createElementVNode("dt", null, "Revoke at", -1)),
                    _createElementVNode("dd", null, _toDisplayString(_ctx.$filters.formatDate(report.value.revokeAt)), 1),
                    _cache[6] || (_cache[6] = _createElementVNode("dt", null, "Status", -1)),
                    _createElementVNode("dd", null, _toDisplayString(humanReadableStatus.value), 1),
                    _cache[7] || (_cache[7] = _createElementVNode("dt", null, "Reason", -1)),
                    _createElementVNode("dd", null, _toDisplayString(report.value.reason), 1)
                  ])
                ])
              ])
            ]),
            _createElementVNode("div", _hoisted_8, [
              _createElementVNode("div", _hoisted_9, [
                _createElementVNode("div", _hoisted_10, [
                  _createVNode(_component_font_awesome_icon, {
                    icon: "user-check",
                    size: "2x"
                  })
                ]),
                _createElementVNode("div", _hoisted_11, [
                  _createElementVNode("dl", null, [
                    _cache[8] || (_cache[8] = _createElementVNode("dt", null, "Granted by", -1)),
                    _createElementVNode("dd", null, _toDisplayString(report.value.grantedBy.firstName) + " " + _toDisplayString(report.value.grantedBy.lastName), 1),
                    _cache[9] || (_cache[9] = _createElementVNode("dt", null, "Technical Id", -1)),
                    _createElementVNode("dd", null, _toDisplayString(firefighterAccessId.value), 1)
                  ])
                ])
              ])
            ])
          ])
        ]),
        placeholder: _withCtx(() => [
          _createElementVNode("div", _hoisted_12, [
            _createElementVNode("div", _hoisted_13, [
              _createElementVNode("div", _hoisted_14, [
                _createElementVNode("div", _hoisted_15, [
                  _createVNode(_component_font_awesome_icon, {
                    icon: "fire-alt",
                    size: "2x"
                  })
                ]),
                _createElementVNode("div", _hoisted_16, [
                  _createElementVNode("dl", null, [
                    _cache[10] || (_cache[10] = _createElementVNode("dt", null, "Username", -1)),
                    _createElementVNode("dd", null, [
                      _createVNode(_component_ContentLoader)
                    ]),
                    _cache[11] || (_cache[11] = _createElementVNode("dt", null, "Start at", -1)),
                    _createElementVNode("dd", null, [
                      _createVNode(_component_ContentLoader)
                    ]),
                    _cache[12] || (_cache[12] = _createElementVNode("dt", null, "Revoke at", -1)),
                    _createElementVNode("dd", null, [
                      _createVNode(_component_ContentLoader)
                    ]),
                    _cache[13] || (_cache[13] = _createElementVNode("dt", null, "Status", -1)),
                    _createElementVNode("dd", null, [
                      _createVNode(_component_ContentLoader)
                    ]),
                    _cache[14] || (_cache[14] = _createElementVNode("dt", null, "Reason", -1)),
                    _createElementVNode("dd", null, [
                      _createVNode(_component_ContentLoader)
                    ])
                  ])
                ])
              ])
            ]),
            _createElementVNode("div", _hoisted_17, [
              _createElementVNode("div", _hoisted_18, [
                _createElementVNode("div", _hoisted_19, [
                  _createVNode(_component_font_awesome_icon, {
                    icon: "user-check",
                    size: "2x"
                  })
                ]),
                _createElementVNode("div", _hoisted_20, [
                  _createElementVNode("dl", null, [
                    _cache[15] || (_cache[15] = _createElementVNode("dt", null, "Granted by", -1)),
                    _createElementVNode("dd", null, [
                      _createVNode(_component_ContentLoader)
                    ]),
                    _cache[16] || (_cache[16] = _createElementVNode("dt", null, "Technical Id", -1)),
                    _createElementVNode("dd", null, [
                      _createVNode(_component_ContentLoader)
                    ])
                  ])
                ])
              ])
            ])
          ])
        ]),
        _: 1
      }, 8, ["info"])
    ]),
    _createVNode(FirefighterSensitiveActions, {
      status: _unref(reportStatus),
      "firefighter-access-id": firefighterAccessId.value
    }, null, 8, ["status", "firefighter-access-id"]),
    _createElementVNode("div", _hoisted_21, [
      _createVNode(_unref(BCard), {
        class: "mt-4 mb-2",
        header: "After Action Report",
        "data-toggle": "collapse"
      }, {
        default: _withCtx(() => [
          _createVNode(_unref(BCardText), null, {
            default: _withCtx(() => _cache[17] || (_cache[17] = [
              _createTextVNode(" You can download a report containing all performed actions by this user on the production account here. The report is currently in JSON format and lists all CloudTrail actions. "),
              _createElementVNode("a", { href: "" }, "Click here to learn more about the CloudTrail Event structure.", -1),
              _createElementVNode("br", null, null, -1),
              _createTextVNode(" The report will be available approximately eight hours after the firefighter access was revoked. All tenant approvers and the firefighter user will receive an Email once the report is ready. ")
            ])),
            _: 1
          }),
          (hasDownloadError.value)
            ? (_openBlock(), _createElementBlock("div", _hoisted_22, " Could not download firefighter report: " + _toDisplayString(_unref(status).message), 1))
            : _createCommentVNode("", true),
          _createVNode(AsyncButton, {
            id: "downloadReport",
            name: "Download Report",
            "initial-icon": "download",
            disabled: !report.value || report.value.status !== 'finished',
            fn: () => downloadReport(),
            onStatus: updateStatus
          }, null, 8, ["disabled", "fn"])
        ]),
        _: 1
      })
    ])
  ]))
}
}

})