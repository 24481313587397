import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, renderSlot as _renderSlot, createTextVNode as _createTextVNode, withCtx as _withCtx, createBlock as _createBlock, normalizeClass as _normalizeClass } from "vue"

const _hoisted_1 = { key: 0 }
const _hoisted_2 = {
  key: 1,
  class: "spinner-border spinner-border-sm",
  role: "status",
  "aria-hidden": "true"
}
const _hoisted_3 = { key: 2 }
const _hoisted_4 = { key: 3 }

import {
  computed, PropType, reactive, Ref, ref, toRefs, onUnmounted,
} from 'vue';
import { BModal, BButton } from 'bootstrap-vue-next';
import { AsyncButtonStatus } from '@/models/asyncButton';

interface ModalConfig {
  message: string;
  confirmButton?: string;
  cancelButton?: string;
}


export default /*@__PURE__*/_defineComponent({
  __name: 'AsyncButton',
  props: {
  fn: {
    type: Function,
    required: true,
  },
  name: {
    type: String,
    required: true,
  },
  id: {
    type: String,
    required: true,
  },
  initialIcon: {
    type: String,
    required: true,
  },
  disabled: {
    default: false,
    type: Boolean,
  },
  restoreTimeout: {
    type: Number,
    default: 3000,
  },
  restoreAfterError: {
    type: Boolean,
    default: true,
  },
  size: {
    type: String,
    default: 'sm',
  },
  variant: {
    type: String,
    default: 'primary',
  },
  modalSize: {
    type: String,
    default: 'sm',
  },
  confirmModal: {
    type: [Object as PropType<ModalConfig>, String] as PropType<ModalConfig | string>,
    default: null,
  },
},
  emits: ['status', 'success', 'error'],
  setup(__props, { emit: __emit }) {

const props = __props;

const emits = __emit;

const modalRef = ref('') as unknown as Ref<BModal>;
const status = ref(AsyncButtonStatus.INITIAL);
const timeout = ref(0);
const { confirmModal } = toRefs(props);

const modalData = reactive({
  message: '',
  confirmButton: 'Ok',
  cancelButton: 'Cancel',
});

const modalId = `${props.id}Modal`;

onUnmounted(() => {
  if (timeout.value) {
    clearTimeout(timeout.value);
  }
});

function setTimeoutProp() {
  if (props.restoreTimeout === -1) {
    return;
  }
  timeout.value = setTimeout(() => {
    status.value = AsyncButtonStatus.INITIAL;
    emits('status', status.value, null);
  }, props.restoreTimeout);
}

const buttonClass = computed(() => ({
  'btn-danger': status.value === AsyncButtonStatus.ERROR,
  [`btn-${props.variant}`]:
        // eslint-disable-next-line max-len
        status.value === AsyncButtonStatus.INITIAL
        || status.value === AsyncButtonStatus.IN_PROGRESS,
  'btn-success': status.value === AsyncButtonStatus.SUCCESSFUL,
}));

async function run() {
  status.value = AsyncButtonStatus.IN_PROGRESS;

  try {
    emits('status', status.value, '');
    props
      .fn()
      .then(() => {
        status.value = AsyncButtonStatus.SUCCESSFUL;
        emits('status', status.value, '');
        emits('success', status.value);
        setTimeoutProp();
      })
      .catch((e) => {
        status.value = AsyncButtonStatus.ERROR;
        emits('status', status.value, e);
        emits('error', e);
        setTimeoutProp();
      });
  } catch (e) {
    setTimeoutProp();
    throw e;
  }
}

function click() {
  if (props.confirmModal) {
    if (typeof props.confirmModal === 'string') {
      modalData.message = confirmModal.value as string;
    }
    modalRef.value.show();
  } else {
    run();
  }
}

function onConfirmation() {
  run();
}


return (_ctx: any,_cache: any) => {
  const _component_font_awesome_icon = _resolveComponent("font-awesome-icon")!

  return (_openBlock(), _createBlock(_unref(BButton), {
    size: __props.size,
    class: _normalizeClass(buttonClass.value),
    disabled: __props.disabled || status.value !== _unref(AsyncButtonStatus).INITIAL,
    variant: __props.variant,
    onClick: click
  }, {
    default: _withCtx(() => [
      (status.value === _unref(AsyncButtonStatus).INITIAL)
        ? (_openBlock(), _createElementBlock("span", _hoisted_1, [
            _createVNode(_component_font_awesome_icon, {
              class: "cert-button-icon",
              icon: __props.initialIcon
            }, null, 8, ["icon"])
          ]))
        : _createCommentVNode("", true),
      (status.value === _unref(AsyncButtonStatus).IN_PROGRESS)
        ? (_openBlock(), _createElementBlock("span", _hoisted_2))
        : _createCommentVNode("", true),
      (status.value === _unref(AsyncButtonStatus).SUCCESSFUL)
        ? (_openBlock(), _createElementBlock("span", _hoisted_3, [
            _createVNode(_component_font_awesome_icon, {
              class: "cert-button-icon",
              icon: "check-circle"
            })
          ]))
        : _createCommentVNode("", true),
      (status.value === _unref(AsyncButtonStatus).ERROR)
        ? (_openBlock(), _createElementBlock("span", _hoisted_4, [
            _createVNode(_component_font_awesome_icon, {
              class: "cert-button-icon",
              icon: "times-circle"
            })
          ]))
        : _createCommentVNode("", true),
      _createTextVNode(" " + _toDisplayString(props.name) + " ", 1),
      (!!modalData)
        ? (_openBlock(), _createBlock(_unref(BModal), {
            key: 4,
            id: modalId,
            ref_key: "modalRef",
            ref: modalRef,
            title: "Confirmation",
            size: __props.modalSize,
            onOk: onConfirmation
          }, {
            "modal-footer": _withCtx(({ ok, cancel }) => [
              _createVNode(_unref(BButton), {
                modelValue: modalData,
                "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((modalData) = $event)),
                onClick: ($event: any) => (cancel())
              }, {
                default: _withCtx(() => [
                  _createTextVNode(_toDisplayString(modalData.cancelButton), 1)
                ]),
                _: 2
              }, 1032, ["modelValue", "onClick"]),
              _createVNode(_unref(BButton), {
                variant: __props.variant,
                onClick: ($event: any) => (ok())
              }, {
                default: _withCtx(() => [
                  _createTextVNode(_toDisplayString(modalData.confirmButton), 1)
                ]),
                _: 2
              }, 1032, ["variant", "onClick"])
            ]),
            default: _withCtx(() => [
              _renderSlot(_ctx.$slots, "default", {}, () => [
                _createTextVNode(_toDisplayString(modalData.message), 1)
              ])
            ]),
            _: 3
          }, 8, ["size"]))
        : _createCommentVNode("", true)
    ]),
    _: 3
  }, 8, ["size", "class", "disabled", "variant"]))
}
}

})