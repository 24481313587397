import { defineComponent as _defineComponent } from 'vue'
import { createTextVNode as _createTextVNode, unref as _unref, withCtx as _withCtx, createVNode as _createVNode, toDisplayString as _toDisplayString, resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, createElementBlock as _createElementBlock, resolveDirective as _resolveDirective, withDirectives as _withDirectives } from "vue"

const _hoisted_1 = { class: "row" }
const _hoisted_2 = { class: "col-offset-2 col-12" }
const _hoisted_3 = { class: "row" }
const _hoisted_4 = { class: "col-12" }
const _hoisted_5 = { class: "mt-4" }
const _hoisted_6 = { class: "col-12 my-4" }
const _hoisted_7 = { id: "tenant-details" }
const _hoisted_8 = {
  key: 0,
  id: "tenantMembersAndAccess",
  class: "p-1"
}
const _hoisted_9 = { class: "mt-4" }
const _hoisted_10 = { id: "active-firefighters-title" }
const _hoisted_11 = {
  key: 0,
  class: "alert alert-light"
}
const _hoisted_12 = ["href"]
const _hoisted_13 = ["href"]
const _hoisted_14 = { id: "scheduled-firefighters-title" }
const _hoisted_15 = {
  key: 0,
  class: "alert alert-light"
}
const _hoisted_16 = ["href"]
const _hoisted_17 = ["href"]
const _hoisted_18 = { class: "mt-4" }
const _hoisted_19 = {
  key: 0,
  class: "alert alert-light"
}
const _hoisted_20 = { class: "row mt-4 align-items-center" }
const _hoisted_21 = { class: "col-2" }
const _hoisted_22 = { class: "col-2" }
const _hoisted_23 = { class: "col" }
const _hoisted_24 = { class: "row g-0" }
const _hoisted_25 = { class: "col-auto" }
const _hoisted_26 = {
  key: 0,
  class: "alert alert-light"
}

import { formatRelative } from 'date-fns';
import axios, { AxiosError } from 'axios';
import {
  computed, onBeforeMount, onMounted, reactive, ref,
} from 'vue';
import {
  BTable, BBreadcrumb, BBreadcrumbItem, BLink, BCol, BRow,
  BContainer, BCard, BButton, BTab, BTabs, BModal, BPopover,
  useToastController,
  BaseColorVariant,
} from 'bootstrap-vue-next';
import { useRoute } from 'vue-router';
import JsonCSV from 'vue-json-csv';
import AddUserToTenantRole from '@/components/AddUserToTenantRole.vue';
import TenantDetailComponent from '@/components/TenantDetailComponent.vue';
import AccountDetailComponent from '@/components/AccountDetailComponent.vue';
import AddUserToTenantFirefighter from '@/components/AddUserToTenantFirefighter.vue';
import AsyncTable from '@/components/AsyncTable.vue';
import AsyncButton from '@/components/AsyncButton.vue';
import Bubblewrap from '@/components/BubblewrapComponent.vue';
import TenantContacts from '@/components/TenantContacts.vue';
import PaginatedMembersTable from '@/components/PaginatedMembersTable.vue';
import CollapsibleComponent from '@/components/CollapsibleComponent.vue';
import DropDownListComponent from '@/components/DropDownListComponent.vue';
import { Permission, TenantRepresentation } from '@/models/keycloak';
import { SelectOptionsDropDown, TenantInformation } from '@/models/tenant';
import { getKeycloakRequestConfig } from '@/utils/http';
import { userStore } from '@/store/modules/user';
import { useApiCall, useAsyncButton } from '@/composable/apiStates';
import { AsyncButtonStatus } from '@/models/asyncButton';
import { fetchTenant } from '@/api/tenant';
import { FirefighterAccessRepresentation, FirefighterStatus } from '@/models/firefighter';
import FirefighterAccessRequest from '../components/FirefighterAccessRequest.vue';

enum FilterMembers {
  ALL = 'All',
  EXPIRING_SOON = 'Expiring Soon',
}

const tableNameOffboard = 'offboarded-members';

export default /*@__PURE__*/_defineComponent({
  __name: 'TenantDetails',
  setup(__props) {

const defaultFields = [
  {
    key: 'lastName',
    label: 'Name',
    sortable: true,
    class: 'align-middle',
  },
  {
    key: 'environment',
    label: 'Environments',
    sortable: true,
    class: 'align-middle',
  },
  {
    key: 'role',
    label: 'Role',
    sortable: true,
    class: 'align-middle',
  },
];

// eslint-disable-next-line no-shadow
const tenantName = ref();
const disabledFields = defaultFields;
const user = userStore();
const apiCall = useApiCall();
const status = useAsyncButton();
const { show } = useToastController();
const showAddUserToTenantModal = ref(false);
const showAddFirefighterModal = ref(false);

const fields = reactive([
  ...defaultFields,
  {
    key: 'certificationData',
    label: 'Certification expires in',
    sortable: true,
    class: 'align-middle',
  },
  {
    key: 'actions',
    label: 'Actions',
    sortable: false,
    class: 'align-middle',
  },
]);

const firefighterFields = ref([
  {
    key: 'fullName',
    label: 'Name',
    sortable: true,
    class: 'align-middle',
    formatter: (_value: any, _key: string, item: any) => `${item.user.firstName} ${item.user.lastName}`,
  },
  {
    key: 'role',
    label: 'Role',
    sortable: true,
    class: 'align-middle',
  },
  {
    key: 'grantedByFullName',
    label: 'Granted by',
    sortable: true,
    class: 'align-middle',
    formatter: (_value: any, _key: string, item: any) => `${item.grantedBy.firstName} ${item.grantedBy.lastName}`,
  },
  {
    key: 'revokeAt',
    label: 'Access until',
    sortable: true,
    class: 'align-middle',
  },
  {
    key: 'reason',
    label: 'Reason',
    class: 'align-middle',
  },
  {
    key: 'actions',
    label: 'Actions',
    sortable: false,
    class: 'align-middle',
  },
]);

const scheduledFirefighterFields = ref([
  {
    key: 'fullName',
    label: 'Name',
    sortable: true,
    class: 'align-middle',
    formatter: (_value: any, _key: string, item: any) => `${item.user.firstName} ${item.user.lastName}`,
  },
  {
    key: 'role',
    label: 'Role',
    sortable: true,
    class: 'align-middle',
  },
  {
    key: 'grantedByFullName',
    label: 'Granted by',
    sortable: true,
    class: 'align-middle',
    formatter: (_value: any, _key: string, item: any) => `${item.grantedBy.firstName} ${item.grantedBy.lastName}`,
  },
  {
    key: 'startAt',
    label: 'Start time',
    sortable: true,
    class: 'align-middle',
  },
  {
    key: 'revokeAt',
    label: 'Access until',
    sortable: true,
    class: 'align-middle',
  },
  {
    key: 'reason',
    label: 'Reason',
    class: 'align-middle',
  },
  {
    key: 'actions',
    label: 'Actions',
    sortable: false,
    class: 'align-middle',
  },
]);

const route = useRoute();

const users = ref<Array<Permission & { _uniqueId: string }>>([]);

const firefighterAccesses = ref([]);

const scheduledFirefighterAccesses = ref([]);

const tenantInfo: TenantInformation = reactive({
  accounts: [],
  region: '',
  complianceProfile: '',
  artifactoryRepositories: { virtualRepo: [], releaseRepo: [], snapshotRepo: [] },
  roles: [],
  contacts: {
    approver: [] as string[],
    escalator: [] as string[],
    responsible: [] as string[],
  },
  quickSight: false,
});

const tenantRoles = computed(() => [...tenantInfo.roles]);

const tenantPermissions = reactive([]);

const isApprover = ref(false);

const formattedDate = ref('');

const dateToFormat = new Date();

const activeFilter = ref(FilterMembers.ALL);

const expireSoon = ref('');

const expirePerPageOptions = [
  SelectOptionsDropDown.EXPIRES,
  '30 days',
  '50 days',
  '70 days',
  '80 days',
];

const membersPerPageOptions = ['50', '100', 'All'];

const membersPerPage = ref(100);

const deactivatedUsers = computed(() => users.value.filter((u) => u.enabled === false));

const getUniqueId = (userId: string, role: string, environment: string) => `${userId}.${role}.${environment}`;

const getMembers = computed(() => {
  const enabledUsers = users.value.filter((u) => u.enabled === true);
  const matches = expireSoon.value.match(/(\d+)/);
  if (activeFilter.value === FilterMembers.ALL) {
    return enabledUsers;
  }

  if (activeFilter.value === FilterMembers.EXPIRING_SOON) {
    const expiredMembers = enabledUsers.filter((u) => {
      if (u.certificationData) {
        return u.certificationData.expiresIn <= matches[0];
      }
      return false;
    });
    return expiredMembers;
  }

  return users.value;
});

const updateMemberList = (member) => {
  const indexToRemove = users.value.findIndex((u) => u.id === member.id && u.role === member.role);

  if (indexToRemove !== -1) {
    // eslint-disable-next-line max-len
    const newUsersArray = [
      ...users.value.slice(0, indexToRemove),
      ...users.value.slice(indexToRemove + 1),
    ];
    users.value = newUsersArray;
  }
};

const optionsSelector = (items) => {
  const options = [];
  // eslint-disable-next-line array-callback-return
  items.map((element) => {
    options.push(
      ...[
        {
          item: element,
          title: element,
          disabled: element === 'Expires in',
        },
      ],
    );
  });
  return options;
};

const updateUsersTable = (selectedValue) => {
  if (selectedValue === 'All') {
    membersPerPage.value = users.value.length;
  } else {
    membersPerPage.value = Number(selectedValue);
  }
};

const resultsPerPage = computed(() => membersPerPage.value);

const updateMemberExpireList = (selectedValue) => {
  expireSoon.value = selectedValue;
  activeFilter.value = FilterMembers.EXPIRING_SOON;
};

async function fetchMyTenantPermissions(): Promise<any> {
  return axios
    .get<TenantRepresentation>(
      `/users/${user.id}/tenants/${tenantName.value}`,
      getKeycloakRequestConfig(user.xsrfToken),
    )
    .then((response) => response.data);
}

const checkFireFighter = computed(() => firefighterAccesses.value.length > 0);

const enabledUsers = computed(() => users.value.filter((u) => u.enabled === true));

onMounted(() => {
  formattedDate.value = formatRelative(dateToFormat, new Date());
});

onBeforeMount(async () => {
  tenantName.value = route.params.tenantName;

  try {
    const hasFirefighterInUrl = window.location.hash.includes('username');

    if (hasFirefighterInUrl) {
      showAddFirefighterModal.value = true;
    }
  } catch (error) {
    return;
  }

  let data: TenantInformation;
  try {
    const tenant: TenantRepresentation = await fetchMyTenantPermissions();

    Object.assign(tenantPermissions, tenant.permissions);
    data = await fetchTenant(tenantName.value);

    isApprover.value = tenantPermissions.includes('approver');

    apiCall.loaded = true;

    Object.assign(tenantInfo, data);
  } catch (error) {
    console.log('error', error);
  }
});

const toast = (variant: string, title: string, message: string) => {
  show?.({
    props: {
      body: message,
      variant: variant as keyof BaseColorVariant,
      title,
      value: 10000,
    },
  });
};

const handleUpdateStatus = (statusCode: { status: AsyncButtonStatus; message: string }) => {
  status.status = statusCode.status;
  status.message = statusCode.message;
};

function handleRequestFirefighter(statusCode: number) {
  if (statusCode === 204) {
    toast(
      'success',
      'Firefighter Access requested',
      `Firefighter access requested for tenant ${tenantName.value}. Please ask an Approver to check
      their mails and accept the request`,
    );
  }
}

function handleAddRoleError(error: Error) {
  toast('danger', ' Could not add user to role', `${error}`);
}
function handleFirefighterError(error: Error) {
  toast('danger', ' Could not add user', `${error}`);
}

/**
 * fetchUsers loads the logged-in approver tenants from the API
 */
async function fetchUsers() {
  return (
    axios
      .get<Permission[]>(
        `/tenants/${route.params.tenantName}/users`,
        getKeycloakRequestConfig(user.xsrfToken),
      )
      .then((response) => response.data)
      // Generate a unique id so that the table does not get confused about the content of the
      // rows when adding or removing users
      .then((usersData) => usersData.map((u) => ({
        ...u,
        _uniqueId: getUniqueId(u.id, u.role, u.environment),
      })))
  );
}

async function filterMembers(type: FilterMembers) {
  activeFilter.value = type;
  expireSoon.value = '';
}

// const hasCertificationData = (tenantMember: Permission) => {
//   const { environment, role } = tenantMember;
//   return environment !== 'prod' && role !== 'escalator' && role !== 'responsible';
// };

const csvData = computed(() => enabledUsers.value.map((u) => ({
  tenant: route.params.tenantName,
  name: `${u.firstName} ${u.lastName}`,
  email: u.email,
  environment: u.environment,
  role: u.role,
  certificationExpiresAt: u.certificationData ? u.certificationData.expiresAt : 'not certified',
})));

async function loadFirefighterTable(
  fireFighterStatus: FirefighterStatus,
): Promise<FirefighterAccessRepresentation[]> {
  const response = await axios.get<FirefighterAccessRepresentation[]>(
    `/tenants/${route.params.tenantName}/firefighters?status=${fireFighterStatus}`,
    getKeycloakRequestConfig(user.xsrfToken),
  );

  return response.data.map((access) => ({
    ...access,
    startAt: new Date(access.startAt),
    revokeAt: new Date(access.revokeAt),
  }));
}

// /**
//  * Returns a list of firefighter roles a user can request
//  */
// const requestableFirefighterRoles = computed(
//   () => tenantInfo.roles.filter((r) => r.hasFirefighterRole),
// );

/**
 * Generates the firefighter confirmation message.
 */
// const confirmationFirefighter = (
//   username: string,
//   fireFighterTenantName: string,
//   role: string,
//   duration: number,
// ) => 'You are about to '
//   + `grant production access to ${username} for your `
//   + `tenant ${fireFighterTenantName} with the role ${role} for ${duration}h. `
//   + 'Do you want to continue?';

async function loadUsers() {
  const usersData = await fetchUsers();
  users.value = usersData;
  return users;
}

async function loadActiveFirefighterTable() {
  const firefighterAccessesData = (await loadFirefighterTable(FirefighterStatus.ACTIVE))
    // Filter firefighters that are in the process of being cancelled
    .filter((access) => !access.canceledAt);

  firefighterAccesses.value = firefighterAccessesData;
}

async function loadScheduledFirefighterTable() {
  const scheduledFirefighterAccessesData = await loadFirefighterTable(FirefighterStatus.SCHEDULED);
  scheduledFirefighterAccesses.value = scheduledFirefighterAccessesData;
}

/**
 * Calls Firefighter access cancellation service for both scheduled and active FF.
 * Then it removes the FF from the table.
 */
async function cancelFirefighter(data: FirefighterAccessRepresentation, scheduled: boolean) {
  await axios.put(
    `/firefighter/accesses/${data.id}/cancel`,
    {},
    getKeycloakRequestConfig(user.xsrfToken),
  );

  if (scheduled) {
    scheduledFirefighterAccesses.value = scheduledFirefighterAccesses.value.filter(
      (elem) => elem.id !== data.id,
    );
  } else {
    // eslint-disable-next-line max-len
    firefighterAccesses.value = firefighterAccesses.value.filter((elem) => elem.id !== data.id);
  }

  users.value = users.value.filter(
    // eslint-disable-next-line no-underscore-dangle
    (elem) => elem._uniqueId !== getUniqueId(data.user.id, data.role, 'prod'),
  );
  toast(
    'success',
    'Firefighter successfully canceled',
    `${data.user.email} ${scheduled ? 'scheduled' : 'active'} firefighter is canceled.`,
  );
}

function handleCancelFirefighterError(error: AxiosError<any>, scheduled: boolean) {
  let errorMessage = error;

  if (error.response && error.response.data.message) {
    errorMessage = error.response.data.message;
  }

  toast(
    'danger',
    'Cancellation failed',
    `Could not cancel the ${scheduled ? 'scheduled' : 'active'} firefighter: ${errorMessage}`,
  );
}

function addUser(newUser: Permission, silent: boolean = false) {
  users.value.unshift({
    ...newUser,
    _uniqueId: getUniqueId(newUser.id, newUser.role, newUser.environment),
  });

  if (!silent) {
    toast(
      'success',
      'User added',
      `User ${newUser.email} with role ${newUser.role} added to tenant ${tenantName.value}`,
    );
  }
}

/**
 * Callback for firefighter access.
 */
function addFirefighter(access: FirefighterAccessRepresentation, scheduled: boolean) {
  showAddFirefighterModal.value = false;

  if (scheduled) {
    scheduledFirefighterAccesses.value.unshift({
      ...access,
      startAt: new Date(access.startAt),
      revokeAt: new Date(access.revokeAt),
    });

    toast(
      'success',
      'Firefighter access scheduled',
      `Access will be granted to ${access.user.email} at ${access.startAt}`,
    );
  } else {
    firefighterAccesses.value.unshift({
      ...access,
      startAt: new Date(access.startAt),
      revokeAt: new Date(access.revokeAt),
    });

    toast(
      'success',
      'Firefighter access granted',
      `Access granted to ${access.user.email} until ${access.revokeAt}`,
    );

    // Push into the users array to prevent additional firefighter addition
    addUser(
      {
        id: access.user.id,
        enabled: true,
        group: `${access.tenant}.${access.role}.prod`,
        groupId: 'n/a',
        username: access.user.email,
        firstName: access.user.firstName,
        lastName: access.user.lastName,
        email: access.user.email,
        environment: 'prod',
        role: access.role,
      },
      true,
    );
  }
}


return (_ctx: any,_cache: any) => {
  const _component_font_awesome_icon = _resolveComponent("font-awesome-icon")!
  const _component_ContentLoader = _resolveComponent("ContentLoader")!
  const _directive_b_tooltip = _resolveDirective("b-tooltip")!

  return (_openBlock(), _createBlock(_unref(BContainer), { id: "profile" }, {
    default: _withCtx(() => [
      _createElementVNode("div", _hoisted_1, [
        _createElementVNode("div", _hoisted_2, [
          _createElementVNode("div", _hoisted_3, [
            _createElementVNode("div", _hoisted_4, [
              _createVNode(_unref(BBreadcrumb), null, {
                default: _withCtx(() => [
                  _createVNode(_unref(BBreadcrumbItem), { to: { name: 'myTenants' } }, {
                    default: _withCtx(() => _cache[12] || (_cache[12] = [
                      _createTextVNode(" My Tenants ")
                    ])),
                    _: 1
                  }),
                  _createVNode(_unref(BBreadcrumbItem), { active: "" }, {
                    default: _withCtx(() => [
                      _createTextVNode(_toDisplayString(tenantName.value), 1)
                    ]),
                    _: 1
                  })
                ]),
                _: 1
              }),
              _createElementVNode("h3", _hoisted_5, [
                _createTextVNode(" Tenant Options for: " + _toDisplayString(tenantName.value) + " ", 1),
                (isApprover.value)
                  ? (_openBlock(), _createBlock(_unref(BLink), {
                      key: 0,
                      id: "tenant-settings",
                      to: { name: 'tenantSettings', params: { tenantName: tenantName.value } },
                      class: "float-end text-dark"
                    }, {
                      default: _withCtx(() => [
                        _createVNode(_component_font_awesome_icon, {
                          size: "xs",
                          icon: 'cog'
                        })
                      ]),
                      _: 1
                    }, 8, ["to"]))
                  : _createCommentVNode("", true)
              ]),
              _cache[13] || (_cache[13] = _createElementVNode("p", { id: "roles" }, null, -1))
            ]),
            _createVNode(_unref(BCol), { id: "requestFirefighter" }, {
              default: _withCtx(() => [
                _createVNode(FirefighterAccessRequest, {
                  "tenant-name": tenantName.value,
                  "tenant-info": tenantInfo,
                  "tenant-roles": tenantRoles.value,
                  "successful-event": (status) => handleRequestFirefighter(status),
                  onError: _cache[0] || (_cache[0] = (err) => handleFirefighterError(err))
                }, null, 8, ["tenant-name", "tenant-info", "tenant-roles", "successful-event"])
              ]),
              _: 1
            }),
            _createElementVNode("div", _hoisted_6, [
              _createElementVNode("div", _hoisted_7, [
                _createVNode(_unref(BCard), null, {
                  default: _withCtx(() => [
                    _createVNode(CollapsibleComponent, {
                      "is-header": true,
                      header: 'Tenant Information',
                      "toggle-id": 'tenant-info-collapsible',
                      "is-visible": !isApprover.value && _unref(apiCall).loaded
                    }, {
                      default: _withCtx(() => [
                        _createVNode(Bubblewrap, { info: _unref(apiCall) }, {
                          placeholder: _withCtx(() => [
                            _createVNode(_unref(BContainer), null, {
                              default: _withCtx(() => [
                                _createVNode(_unref(BRow), null, {
                                  default: _withCtx(() => [
                                    _createVNode(_unref(BCol), null, {
                                      default: _withCtx(() => [
                                        _cache[14] || (_cache[14] = _createElementVNode("div", { class: "spacer" }, null, -1)),
                                        _createVNode(_component_ContentLoader)
                                      ]),
                                      _: 1
                                    })
                                  ]),
                                  _: 1
                                })
                              ]),
                              _: 1
                            })
                          ]),
                          loaded: _withCtx(() => [
                            _createVNode(_unref(BRow), { class: "row-gap-4" }, {
                              default: _withCtx(() => [
                                _createVNode(_unref(BCol), {
                                  class: "",
                                  md: "6"
                                }, {
                                  default: _withCtx(() => [
                                    _createVNode(TenantDetailComponent, {
                                      "compliance-profile": tenantInfo.complianceProfile,
                                      region: tenantInfo.region,
                                      "quick-sight": tenantInfo.quickSight,
                                      "artifactory-repositories": tenantInfo.artifactoryRepositories
                                    }, null, 8, ["compliance-profile", "region", "quick-sight", "artifactory-repositories"])
                                  ]),
                                  _: 1
                                }),
                                _createVNode(_unref(BCol), { md: "6" }, {
                                  default: _withCtx(() => [
                                    _cache[15] || (_cache[15] = _createElementVNode("h5", { class: "" }, " Accounts ", -1)),
                                    _createVNode(_unref(BRow), {
                                      cols: "1",
                                      class: "gap-2"
                                    }, {
                                      default: _withCtx(() => [
                                        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(tenantInfo.accounts, (account, idx) => {
                                          return (_openBlock(), _createBlock(_unref(BCol), { key: idx }, {
                                            default: _withCtx(() => [
                                              _createVNode(CollapsibleComponent, {
                                                class: "gy-4",
                                                "is-header": false,
                                                header: account.accountAlias,
                                                "is-visible": false,
                                                "toggle-id": `${account.accountAlias}-${idx}`
                                              }, {
                                                default: _withCtx(() => [
                                                  _createVNode(AccountDetailComponent, {
                                                    class: "gy-4",
                                                    "account-alias": account.accountAlias,
                                                    "account-id": account.accountID,
                                                    "mps-connect-cidr": account.mpsConnectCIDR,
                                                    "mps-connect-enabled": account.mpsConnectEnabled,
                                                    "vpc-a-zs": account.vpcAZs,
                                                    "vpc-cidr": account.vpcCIDR,
                                                    idx: idx
                                                  }, null, 8, ["account-alias", "account-id", "mps-connect-cidr", "mps-connect-enabled", "vpc-a-zs", "vpc-cidr", "idx"])
                                                ]),
                                                _: 2
                                              }, 1032, ["header", "toggle-id"])
                                            ]),
                                            _: 2
                                          }, 1024))
                                        }), 128))
                                      ]),
                                      _: 1
                                    })
                                  ]),
                                  _: 1
                                }),
                                _createVNode(_unref(BCol), { md: "12" }, {
                                  default: _withCtx(() => [
                                    _createVNode(TenantContacts, {
                                      approvers: tenantInfo.contacts.approver,
                                      escalators: tenantInfo.contacts.escalator,
                                      responsibles: tenantInfo.contacts.responsible
                                    }, null, 8, ["approvers", "escalators", "responsibles"])
                                  ]),
                                  _: 1
                                })
                              ]),
                              _: 1
                            })
                          ]),
                          _: 1
                        }, 8, ["info"])
                      ]),
                      _: 1
                    }, 8, ["is-visible"])
                  ]),
                  _: 1
                })
              ])
            ]),
            (isApprover.value)
              ? (_openBlock(), _createBlock(_unref(BCol), {
                  key: 0,
                  lg: "6",
                  md: "12"
                }))
              : _createCommentVNode("", true)
          ]),
          (isApprover.value)
            ? (_openBlock(), _createElementBlock("div", _hoisted_8, [
                _cache[27] || (_cache[27] = _createElementVNode("p", { class: "lead" }, " You can see all the users of the tenant below. ", -1)),
                _createElementVNode("h4", _hoisted_9, [
                  _createVNode(_unref(BRow), null, {
                    default: _withCtx(() => [
                      _createVNode(_unref(BCol), {
                        cols: "10",
                        md: "8"
                      }, {
                        default: _withCtx(() => [
                          _cache[17] || (_cache[17] = _createTextVNode(" Firefighter Accesses ")),
                          _createVNode(_unref(BButton), {
                            id: "show-tenant-reports",
                            size: "sm",
                            to: { name: 'tenantReports', params: { tenantName: tenantName.value } }
                          }, {
                            default: _withCtx(() => _cache[16] || (_cache[16] = [
                              _createTextVNode(" Show Reports ")
                            ])),
                            _: 1
                          }, 8, ["to"])
                        ]),
                        _: 1
                      }),
                      _createVNode(_unref(BCol), {
                        cols: "2",
                        md: "4"
                      }, {
                        default: _withCtx(() => [
                          _createVNode(_unref(BButton), {
                            id: "add-firefighter",
                            class: "float-end",
                            variant: "primary",
                            onClick: _cache[1] || (_cache[1] = ($event: any) => (showAddFirefighterModal.value = !showAddFirefighterModal.value))
                          }, {
                            default: _withCtx(() => _cache[18] || (_cache[18] = [
                              _createTextVNode(" Add Firefighter ")
                            ])),
                            _: 1
                          }),
                          _createVNode(_unref(BModal), {
                            id: "add-user-to-tenant-firefighter-modal",
                            modelValue: showAddFirefighterModal.value,
                            "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event: any) => ((showAddFirefighterModal).value = $event)),
                            title: `Add Firefighter to ${tenantName.value}`,
                            size: "lg",
                            "no-footer": ""
                          }, {
                            default: _withCtx(() => [
                              _createVNode(AddUserToTenantFirefighter, {
                                visible: showAddFirefighterModal.value,
                                "tenant-name": tenantName.value,
                                "tenant-info": tenantInfo,
                                "tenant-members": getMembers.value,
                                "successful-event": (access, scheduled) => addFirefighter(access, scheduled),
                                "firefighter-accesses": firefighterAccesses.value,
                                onHide: _cache[2] || (_cache[2] = () => showAddFirefighterModal.value = false),
                                onError: _cache[3] || (_cache[3] = (err) => handleFirefighterError(err))
                              }, null, 8, ["visible", "tenant-name", "tenant-info", "tenant-members", "successful-event", "firefighter-accesses"])
                            ]),
                            _: 1
                          }, 8, ["modelValue", "title"])
                        ]),
                        _: 1
                      })
                    ]),
                    _: 1
                  })
                ]),
                _createVNode(_unref(BCard), { "no-body": "" }, {
                  default: _withCtx(() => [
                    _createVNode(_unref(BTabs), {
                      id: "firefighterTabs",
                      card: ""
                    }, {
                      default: _withCtx(() => [
                        _createVNode(_unref(BTab), null, {
                          title: _withCtx(() => [
                            _createElementVNode("span", _hoisted_10, [
                              _cache[19] || (_cache[19] = _createTextVNode(" Active Firefighters ")),
                              (checkFireFighter.value)
                                ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
                                    _createTextVNode(" (" + _toDisplayString(firefighterAccesses.value.length) + ") ", 1)
                                  ], 64))
                                : _createCommentVNode("", true)
                            ])
                          ]),
                          default: _withCtx(() => [
                            _createVNode(AsyncTable, {
                              headline: "",
                              fn: () => loadActiveFirefighterTable()
                            }, {
                              default: _withCtx(() => [
                                (!checkFireFighter.value)
                                  ? (_openBlock(), _createElementBlock("div", _hoisted_11, " There is no temporary production access at the moment. "))
                                  : _createCommentVNode("", true),
                                (firefighterAccesses.value.length > 0)
                                  ? (_openBlock(), _createBlock(_unref(BTable), {
                                      key: 1,
                                      id: "firefighters",
                                      striped: "",
                                      hover: "",
                                      responsive: "",
                                      items: firefighterAccesses.value,
                                      fields: firefighterFields.value,
                                      "primary-key": "id"
                                    }, {
                                      "cell(fullName)": _withCtx((data) => [
                                        _createElementVNode("a", {
                                          class: "text-decoration-none",
                                          href: `mailto:${data.item.user.email}`
                                        }, _toDisplayString(data.item.user.firstName) + " " + _toDisplayString(data.item.user.lastName), 9, _hoisted_12)
                                      ]),
                                      "cell(grantedByFullName)": _withCtx((data) => [
                                        _createElementVNode("a", {
                                          class: "text-decoration-none",
                                          href: `mailto:${data.item.grantedBy.email}`
                                        }, _toDisplayString(data.item.grantedBy.firstName) + " " + _toDisplayString(data.item.grantedBy.lastName), 9, _hoisted_13)
                                      ]),
                                      "cell(revokeAt)": _withCtx((data) => [
                                        _createTextVNode(_toDisplayString(_unref(formatRelative)(data.item.revokeAt, new Date())), 1)
                                      ]),
                                      "cell(startAt)": _withCtx((data) => [
                                        _createTextVNode(_toDisplayString(_unref(formatRelative)(data.item.startAt, new Date())), 1)
                                      ]),
                                      "cell(reason)": _withCtx((data) => [
                                        _withDirectives(_createVNode(_component_font_awesome_icon, {
                                          id: `scheduled_firefighter_${data.item.id}`,
                                          class: "ff-button-icon",
                                          icon: "info-circle"
                                        }, null, 8, ["id"]), [
                                          [_directive_b_tooltip, data.item.reason]
                                        ])
                                      ]),
                                      "cell(actions)": _withCtx((data) => [
                                        _createVNode(AsyncButton, {
                                          id: "cancelActiveFirefighter",
                                          name: "Cancel",
                                          variant: "secondary",
                                          "confirm-modal": 
                          'Are you sure you want to \ncancel this firefighter action?'
                        ,
                                          "initial-icon": "ban",
                                          fn: () => cancelFirefighter(data.item, true),
                                          onError: _cache[5] || (_cache[5] = (err) => handleCancelFirefighterError(err, true)),
                                          onStatus: handleUpdateStatus
                                        }, null, 8, ["fn"])
                                      ]),
                                      _: 1
                                    }, 8, ["items", "fields"]))
                                  : _createCommentVNode("", true)
                              ]),
                              _: 1
                            }, 8, ["fn"])
                          ]),
                          _: 1
                        }),
                        _createVNode(_unref(BTab), { id: "scheduled-firefighters" }, {
                          title: _withCtx(() => [
                            _createElementVNode("span", _hoisted_14, [
                              _cache[20] || (_cache[20] = _createTextVNode(" Scheduled Firefighters ")),
                              (scheduledFirefighterAccesses.value)
                                ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
                                    _createTextVNode(" (" + _toDisplayString(scheduledFirefighterAccesses.value.length) + ") ", 1)
                                  ], 64))
                                : _createCommentVNode("", true)
                            ])
                          ]),
                          default: _withCtx(() => [
                            _createVNode(AsyncTable, {
                              headline: "",
                              fn: () => loadScheduledFirefighterTable()
                            }, {
                              default: _withCtx(() => [
                                (scheduledFirefighterAccesses.value.length === 0)
                                  ? (_openBlock(), _createElementBlock("div", _hoisted_15, " There are currently no scheduled firefighter accesses. "))
                                  : _createCommentVNode("", true),
                                (scheduledFirefighterAccesses.value.length > 0)
                                  ? (_openBlock(), _createBlock(_unref(BTable), {
                                      key: 1,
                                      id: "scheduledFirefighters",
                                      striped: "",
                                      hover: "",
                                      responsive: "",
                                      items: scheduledFirefighterAccesses.value,
                                      fields: scheduledFirefighterFields.value,
                                      "primary-key": "id"
                                    }, {
                                      "cell(fullName)": _withCtx((data) => [
                                        _createElementVNode("a", {
                                          class: "text-decoration-none",
                                          href: `mailto:${data.item.user.email}`
                                        }, _toDisplayString(data.item.user.firstName) + " " + _toDisplayString(data.item.user.lastName), 9, _hoisted_16)
                                      ]),
                                      "cell(grantedByFullName)": _withCtx((data) => [
                                        _createElementVNode("a", {
                                          class: "text-decoration-none",
                                          href: `mailto:${data.item.grantedBy.email}`
                                        }, _toDisplayString(data.item.grantedBy.firstName) + " " + _toDisplayString(data.item.grantedBy.lastName), 9, _hoisted_17)
                                      ]),
                                      "cell(revokeAt)": _withCtx((data) => [
                                        _createTextVNode(_toDisplayString(_unref(formatRelative)(data.item.revokeAt, new Date())), 1)
                                      ]),
                                      "cell(startAt)": _withCtx((data) => [
                                        _createTextVNode(_toDisplayString(_unref(formatRelative)(data.item.startAt, new Date())), 1)
                                      ]),
                                      "cell(reason)": _withCtx((data) => [
                                        _withDirectives(_createVNode(_component_font_awesome_icon, {
                                          id: `scheduled_firefighter_${data.item.id}`,
                                          class: "ff-button-icon",
                                          icon: "info-circle"
                                        }, null, 8, ["id"]), [
                                          [_directive_b_tooltip, data.item.reason]
                                        ])
                                      ]),
                                      "cell(actions)": _withCtx((data) => [
                                        _createVNode(AsyncButton, {
                                          id: "cancelScheduledFirefighter",
                                          name: "Cancel",
                                          variant: "secondary",
                                          "confirm-modal": 
                          'Are you sure you want to cancel \nthis firefighter action?'
                        ,
                                          "initial-icon": "ban",
                                          fn: () => cancelFirefighter(data.item, true),
                                          onError: _cache[6] || (_cache[6] = (err) => handleCancelFirefighterError(err, true)),
                                          onStatus: handleUpdateStatus
                                        }, null, 8, ["fn"])
                                      ]),
                                      _: 1
                                    }, 8, ["items", "fields"]))
                                  : _createCommentVNode("", true)
                              ]),
                              _: 1
                            }, 8, ["fn"])
                          ]),
                          _: 1
                        })
                      ]),
                      _: 1
                    })
                  ]),
                  _: 1
                }),
                _createVNode(AsyncTable, {
                  headline: "",
                  fn: () => loadUsers()
                }, {
                  headline: _withCtx(() => [
                    _createElementVNode("h4", _hoisted_18, [
                      _cache[22] || (_cache[22] = _createTextVNode(" Members ")),
                      _createVNode(_unref(JsonCSV), {
                        id: "download-tenant-member-table",
                        class: "btn btn-secondary btn-sm",
                        data: csvData.value,
                        name: `${_unref(route).params.tenantName}.members.csv`
                      }, {
                        default: _withCtx(() => _cache[21] || (_cache[21] = [
                          _createTextVNode(" Download as CSV ")
                        ])),
                        _: 1
                      }, 8, ["data", "name"])
                    ])
                  ]),
                  default: _withCtx(() => [
                    (enabledUsers.value.length === 0)
                      ? (_openBlock(), _createElementBlock("div", _hoisted_19, " There are no members in this tenant. Why don't you add some? "))
                      : _createCommentVNode("", true),
                    _createElementVNode("div", _hoisted_20, [
                      _createElementVNode("div", _hoisted_21, [
                        _createVNode(_unref(BRow), { "align-v": "center" }, {
                          default: _withCtx(() => [
                            _createVNode(_unref(BCol), { cols: "2" }, {
                              default: _withCtx(() => [
                                _createVNode(_component_font_awesome_icon, {
                                  icon: "filter",
                                  size: "lg",
                                  class: "text-secondary"
                                })
                              ]),
                              _: 1
                            }),
                            _createVNode(_unref(BCol), null, {
                              default: _withCtx(() => [
                                _createVNode(_unref(BButton), {
                                  style: {"width":"100%"},
                                  variant: "outline-secondary",
                                  onClick: _cache[7] || (_cache[7] = ($event: any) => (filterMembers(FilterMembers.ALL)))
                                }, {
                                  default: _withCtx(() => [
                                    _createTextVNode(_toDisplayString(FilterMembers.ALL), 1)
                                  ]),
                                  _: 1
                                })
                              ]),
                              _: 1
                            })
                          ]),
                          _: 1
                        })
                      ]),
                      _createElementVNode("div", _hoisted_22, [
                        _createVNode(DropDownListComponent, {
                          id: "expireDropDownList",
                          "default-option": 'Expires in',
                          options: optionsSelector(expirePerPageOptions),
                          onSelectValue: updateMemberExpireList
                        }, null, 8, ["options"])
                      ]),
                      _createVNode(_unref(BCol), {
                        cols: "2",
                        offset: "6",
                        md: "4",
                        "offset-md": "4"
                      }, {
                        default: _withCtx(() => [
                          _createVNode(_unref(BPopover), {
                            id: "add-user-to-tenant-modal",
                            "model-value": showAddUserToTenantModal.value,
                            placement: "left",
                            click: true,
                            delay: { show: 0, hide: 0 },
                            noninteractive: ""
                          }, {
                            target: _withCtx(() => [
                              _createVNode(_unref(BButton), {
                                class: "float-end",
                                variant: "primary",
                                onClick: _cache[8] || (_cache[8] = ($event: any) => (showAddUserToTenantModal.value = !showAddUserToTenantModal.value))
                              }, {
                                default: _withCtx(() => _cache[23] || (_cache[23] = [
                                  _createTextVNode(" Add Member ")
                                ])),
                                _: 1
                              })
                            ]),
                            title: _withCtx(() => _cache[24] || (_cache[24] = [
                              _createElementVNode("span", { class: "fs-6" }, "Add Member", -1)
                            ])),
                            default: _withCtx(() => [
                              _createVNode(AddUserToTenantRole, {
                                "tenant-name": tenantName.value,
                                "successful-event": (user) => addUser(user),
                                users: users.value,
                                "tenant-info": tenantInfo,
                                onHide: _cache[9] || (_cache[9] = () => showAddUserToTenantModal.value = false),
                                onError: _cache[10] || (_cache[10] = (err) => handleAddRoleError(err))
                              }, null, 8, ["tenant-name", "successful-event", "users", "tenant-info"])
                            ]),
                            _: 1
                          }, 8, ["model-value"])
                        ]),
                        _: 1
                      })
                    ]),
                    _createVNode(PaginatedMembersTable, {
                      id: "members",
                      fields: fields,
                      "disabled-fields": _unref(disabledFields),
                      toast: toast,
                      users: getMembers.value,
                      "per-page": resultsPerPage.value,
                      onRemoved: _cache[11] || (_cache[11] = (user) => updateMemberList(user))
                    }, null, 8, ["fields", "disabled-fields", "users", "per-page"]),
                    _createVNode(_unref(BRow), null, {
                      default: _withCtx(() => [
                        _createElementVNode("div", _hoisted_23, [
                          _createElementVNode("div", _hoisted_24, [
                            _cache[25] || (_cache[25] = _createElementVNode("div", { class: "col text-end" }, [
                              _createElementVNode("p", { class: "me-2 pt-1" }, " View ")
                            ], -1)),
                            _createElementVNode("div", _hoisted_25, [
                              _createVNode(DropDownListComponent, {
                                id: "usersFilterDropDownList",
                                "default-option": membersPerPage.value.toString(),
                                options: optionsSelector(membersPerPageOptions),
                                onSelectValue: updateUsersTable
                              }, null, 8, ["default-option", "options"])
                            ]),
                            _cache[26] || (_cache[26] = _createElementVNode("div", { class: "col-auto" }, [
                              _createElementVNode("p", { class: "ms-2 pt-1" }, " users per page ")
                            ], -1))
                          ])
                        ])
                      ]),
                      _: 1
                    })
                  ]),
                  _: 1
                }, 8, ["fn"]),
                _createVNode(AsyncTable, {
                  headline: "Offboarded Members",
                  fn: () => loadUsers()
                }, {
                  default: _withCtx(() => [
                    (deactivatedUsers.value.length === 0)
                      ? (_openBlock(), _createElementBlock("div", _hoisted_26, " There are no deactivated members in this tenant. "))
                      : _createCommentVNode("", true),
                    (deactivatedUsers.value.length > 0)
                      ? (_openBlock(), _createBlock(PaginatedMembersTable, {
                          key: 1,
                          id: "offboarded-members",
                          users: deactivatedUsers.value,
                          fields: _unref(disabledFields),
                          "disabled-fields": _unref(disabledFields),
                          toast: toast,
                          "per-page": resultsPerPage.value,
                          "table-id": tableNameOffboard
                        }, null, 8, ["users", "fields", "disabled-fields", "per-page"]))
                      : _createCommentVNode("", true)
                  ]),
                  _: 1
                }, 8, ["fn"])
              ]))
            : _createCommentVNode("", true)
        ])
      ]),
      (isApprover.value)
        ? (_openBlock(), _createBlock(_unref(BModal), {
            key: 0,
            id: "certification-info",
            title: "(Re)certification",
            "ok-only": true,
            size: "lg"
          }, {
            default: _withCtx(() => _cache[28] || (_cache[28] = [
              _createElementVNode("p", { class: "my-4" }, " The re-certification process is mandated by compliance restrictions for the MPS platform. ", -1),
              _createElementVNode("p", { class: "my-4" }, " To ensure that only members of your team have access to your accounts, access to every tenant (and every role) needs to be verified every 90 days. Both you and the user will receive email notifications when the certification period expires. ", -1),
              _createElementVNode("p", { class: "my-4" }, " Only approvers can re-certify a user. ", -1)
            ])),
            _: 1
          }))
        : _createCommentVNode("", true)
    ]),
    _: 1
  }))
}
}

})