<template>
  <div div="logged-in">
    <template v-if="error">
      There was an error logging you in. Please contact MPS.
    </template>
    <template v-else>
      <h1>You Logged In Successfully</h1>
    </template>
  </div>
</template>

<script setup lang="ts">
import Cookies from 'js-cookie';
import { onMounted, ref } from 'vue';
import { useRoute, useRouter } from 'vue-router';
import { userStore } from '@/store/modules/user';

const error = ref(false);
const route = useRoute();
const router = useRouter();
const user = userStore();

onMounted(() => {
  const id = Cookies.get('userId');

  if (id === undefined) {
    error.value = true;
    return;
  }

  const xsrfToken = Cookies.get('XSRF-Token');

  if (xsrfToken === undefined) {
    error.value = true;
    return;
  }

  user.login({ id, xsrfToken });
  if (route.query.go) {
    router.push(decodeURIComponent(route.query.go as string));
  } else {
    router.push('/profile');
  }
});

</script>
