import { defineComponent as _defineComponent } from 'vue'
import { createTextVNode as _createTextVNode, unref as _unref, withCtx as _withCtx, createVNode as _createVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, resolveDirective as _resolveDirective, withDirectives as _withDirectives, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "container" }
const _hoisted_2 = { class: "row" }
const _hoisted_3 = { class: "col-offset-2 col-12" }
const _hoisted_4 = { class: "mt-4" }
const _hoisted_5 = {
  key: 0,
  class: "alert alert-light"
}
const _hoisted_6 = ["href"]
const _hoisted_7 = ["href"]

import { useRoute } from 'vue-router';
import {
  BTable, BBreadcrumb, BBreadcrumbItem, BButton,
  TableField, BTableSortBy,
} from 'bootstrap-vue-next';
import Axios from 'axios';
import AsyncTable from '@/components/AsyncTable.vue';
import { userStore } from '@/store/modules/user';
import { FirefighterAccessRepresentation } from '../models/firefighter';
import { getKeycloakRequestConfig } from '../utils/http';


export default /*@__PURE__*/_defineComponent({
  __name: 'TenantReports',
  setup(__props) {

const user = userStore();

const firefighterFieldsOriginal: TableField<FirefighterAccessRepresentation>[] = [
  {
    key: 'fullName',
    label: 'Name',
    sortable: true,
    formatter: (_value: any, _key: string, item: any) => `${item.user.firstName} ${item.user.lastName}`,
  },
  {
    key: 'role',
    label: 'Role',
    sortable: true,
  },
  {
    key: 'grantedByFullName',
    label: 'Granted by',
    sortable: true,
    formatter: (_value: any, _key: string, item: any) => `${item.grantedBy.firstName} ${item.grantedBy.lastName}`,
  },
  {
    key: 'startAt',
    label: 'Grant Time',
    sortable: true,
  },
  {
    key: 'revokeAt',
    label: 'Revoke Time',
    sortable: true,
  },
  {
    key: 'status',
    label: 'Status',
  },
  {
    key: 'reason',
    label: 'Reason',
    class: 'text-center',
  },
  {
    key: 'details',
    label: 'Show Details',
  },
];

const sortBy: BTableSortBy[] = [{
  order: 'desc',
  key: 'startAt',
}];

const route = useRoute();

let firefighterAccesses: Array<FirefighterAccessRepresentation> = [];

async function loadFirefighterTable() {
  const response = await Axios.get<FirefighterAccessRepresentation[]>(
    `/tenants/${route.params.tenantName}/firefighters`,
    getKeycloakRequestConfig(user.xsrfToken),
  );

  firefighterAccesses = response.data.map((access) => ({
    ...access,
    startAt: new Date(access.startAt),
    revokeAt: new Date(access.revokeAt),
    canceledAt: access.canceledAt === undefined ? undefined : new Date(access.canceledAt),
  }));
}

return (_ctx: any,_cache: any) => {
  const _component_font_awesome_icon = _resolveComponent("font-awesome-icon")!
  const _directive_b_tooltip = _resolveDirective("b-tooltip")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _createVNode(_unref(BBreadcrumb), null, {
          default: _withCtx(() => [
            _createVNode(_unref(BBreadcrumbItem), { to: { name: 'myTenants' } }, {
              default: _withCtx(() => _cache[0] || (_cache[0] = [
                _createTextVNode(" My Tenants ")
              ])),
              _: 1
            }),
            _createVNode(_unref(BBreadcrumbItem), {
              to: { name: 'tenantDetails', params: { tenantName: _unref(route).params.tenantName } }
            }, {
              default: _withCtx(() => [
                _createTextVNode(_toDisplayString(_unref(route).params.tenantName), 1)
              ]),
              _: 1
            }, 8, ["to"]),
            _createVNode(_unref(BBreadcrumbItem), { active: "" }, {
              default: _withCtx(() => _cache[1] || (_cache[1] = [
                _createTextVNode(" Reports ")
              ])),
              _: 1
            })
          ]),
          _: 1
        }),
        _createElementVNode("h3", _hoisted_4, " Tenant Reports for " + _toDisplayString(_unref(route).params.tenantName), 1),
        _cache[3] || (_cache[3] = _createElementVNode("p", { class: "lead" }, " This page contains your tenant reports for example Firefighter access history. ", -1)),
        _createVNode(AsyncTable, {
          fn: () => loadFirefighterTable(),
          headline: "Firefighter Access History"
        }, {
          default: _withCtx(() => [
            (_unref(firefighterAccesses).length === 0)
              ? (_openBlock(), _createElementBlock("div", _hoisted_5, " There is no temporary production access at the moment. "))
              : _createCommentVNode("", true),
            (_unref(firefighterAccesses).length > 0)
              ? (_openBlock(), _createBlock(_unref(BTable), {
                  key: 1,
                  id: "firefighters",
                  striped: "",
                  hover: "",
                  items: _unref(firefighterAccesses),
                  fields: firefighterFieldsOriginal,
                  "sort-by": sortBy,
                  "sort-desc": "true",
                  "primary-key": "id"
                }, {
                  "cell(fullName)": _withCtx((data) => [
                    _createElementVNode("a", {
                      href: `mailto:${data.item.user.email}`
                    }, _toDisplayString(data.item.user.firstName) + " " + _toDisplayString(data.item.user.lastName), 9, _hoisted_6)
                  ]),
                  "cell(grantedByFullName)": _withCtx((data) => [
                    _createElementVNode("a", {
                      href: `mailto:${data.item.grantedBy.email}`
                    }, _toDisplayString(data.item.grantedBy.firstName) + " " + _toDisplayString(data.item.grantedBy.lastName), 9, _hoisted_7)
                  ]),
                  "cell(startAt)": _withCtx((data) => [
                    _createTextVNode(_toDisplayString(_ctx.$filters.formatDate(data.item.startAt)), 1)
                  ]),
                  "cell(revokeAt)": _withCtx((data) => [
                    _createTextVNode(_toDisplayString(_ctx.$filters.formatDate((data.item.canceledAt === undefined ? data.item.revokeAt : data.item.canceledAt ))), 1)
                  ]),
                  "cell(reason)": _withCtx((data) => [
                    _withDirectives(_createVNode(_component_font_awesome_icon, {
                      id: `firefighter_${data.item.id}`,
                      class: "ff-button-icon",
                      icon: "info-circle"
                    }, null, 8, ["id"]), [
                      [
                        _directive_b_tooltip,
                        data.item.reason,
                        void 0,
                        { html: true }
                      ]
                    ])
                  ]),
                  "cell(details)": _withCtx((data) => [
                    _createVNode(_unref(BButton), {
                      size: "sm",
                      to: {
                  name: 'firefighterAccessDetails',
                  params: { firefighterAccessId: data.item.id },
                }
                    }, {
                      default: _withCtx(() => _cache[2] || (_cache[2] = [
                        _createTextVNode(" Show Details ")
                      ])),
                      _: 2
                    }, 1032, ["to"])
                  ]),
                  _: 1
                }, 8, ["items"]))
              : _createCommentVNode("", true)
          ]),
          _: 1
        }, 8, ["fn"])
      ])
    ])
  ]))
}
}

})