<template>
  <div>
    <BButton
      :disabled="props.tenantRoles.length === 0"
      variant="outline-danger"
      @click="showModal()"
    >
      <span><font-awesome-icon
        class="cert-button-icon"
        icon="fire-alt"
      /></span>
      Request Firefighter
    </BButton>
    <BModal
      :id="modalId"
      ref="modalRef"
      :hide-header="true"
      :no-footer="true"
      size="lg"
    >
      <h3 class="mt-3 mb-4">
        Request Firefighter Access
      </h3>
      <FirefighterAccessForm
        :tenant-name="tenantName"
        :tenant-roles="tenantRoles"
        :successful-event="successfulEvent"
        :hide-modal="() => hideModal()"
      />
    </BModal>
  </div>
</template>

<script setup lang="ts">
import {
  computed, PropType, Ref, ref,
} from 'vue';
import { BModal, BButton } from 'bootstrap-vue-next';
import FirefighterAccessForm from '@/components/FirefighterAccessForm.vue';
import { TenantRole } from '@/models/tenant';
/**
 * This Component represents a button and form in modal to request firefighter
 * access on the tenant detail page.
 */

const props = defineProps({
  tenantName: {
    type: String,
    default() {
      return '';
    },
  },
  tenantRoles: {
    type: Array as PropType<TenantRole[]>,
    required: true,
  },
  successfulEvent: {
    type: Function,
    required: true,
  },
});

const modalRef = ref('') as unknown as Ref<BModal>;

const modalId = computed(() => `requestFirefighter_${props.tenantName}`);

function showModal() {
  modalRef.value.show();
}

function hideModal() {
  modalRef.value.hide();
}
</script>
