import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, createVNode as _createVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, withCtx as _withCtx, createBlock as _createBlock, createCommentVNode as _createCommentVNode, withModifiers as _withModifiers } from "vue"

const _hoisted_1 = { id: "onboarding-wizard-suggestions" }

import { ref, reactive, computed } from 'vue';
import {
  BListGroup, BButton, BForm, BFormInput, BInputGroup, BListGroupItem,
} from 'bootstrap-vue-next';
import { storeToRefs } from 'pinia';
import { watchDebounced } from '@vueuse/core';
import { fetchUsers } from '@/api/bulkPermissionManagement';
import { userStore as useUserStore } from '@/store/modules/user';
import { useBulkPermissionManagement } from '@/store/modules/useBulkPermissionManagement';
import { User } from '@/models/bulkPermissionManagement';


export default /*@__PURE__*/_defineComponent({
  __name: 'BulkSelectUsers',
  setup(__props) {

const store = useBulkPermissionManagement();
const { selectedUsers } = storeToRefs(store);

const userStore = useUserStore();

const emailInputElement = ref(null);

const input = reactive({ email: '' });

const isValidUser = ref(false);

const isLoggedInUser = ref(false);

const loadingSuggestions = ref(false);

const canAddUser = computed(() => isValidUser.value
  && !loadingSuggestions.value
  && !isLoggedInUser.value);

const suggestions = ref<string[]>([]);

const getUsers = async (email: string) => {
  if (email.length > 2) {
    const users = await fetchUsers(email);

    return users;
  }
  return [];
};

const isASelectedUser = (email: string) => selectedUsers.value
  .map((user) => user.email)
  .includes(email);

const clickAdd = async () => {
  if (!canAddUser.value) {
    return;
  }

  const allUsers = await getUsers(input.email);

  if (isASelectedUser(input.email)) {
    return;
  }

  selectedUsers.value.push(allUsers[0]);

  input.email = '';
  emailInputElement.value.focus();
};

const clickRemoveUser = (user) => {
  selectedUsers.value.splice(selectedUsers.value.indexOf(user), 1);
  input.email = '';
  emailInputElement.value.focus();
};

const loadSuggestions = (userSuggestions: User[], email: string) => {
  const suggestedUsers = userSuggestions.map((suggestion) => suggestion.email);
  const emailsOfSelectedUsers = selectedUsers.value.map((user) => user.email);

  suggestions.value = suggestedUsers.filter((e) => emailsOfSelectedUsers.indexOf(e) === -1);

  // don't show suggestions if the user the user has typed is the only suggestion
  if (suggestions.value.length === 1 && suggestions.value[0] === email) {
    suggestions.value = [];
  }
};

watchDebounced(
  input,
  async () => {
    loadingSuggestions.value = true;

    const userSuggestions = await getUsers(input.email);

    loadSuggestions(userSuggestions, input.email);

    isValidUser.value = userSuggestions.length === 1
      && input.email === userSuggestions[0].email;

    isLoggedInUser.value = userSuggestions.length === 1
      && userStore.id === userSuggestions[0].id;

    loadingSuggestions.value = false;
  },
  { debounce: 350 },
);

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", null, [
    _createVNode(_unref(BForm), {
      onSubmit: _cache[1] || (_cache[1] = _withModifiers(() => { canAddUser.value && clickAdd() }, ["prevent"]))
    }, {
      default: _withCtx(() => [
        _createVNode(_unref(BInputGroup), null, {
          default: _withCtx(() => [
            _createVNode(_unref(BFormInput), {
              id: "onboarding-wizard-select-multiple-users",
              ref_key: "emailInputElement",
              ref: emailInputElement,
              modelValue: input.email,
              "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((input.email) = $event)),
              list: "onboarding-wizard-suggestions",
              placeholder: "email address"
            }, null, 8, ["modelValue"]),
            _createElementVNode("datalist", _hoisted_1, [
              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(suggestions.value, (suggestion) => {
                return (_openBlock(), _createElementBlock("option", { key: suggestion }, _toDisplayString(suggestion), 1))
              }), 128))
            ]),
            _createVNode(_unref(BButton), {
              disabled: !canAddUser.value,
              onClick: clickAdd
            }, {
              default: _withCtx(() => [
                _createTextVNode(_toDisplayString(loadingSuggestions.value ? 'Loading...' : 'Add'), 1)
              ]),
              _: 1
            }, 8, ["disabled"])
          ]),
          _: 1
        }),
        _createVNode(_unref(BListGroup), { class: "mt-3" }, {
          default: _withCtx(() => [
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_unref(selectedUsers), (user) => {
              return (_openBlock(), _createBlock(_unref(BListGroupItem), {
                key: user.id,
                class: "d-flex justify-content-between flex-end"
              }, {
                default: _withCtx(() => [
                  _createTextVNode(_toDisplayString(user.email) + " ", 1),
                  _createVNode(_unref(BButton), {
                    pill: "",
                    size: "sm",
                    variant: "outline-danger",
                    onClick: ($event: any) => (clickRemoveUser(user))
                  }, {
                    default: _withCtx(() => _cache[2] || (_cache[2] = [
                      _createTextVNode(" Remove ")
                    ])),
                    _: 2
                  }, 1032, ["onClick"])
                ]),
                _: 2
              }, 1024))
            }), 128)),
            (_unref(selectedUsers).length === 0)
              ? (_openBlock(), _createBlock(_unref(BListGroupItem), {
                  key: 0,
                  class: "text-center"
                }, {
                  default: _withCtx(() => [
                    _cache[4] || (_cache[4] = _createElementVNode("span", { class: "my-2 m-0" }, " Type an email address to add a user to the list. ", -1)),
                    _createVNode(_unref(BButton), {
                      pill: "",
                      size: "sm",
                      variant: "outline-danger",
                      style: {"visibility":"hidden"}
                    }, {
                      default: _withCtx(() => _cache[3] || (_cache[3] = [
                        _createTextVNode(" Remove ")
                      ])),
                      _: 1
                    })
                  ]),
                  _: 1
                }))
              : _createCommentVNode("", true)
          ]),
          _: 1
        })
      ]),
      _: 1
    })
  ]))
}
}

})