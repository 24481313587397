import { defineComponent as _defineComponent } from 'vue'
import { createVNode as _createVNode, resolveComponent as _resolveComponent, resolveDirective as _resolveDirective, withDirectives as _withDirectives, createTextVNode as _createTextVNode, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, unref as _unref, withCtx as _withCtx, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "overflow-auto" }
const _hoisted_2 = { key: 0 }
const _hoisted_3 = { key: 0 }
const _hoisted_4 = { key: 1 }
const _hoisted_5 = { key: 1 }
const _hoisted_6 = ["href"]
const _hoisted_7 = { class: "d-flex justify-content-end" }

import {
  ref, computed, watch, reactive,
} from 'vue';
import { BTable, BPagination } from 'bootstrap-vue-next';
import TenantUserActions from '@/components/TenantUserActions.vue';
import EnvironmentComponent from '@/components/EnvironmentComponent.vue';
import { Permission } from '@/models/keycloak';


export default /*@__PURE__*/_defineComponent({
  __name: 'PaginatedMembersTable',
  props: {
  fields: {
    type: Array,
    required: true,
  },
  users: {
    type: Array,
    required: true,
  },
  disabledFields: {
    type: Array,
    required: true,
  },
  toast: {
    type: Function,
    required: true,
  },
  tableId: {
    type: String,
    default: 'my-table',
  },
  perPage: {
    type: Number,
    required: true,
  },
},
  emits: ['removed'],
  setup(__props, { emit: __emit }) {

const props = __props;

const emits = __emit;

const currentPage = ref(1);

const { toast } = props;

const rows = computed(() => props.users.length);

const pageSize = computed(() => {
  const paginatedItems = reactive([]);
  paginatedItems.splice(0, paginatedItems.length);
  const startIndex = (currentPage.value - 1) * props.perPage;
  const endIndex = startIndex + props.perPage;

  paginatedItems.push(...props.users.slice(startIndex, endIndex));

  return paginatedItems;
});

const hasCertificationData = (tenantMember: Permission) => {
  const { environment, role } = tenantMember;
  return environment !== 'prod' && role !== 'escalator' && role !== 'responsible';
};

const showPagination = computed(() => props.users.length > props.perPage);

watch(
  () => props.perPage,
  () => {
    currentPage.value = 1;
  },
);

/**
 * Triggered when a user has been recertified through TenantUserActions
 *
 * The certification data is updated in the local storage to avoid having
 * to make new requests for all users.
 */
function userRecertified(
  tenantMember: Permission,
  message: { title: string, message: string, certificationData: any },
) {
  toast('success', message.title, message.message);

  const storedUser = props.users.find(
    (u: Permission) => u.id === tenantMember.id && u.role === tenantMember.role,
  ) as Permission;
  storedUser.certificationData = message.certificationData;
}

/**
 * Triggered when a user was removed through TenantUserActions
*
* We locally update the "users" array to represent the change.
 */
function userRemoved(tenantMember: Permission, message: { title: string, message: string }) {
  toast('success', message.title, message.message);
  emits('removed', tenantMember);
}

/**
* Handles errors from the TenantUserAction component. We only display the error
* in a toast message.
 *
 * This will only trigger for HTTP Errors from the resulting requests of actions.
 */
const handleUserActionError = (message: { title: string, message: string }) => {
  toast('danger', message.title, message.message);
};


return (_ctx: any,_cache: any) => {
  const _component_font_awesome_icon = _resolveComponent("font-awesome-icon")!
  const _directive_b_modal = _resolveDirective("b-modal")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_unref(BTable), {
      "per-page": 0,
      "current-page": currentPage.value,
      items: pageSize.value,
      fields: props.fields,
      "primary-key": "_uniqueId",
      striped: "",
      hover: "",
      responsive: ""
    }, {
      "cell(environment)": _withCtx((data) => [
        _createVNode(EnvironmentComponent, {
          production: data.item.environment === 'prod'
        }, null, 8, ["production"])
      ]),
      "head(´)": _withCtx(() => [
        _cache[2] || (_cache[2] = _createTextVNode(" Certification expires in ")),
        _withDirectives(_createVNode(_component_font_awesome_icon, {
          class: "modal-cta",
          icon: "question-circle"
        }, null, 512), [
          [_directive_b_modal, 'certification-info']
        ])
      ]),
      "cell(certificationData)": _withCtx((data) => [
        (hasCertificationData(data.item))
          ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
              (data.item.certificationData)
                ? (_openBlock(), _createElementBlock("span", _hoisted_3, _toDisplayString(data.item.certificationData.expiresIn) + " day(s)", 1))
                : (_openBlock(), _createElementBlock("span", _hoisted_4, _cache[3] || (_cache[3] = [
                    _createElementVNode("span", { class: "badge text-bg-danger text-light" }, "not certified", -1)
                  ])))
            ]))
          : _createCommentVNode("", true),
        (!hasCertificationData(data.item))
          ? (_openBlock(), _createElementBlock("div", _hoisted_5, "   "))
          : _createCommentVNode("", true)
      ]),
      "cell(lastName)": _withCtx((data) => [
        _createElementVNode("a", {
          class: "text-decoration-none",
          href: `mailto:${data.item.email}`
        }, _toDisplayString(data.item.firstName) + " " + _toDisplayString(data.item.lastName), 9, _hoisted_6)
      ]),
      "cell(actions)": _withCtx((data) => [
        _createVNode(TenantUserActions, {
          "tenant-member": data.item,
          onRecertified: (message) => userRecertified(data.item, message),
          onRemoved: (message) => userRemoved(data.item, message),
          onError: _cache[0] || (_cache[0] = (error) => handleUserActionError(error))
        }, null, 8, ["tenant-member", "onRecertified", "onRemoved"])
      ]),
      _: 1
    }, 8, ["current-page", "items", "fields"]),
    _createElementVNode("div", _hoisted_7, [
      (showPagination.value)
        ? (_openBlock(), _createBlock(_unref(BPagination), {
            key: 0,
            modelValue: currentPage.value,
            "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((currentPage).value = $event)),
            "total-rows": rows.value,
            "per-page": props.perPage,
            "aria-controls": "my-table"
          }, null, 8, ["modelValue", "total-rows", "per-page"]))
        : _createCommentVNode("", true)
    ])
  ]))
}
}

})