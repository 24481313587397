import { defineComponent as _defineComponent } from 'vue'
import { createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, unref as _unref, withCtx as _withCtx, createVNode as _createVNode, normalizeClass as _normalizeClass, withModifiers as _withModifiers, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "container" }

import {
  ref, computed,
} from 'vue';
import {
  BForm, BCol, BRow, ValidationState, BaseColorVariant,
  BAlert,
  BFormInput, BCard, useToastController,
  BFormInvalidFeedback,
} from 'bootstrap-vue-next';
import FirefighterAccessForm from '@/components/FirefighterAccessForm.vue';
import { TenantRole } from '@/models/tenant';


export default /*@__PURE__*/_defineComponent({
  __name: 'RequestFirefighterPage',
  setup(__props) {

const { show } = useToastController();
const tenant = ref('');

async function sendRequest() {
  return null;
}

const availableRoles: TenantRole[] = [
  {
    name: 'DevOps',
    technicalName: 'devops',
    hasFirefighterRole: true,
    isSelfServiceRole: true,
  },
  {
    name: 'ReadOnly',
    technicalName: 'readonly',
    hasFirefighterRole: true,
    isSelfServiceRole: true,
  },
];

const tenantFieldValidation: ValidationState = computed(() => {
  if (tenant.value.length === 0) {
    return null;
  }

  if (tenant.value.endsWith('-')) {
    return false;
  }

  const parts = tenant.value.split('-');

  if (parts.length < 3) {
    return false;
  }

  // maximum tenant name length
  if (tenant.value.length > 27) {
    return false;
  }

  // Return `null` so that there is _no_ feedback. Reasoning: we
  // don't actually validate the tenant exists, so this might create
  // confusion.
  return null;
});

const toast = (variant: string, title: string, message: string) => {
  show?.({
    props: {
      body: message,
      variant: variant as keyof BaseColorVariant,
      title,
      value: 10000,
    },
  });
};

function handleRequestFirefighter(statusCode: number) {
  if (statusCode === 204) {
    toast(
      'success',
      'Firefighter Access requested',
      `Firefighter access requested for tenant ${tenant.value}. Please ask an Approver to check
      their mails and accept the request`,
    );
  } else {
    console.warn('unexpected status code', statusCode);
  }

  tenant.value = '';
}

function handleFirefighterError(error: Error) {
  toast('danger', 'Error requesting Firefighter Access', `There was an error requesting
  Firefighter access. Please ensure that the tenant you entered is correct and try again`);
}


return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_unref(BRow), null, {
      default: _withCtx(() => [
        _createVNode(_unref(BCol), { class: "col-offset-2" }, {
          default: _withCtx(() => [
            _cache[3] || (_cache[3] = _createElementVNode("h3", { class: "mt-4" }, " Request Firefighter Access ", -1)),
            _createVNode(_unref(BAlert), {
              "model-value": true,
              variant: "info"
            }, {
              default: _withCtx(() => _cache[2] || (_cache[2] = [
                _createTextVNode(" This page only allows requesting Firefighter access for the "),
                _createElementVNode("em", null, "DevOps", -1),
                _createTextVNode(" and "),
                _createElementVNode("em", null, "ReadOnly", -1),
                _createTextVNode(" roles. For Firefighter access to other roles, please use the \"My Tenants\" page. ")
              ])),
              _: 1
            }),
            _createVNode(_unref(BCard), null, {
              default: _withCtx(() => [
                _createVNode(_unref(BForm), {
                  onSubmit: _withModifiers(sendRequest, ["prevent"])
                }, {
                  default: _withCtx(() => [
                    _createVNode(_unref(BFormInput), {
                      id: "tenant-name",
                      modelValue: tenant.value,
                      "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((tenant).value = $event)),
                      class: _normalizeClass({
                'mb-3': tenantFieldValidation.value || tenantFieldValidation.value === null,
                'mb-1': !tenantFieldValidation.value && tenantFieldValidation.value !== null,
              }),
                      type: "text",
                      placeholder: "Tenant",
                      state: tenantFieldValidation.value,
                      required: ""
                    }, null, 8, ["modelValue", "class", "state"]),
                    _createVNode(_unref(BFormInvalidFeedback), {
                      state: tenantFieldValidation.value,
                      text: 'Please enter a valid tenant name format (vwfs-du-example, without stage)'
                    }, null, 8, ["state"]),
                    _createVNode(FirefighterAccessForm, {
                      "tenant-name": tenant.value,
                      "tenant-roles": availableRoles,
                      "validation-state": tenantFieldValidation.value === null && tenant.value.length > 0,
                      "successful-event": (status) => handleRequestFirefighter(status),
                      class: _normalizeClass({ 'mt-3': !tenantFieldValidation.value && tenantFieldValidation.value !== null }),
                      onError: _cache[1] || (_cache[1] = (err) => handleFirefighterError(err))
                    }, null, 8, ["tenant-name", "validation-state", "successful-event", "class"])
                  ]),
                  _: 1
                })
              ]),
              _: 1
            })
          ]),
          _: 1
        })
      ]),
      _: 1
    })
  ]))
}
}

})