import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, normalizeClass as _normalizeClass, createVNode as _createVNode, withCtx as _withCtx, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "form-row form-group" }
const _hoisted_2 = { class: "form-row form-group" }
const _hoisted_3 = { class: "form-row form-group" }
const _hoisted_4 = { class: "form-row form-group" }
const _hoisted_5 = {
  key: 0,
  id: "scheduledFirefighterPicker",
  class: "form-row form-group"
}
const _hoisted_6 = {
  id: "time-zone",
  class: "text-muted"
}
const _hoisted_7 = { class: "form-row form-group mb-0" }

import axios from 'axios';
import {
  computed, PropType, ref, watch, watchEffect,
} from 'vue';
import {
  BFormTextarea, BFormCheckbox, BFormInvalidFeedback,
  BInputGroup, BFormInput, BCol, BButton,
} from 'bootstrap-vue-next';
import moment from 'moment';
import AsyncButton from '@/components/AsyncButton.vue';
import DropDownListComponent from '@/components/DropDownListComponent.vue';
import { getKeycloakRequestConfig } from '@/utils/http';
import { SelectOptionsDropDown, TenantRole } from '@/models/tenant';
import Validator from '@/utils/validator';
import { userStore } from '@/store/modules/user';
import { AsyncButtonStatus } from '@/models/asyncButton';
import { useAsyncButton } from '@/composable/apiStates';
import { createDuration } from '@/composable/durationSelector';
import { useTenantRoles } from '@/composable/useTenantRoles';
import { FirefighterAccessRequestRequest } from '../models/firefighter';


export default /*@__PURE__*/_defineComponent({
  __name: 'FirefighterAccessForm',
  props: {
  tenantName: {
    type: String,
    default: '',
  },
  tenantRoles: {
    type: Array as PropType<TenantRole[]>,
    required: true,
  },
  // Optional validation state in case there are more fields in the form
  validationState: {
    type: Boolean,
    required: false,
    default: true,
  },
  successfulEvent: {
    type: Function,
    required: true,
  },
  hideModal: {
    type: Function,
    required: false,
    default: null,
  },
},
  emits: ['successfulEvent', 'error'],
  setup(__props, { emit: __emit }) {

const props = __props;

const emit = __emit;
const status = useAsyncButton();
const isGrantNow = ref(true);
const requestDuration = ref(SelectOptionsDropDown.DURATION);
const requestReason = ref(null);
const requestDate = ref(null);
const requestTime = ref('08:00');
const user = userStore();
const requestRole = ref(SelectOptionsDropDown.ROLE);
const timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone || 'UTC';
const { durationOptions } = createDuration();

const roleOptions = ref([]);

watch(
  () => props.tenantRoles,
  (newRoles) => {
    roleOptions.value = useTenantRoles(newRoles, true).roleOptions.value;
  },
  { immediate: true },
);

const updateDuration = (selectedDuration: string) => {
  requestDuration.value = selectedDuration as SelectOptionsDropDown;
};

const updateStatus = (statusCode: { status: AsyncButtonStatus, message: string }) => {
  status.status = statusCode.status;
  status.message = statusCode.message;
};

const requestStartAt = computed(() => {
  if (isGrantNow.value) {
    return null;
  }
  const parsedDate = moment(`${requestDate.value} ${requestTime.value}:00`, 'YYYY-MM-DD HH:mm:ss', true);

  return parsedDate.toISOString();
});

const resetForm = () => {
  requestRole.value = SelectOptionsDropDown.ROLE;
  requestReason.value = null;
  requestDuration.value = SelectOptionsDropDown.DURATION;
  requestDate.value = null;
  requestTime.value = '08:00';
  isGrantNow.value = true;
  props.hideModal?.();
};

async function sendRequest() {
  const firefighterAccessRequest: FirefighterAccessRequestRequest = {
    role: requestRole.value,
    reason: requestReason.value,
    startNow: isGrantNow.value,
    startAt: requestStartAt.value,
    timeZone,
    duration: Number(requestDuration.value),
  };

  try {
    const response = await axios.post(
      `firefighter/requests/${props.tenantName}/users/${user.id}`,
      firefighterAccessRequest,
      getKeycloakRequestConfig(user.xsrfToken),
    );
    if (props.successfulEvent) {
      props.successfulEvent(response.status);
      emit('successfulEvent', response.status);
      resetForm();
    }
  } catch (err) {
    if (err.response && err.response.data) {
      emit('error', err.response.data.message);
    }
  }
}

const requestDurationIsValid = computed(() => Number(requestDuration.value) > 0);
const requestReasonIsValid = computed(() => requestReason.value?.trim().length > 0);
const requestDateIsValid = computed(
  () => isGrantNow.value || Validator.isDateIsValid(requestDate.value),
);
const scheduledTimestampIsValid = computed(
  () => isGrantNow.value || Validator.isTimestampValid(requestDate.value, requestTime.value),
);
const requestTimeIsValid = computed(
  () => isGrantNow.value || Validator.isTimeIsValid(requestTime.value),
);
const requestRoleIsValid = computed(() => requestRole.value !== SelectOptionsDropDown.ROLE);

const formIsValid = computed(() => props.validationState === true
  && requestDurationIsValid.value
  && requestReasonIsValid.value
  && requestRoleIsValid.value
  && (isGrantNow.value || (
    requestDateIsValid.value && requestTimeIsValid.value && scheduledTimestampIsValid.value
  )));

watchEffect(() => {
  if (requestTime.value) {
    requestTime.value = requestTime.value.split(':').slice(0, 2).join(':');
  }
});

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", null, [
    _createElementVNode("div", _hoisted_1, [
      _createVNode(_unref(BCol), null, {
        default: _withCtx(() => [
          _createVNode(DropDownListComponent, {
            modelValue: requestRole.value,
            "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((requestRole).value = $event)),
            name: "role",
            "default-option": _unref(SelectOptionsDropDown).ROLE,
            options: roleOptions.value,
            class: _normalizeClass({ 'mb-3': requestRoleIsValid.value, 'mb-1': !requestRoleIsValid.value }),
            state: requestRoleIsValid.value
          }, null, 8, ["modelValue", "default-option", "options", "class", "state"]),
          _createVNode(_unref(BFormInvalidFeedback), {
            state: requestRoleIsValid.value,
            text: 'Please select a role.'
          }, null, 8, ["state"])
        ]),
        _: 1
      })
    ]),
    _createElementVNode("div", _hoisted_2, [
      _createVNode(_unref(BCol), { class: "mb-3" }, {
        default: _withCtx(() => [
          _createVNode(_unref(BFormTextarea), {
            modelValue: requestReason.value,
            "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((requestReason).value = $event)),
            placeholder: "Reason...",
            class: _normalizeClass({ 'mb-3': requestReasonIsValid.value, 'mb-1': !requestReasonIsValid.value }),
            state: requestReasonIsValid.value
          }, null, 8, ["modelValue", "class", "state"]),
          _createVNode(_unref(BFormInvalidFeedback), {
            state: requestReasonIsValid.value,
            text: 'Please enter a reason.'
          }, null, 8, ["state"])
        ]),
        _: 1
      })
    ]),
    _createElementVNode("div", _hoisted_3, [
      _createVNode(_unref(BCol), null, {
        default: _withCtx(() => [
          _createVNode(DropDownListComponent, {
            modelValue: requestDuration.value,
            "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((requestDuration).value = $event)),
            name: "duration",
            class: _normalizeClass({ 'mb-3': requestDurationIsValid.value, 'mb-1': !requestDurationIsValid.value }),
            "default-option": _unref(SelectOptionsDropDown).DURATION,
            options: _unref(durationOptions),
            state: requestDurationIsValid.value,
            onSelectValue: updateDuration
          }, null, 8, ["modelValue", "class", "default-option", "options", "state"]),
          _createVNode(_unref(BFormInvalidFeedback), {
            state: requestDurationIsValid.value,
            text: 'Please enter a duration.'
          }, null, 8, ["state"])
        ]),
        _: 1
      })
    ]),
    _createElementVNode("div", _hoisted_4, [
      _createVNode(_unref(BFormCheckbox), {
        modelValue: isGrantNow.value,
        "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((isGrantNow).value = $event)),
        switch: "",
        name: "isGrantNow",
        class: _normalizeClass({ 'mb-3': scheduledTimestampIsValid.value, 'mb-1': !scheduledTimestampIsValid.value })
      }, {
        default: _withCtx(() => _cache[6] || (_cache[6] = [
          _createTextVNode(" Grant now ")
        ])),
        _: 1
      }, 8, ["modelValue", "class"]),
      _createVNode(_unref(BFormInvalidFeedback), {
        state: scheduledTimestampIsValid.value,
        text: 'The chosen date and time need to be valid and in the future.'
      }, null, 8, ["state"])
    ]),
    (!isGrantNow.value)
      ? (_openBlock(), _createElementBlock("div", _hoisted_5, [
          _createVNode(_unref(BCol), null, {
            default: _withCtx(() => [
              _createElementVNode("p", _hoisted_6, " Time zone: " + _toDisplayString(_unref(timeZone)), 1)
            ]),
            _: 1
          }),
          _createVNode(_unref(BCol), null, {
            default: _withCtx(() => [
              _createVNode(_unref(BFormInput), {
                id: "datePicker",
                modelValue: requestDate.value,
                "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event: any) => ((requestDate).value = $event)),
                type: "date",
                name: "requestDate",
                class: _normalizeClass({ 'mb-3': requestDateIsValid.value, 'mb-1': !requestDateIsValid.value }),
                state: requestDateIsValid.value
              }, null, 8, ["modelValue", "class", "state"]),
              _createVNode(_unref(BFormInvalidFeedback), {
                state: requestDateIsValid.value,
                text: 'Please enter a valid date.'
              }, null, 8, ["state"])
            ]),
            _: 1
          }),
          _createVNode(_unref(BCol), null, {
            default: _withCtx(() => [
              _createVNode(_unref(BInputGroup), { class: "mb-3" }, {
                default: _withCtx(() => [
                  _createVNode(_unref(BFormInput), {
                    id: "scheduled-time",
                    modelValue: requestTime.value,
                    "onUpdate:modelValue": _cache[5] || (_cache[5] = ($event: any) => ((requestTime).value = $event)),
                    type: "text",
                    placeholder: "HH:mm",
                    class: _normalizeClass({ 'mb-3': requestTimeIsValid.value, 'mb-1': !requestTimeIsValid.value }),
                    state: requestTimeIsValid.value
                  }, null, 8, ["modelValue", "class", "state"]),
                  _createVNode(_unref(BFormInvalidFeedback), {
                    state: requestTimeIsValid.value,
                    text: 'Please enter a valid time (HH:mm)'
                  }, null, 8, ["state"])
                ]),
                _: 1
              })
            ]),
            _: 1
          })
        ]))
      : _createCommentVNode("", true),
    _createElementVNode("div", _hoisted_7, [
      _createVNode(_unref(BCol), { class: "d-flex justify-content-end" }, {
        default: _withCtx(() => [
          (props.hideModal !== null)
            ? (_openBlock(), _createBlock(_unref(BButton), {
                key: 0,
                id: "close-ff-request-modal",
                class: "mt-2 mx-2",
                secondary: "",
                onClick: resetForm
              }, {
                default: _withCtx(() => _cache[7] || (_cache[7] = [
                  _createTextVNode(" Cancel ")
                ])),
                _: 1
              }))
            : _createCommentVNode("", true),
          _createVNode(AsyncButton, {
            id: "requestFireFighterBtn",
            class: "mt-2 text-light",
            name: `Request Firefighter Access`,
            "initial-icon": "fire-alt",
            disabled: !formIsValid.value,
            fn: sendRequest,
            size: "md",
            variant: "danger",
            onStatus: updateStatus
          }, null, 8, ["disabled"])
        ]),
        _: 1
      })
    ])
  ]))
}
}

})