import { defineComponent as _defineComponent } from 'vue'
import { createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = ["id"]


export default /*@__PURE__*/_defineComponent({
  __name: 'AccountDetailComponent',
  props: {
  accountAlias: {
    type: String,
    required: true,
  },
  accountId: {
    type: String,
    required: true,
  },
  vpcCidr: {
    type: String,
    required: true,
  },
  vpcAZs: {
    type: Number,
    required: true,
  },
  mpsConnectEnabled: Boolean,
  mpsConnectCidr: {
    type: String,
    required: true,
  },
  idx: {
    type: Number,
    required: true,
  },
},
  setup(__props) {



return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", {
    id: __props.idx.toString,
    class: "bg-light p-4 mb-4"
  }, [
    _createElementVNode("div", null, [
      _createElementVNode("p", null, [
        _cache[0] || (_cache[0] = _createElementVNode("b", null, "Account Alias:", -1)),
        _cache[1] || (_cache[1] = _createElementVNode("br", null, null, -1)),
        _createTextVNode(" " + _toDisplayString(__props.accountAlias), 1)
      ]),
      _createElementVNode("p", null, [
        _cache[2] || (_cache[2] = _createElementVNode("b", null, "Account ID:", -1)),
        _cache[3] || (_cache[3] = _createElementVNode("br", null, null, -1)),
        _createTextVNode(" " + _toDisplayString(__props.accountId), 1)
      ]),
      _createElementVNode("p", null, [
        _cache[4] || (_cache[4] = _createElementVNode("b", null, "VPC CIDR:", -1)),
        _cache[5] || (_cache[5] = _createElementVNode("br", null, null, -1)),
        _createTextVNode(" " + _toDisplayString(__props.vpcCidr), 1)
      ]),
      _createElementVNode("p", null, [
        _cache[6] || (_cache[6] = _createElementVNode("b", null, "Number of VPC AZs:", -1)),
        _cache[7] || (_cache[7] = _createElementVNode("br", null, null, -1)),
        _createTextVNode(" " + _toDisplayString(__props.vpcAZs), 1)
      ]),
      _createElementVNode("p", null, [
        _cache[8] || (_cache[8] = _createElementVNode("b", null, "MPS Connect CIDR:", -1)),
        _cache[9] || (_cache[9] = _createElementVNode("br", null, null, -1)),
        _createTextVNode(" " + _toDisplayString(__props.mpsConnectEnabled ? __props.mpsConnectCidr : "Not activated"), 1)
      ])
    ])
  ], 8, _hoisted_1))
}
}

})