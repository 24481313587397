import { defineComponent as _defineComponent } from 'vue'
import { createTextVNode as _createTextVNode, unref as _unref, withCtx as _withCtx, createVNode as _createVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createBlock as _createBlock, isRef as _isRef, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = {
  id: "onboarding-wizard-select-tenant-role",
  class: "mt-5"
}

import { storeToRefs } from 'pinia';
import { reactive } from 'vue';
import {
  BTh, BFormCheckbox, BTr, BTd, BTableSimple, BThead, BTbody,
} from 'bootstrap-vue-next';
import { Role } from '@/models/bulkPermissionManagement';
import { useBulkPermissionManagement } from '@/store/modules/useBulkPermissionManagement';
import { useTenants } from '@/store/modules/useTenants';


export default /*@__PURE__*/_defineComponent({
  __name: 'BulkSelectTenantRoles',
  setup(__props) {

const store = useBulkPermissionManagement();
const { selectedTenantRoles } = storeToRefs(store);

const tenantStore = useTenants();
const { tenants, roles, tenantRoles } = storeToRefs(tenantStore);

const allSelected = (role: Role) => {
  const tenantRolesToSelect = tenantRoles.value.filter((a) => a.role === role).map(reactive);
  return tenantRolesToSelect.every((a) => selectedTenantRoles.value.includes(a));
};

function toggleRole(role: Role) {
  const tenantRolesToSelect = tenantRoles.value.filter((a) => a.role === role).map(reactive);

  if (allSelected(role)) {
    selectedTenantRoles.value = [
      ...selectedTenantRoles.value.filter((a) => !tenantRolesToSelect.includes(a)),
    ];
  } else {
    selectedTenantRoles.value = [
      ...new Set([...selectedTenantRoles.value, ...tenantRolesToSelect])];
  }
}

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_unref(BTableSimple), {
      responsive: "",
      small: "",
      striped: "",
      bordered: ""
    }, {
      default: _withCtx(() => [
        _createVNode(_unref(BThead), null, {
          default: _withCtx(() => [
            _createVNode(_unref(BTr), null, {
              default: _withCtx(() => [
                _createVNode(_unref(BTh), {
                  "sticky-column": "",
                  "is-row-header": "",
                  style: {"width":"240px","max-width":"240px"}
                }, {
                  default: _withCtx(() => _cache[1] || (_cache[1] = [
                    _createTextVNode("   ")
                  ])),
                  _: 1
                }),
                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_unref(roles), (role) => {
                  return (_openBlock(), _createBlock(_unref(BTh), {
                    key: role.name,
                    style: {"width":"120px","word-wrap":"break-word","max-width":"120px","text-align":"center","word-break":"break-all"}
                  }, {
                    default: _withCtx(() => [
                      _createTextVNode(_toDisplayString(role.name) + " ", 1),
                      _createVNode(_unref(BFormCheckbox), {
                        checked: allSelected(role),
                        onChange: ($event: any) => (toggleRole(role))
                      }, null, 8, ["checked", "onChange"])
                    ]),
                    _: 2
                  }, 1024))
                }), 128))
              ]),
              _: 1
            })
          ]),
          _: 1
        }),
        _createVNode(_unref(BTbody), null, {
          default: _withCtx(() => [
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_unref(tenants), (tenant) => {
              return (_openBlock(), _createBlock(_unref(BTr), {
                key: tenant.name
              }, {
                default: _withCtx(() => [
                  _createVNode(_unref(BTh), { "sticky-column": "" }, {
                    default: _withCtx(() => [
                      _createTextVNode(_toDisplayString(tenant.name), 1)
                    ]),
                    _: 2
                  }, 1024),
                  (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_unref(roles), (role) => {
                    return (_openBlock(), _createBlock(_unref(BTd), {
                      key: role.name,
                      style: {"text-align":"center"}
                    }, {
                      default: _withCtx(() => [
                        (_unref(tenantRoles).some((a) => a.role === role && a.tenant === tenant))
                          ? (_openBlock(), _createBlock(_unref(BFormCheckbox), {
                              key: 0,
                              modelValue: _unref(selectedTenantRoles),
                              "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => (_isRef(selectedTenantRoles) ? (selectedTenantRoles).value = $event : null)),
                              value: reactive(_unref(tenantRoles).find((a) => a.role === role && a.tenant === tenant))
                            }, null, 8, ["modelValue", "value"]))
                          : _createCommentVNode("", true)
                      ]),
                      _: 2
                    }, 1024))
                  }), 128))
                ]),
                _: 2
              }, 1024))
            }), 128))
          ]),
          _: 1
        })
      ]),
      _: 1
    })
  ]))
}
}

})