import { defineComponent as _defineComponent } from 'vue'
import { createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, createTextVNode as _createTextVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, unref as _unref, withCtx as _withCtx } from "vue"

const _hoisted_1 = { class: "table-responsive" }
const _hoisted_2 = { class: "table table-striped" }
const _hoisted_3 = { class: "" }
const _hoisted_4 = {
  "aria-colindex": "1",
  style: {"width":"100px"}
}
const _hoisted_5 = {
  "aria-colindex": "1",
  style: {"width":"100px"}
}
const _hoisted_6 = {
  "aria-colindex": "1",
  style: {"width":"100px"}
}
const _hoisted_7 = {
  "aria-colindex": "1",
  style: {"width":"100px"}
}
const _hoisted_8 = {
  "aria-colindex": "1",
  style: {"width":"100px"}
}
const _hoisted_9 = {
  "aria-colindex": "1",
  style: {"width":"100px"}
}
const _hoisted_10 = { key: 0 }
const _hoisted_11 = {
  key: 1,
  class: "alert alert-light"
}

import axios from 'axios';
import {
  reactive, onMounted,
} from 'vue';
import {
  BTable, BContainer, BRow, BCol,
  BTableSortBy,
} from 'bootstrap-vue-next';
import Bubblewrap from '@/components/BubblewrapComponent.vue';
import EnvironmentComponent from '@/components/EnvironmentComponent.vue';
import { BubblewrapInfo } from '@/models/bubblewrap';
import { Permission } from '@/models/keycloak';
import { getKeycloakRequestConfig } from '@/utils/http';
import { userStore } from '@/store/modules/user';


export default /*@__PURE__*/_defineComponent({
  __name: 'MyPermissions',
  setup(__props) {

const permissions: Permission[] = reactive([]);
const user = userStore();
const fields = [
  {
    key: 'group',
    label: 'Group name',
    sortable: true,
    class: 'align-middle',
  },
  {
    key: 'environment',
    label: 'Environment',
    sortable: true,
    class: 'align-middle',
  },
  {
    key: 'role',
    label: 'Role',
    sortable: true,
    class: 'align-middle',
  },
  {
    key: 'certificationData',
    label: 'Certification expires in',
    sortable: true,
    class: 'align-middle',
  },
];

const tableSortBy: BTableSortBy[] = [{ key: 'group', order: 'asc' }];

const apiCall: BubblewrapInfo = reactive({
  error: null,
  loaded: false,
});

async function fetchPermissions(): Promise<Permission[]> {
  return axios
    .get<Permission[]>(
      `/users/${user.id}/permissions`,
      getKeycloakRequestConfig(user.xsrfToken),
    )
    .then((response) => response.data as Permission[]);
}

onMounted(async () => {
  try {
    const permissionsData = await fetchPermissions();
    Object.assign(permissions, permissionsData);

    apiCall.loaded = true;
  } catch (e) {
    apiCall.error = e;
  }
});


return (_ctx: any,_cache: any) => {
  const _component_font_awesome_icon = _resolveComponent("font-awesome-icon")!
  const _component_ContentLoader = _resolveComponent("ContentLoader")!

  return (_openBlock(), _createBlock(_unref(BContainer), { id: "profile" }, {
    default: _withCtx(() => [
      _createVNode(_unref(BRow), null, {
        default: _withCtx(() => [
          _createVNode(_unref(BCol), null, {
            default: _withCtx(() => [
              _cache[4] || (_cache[4] = _createElementVNode("h3", { class: "mt-4" }, " My Permissions ", -1)),
              _cache[5] || (_cache[5] = _createElementVNode("p", { class: "lead" }, " You can see a list of the permissions assigned to you below. ", -1)),
              _createVNode(Bubblewrap, { info: apiCall }, {
                placeholder: _withCtx(() => [
                  _createElementVNode("div", _hoisted_1, [
                    _createElementVNode("table", _hoisted_2, [
                      _createElementVNode("thead", null, [
                        _createElementVNode("tr", null, [
                          _cache[1] || (_cache[1] = _createElementVNode("th", null, "Group name", -1)),
                          _cache[2] || (_cache[2] = _createElementVNode("th", null, "Environment", -1)),
                          _cache[3] || (_cache[3] = _createElementVNode("th", null, "Role", -1)),
                          _createElementVNode("th", null, [
                            _cache[0] || (_cache[0] = _createTextVNode(" Certification expires in ")),
                            _createVNode(_component_font_awesome_icon, { icon: "question-circle" })
                          ])
                        ])
                      ]),
                      _createElementVNode("tbody", _hoisted_3, [
                        (_openBlock(), _createElementBlock(_Fragment, null, _renderList(3, (n) => {
                          return _createElementVNode("tr", {
                            key: n,
                            class: ""
                          }, [
                            _createElementVNode("td", _hoisted_4, [
                              _createVNode(_component_ContentLoader, {
                                height: "2px",
                                width: "100px"
                              })
                            ]),
                            _createElementVNode("td", _hoisted_5, [
                              _createVNode(_component_ContentLoader, {
                                height: "2px",
                                width: "100px"
                              })
                            ]),
                            _createElementVNode("td", _hoisted_6, [
                              _createVNode(_component_ContentLoader, {
                                height: "2px",
                                width: "100px"
                              })
                            ]),
                            _createElementVNode("td", _hoisted_7, [
                              _createVNode(_component_ContentLoader, {
                                height: "2px",
                                width: "100px"
                              })
                            ]),
                            _createElementVNode("td", _hoisted_8, [
                              _createVNode(_component_ContentLoader, {
                                height: "2px",
                                width: "100px"
                              })
                            ]),
                            _createElementVNode("td", _hoisted_9, [
                              _createVNode(_component_ContentLoader, {
                                height: "2px",
                                width: "100px"
                              })
                            ])
                          ])
                        }), 64))
                      ])
                    ])
                  ])
                ]),
                loaded: _withCtx(() => [
                  (permissions.length > 0)
                    ? (_openBlock(), _createBlock(_unref(BTable), {
                        key: 0,
                        id: "permissions",
                        striped: "",
                        hover: "",
                        responsive: "",
                        items: permissions,
                        fields: fields,
                        "sort-by": tableSortBy,
                        "sort-null-last": ""
                      }, {
                        "cell(environment)": _withCtx((data) => [
                          (data.item.role)
                            ? (_openBlock(), _createBlock(EnvironmentComponent, {
                                key: 0,
                                production: data.item.environment === 'prod'
                              }, null, 8, ["production"]))
                            : _createCommentVNode("", true)
                        ]),
                        "cell(certificationData)": _withCtx((data) => [
                          _createElementVNode("div", null, [
                            (data.item.certificationData && data.item.certificationData.expiresIn)
                              ? (_openBlock(), _createElementBlock("span", _hoisted_10, _toDisplayString(data.item.certificationData.expiresIn) + " day(s) ", 1))
                              : _createCommentVNode("", true)
                          ])
                        ]),
                        _: 1
                      }, 8, ["items"]))
                    : _createCommentVNode("", true),
                  (permissions.length === 0)
                    ? (_openBlock(), _createElementBlock("div", _hoisted_11, " You don't have any permissions. "))
                    : _createCommentVNode("", true)
                ]),
                _: 1
              }, 8, ["info"])
            ]),
            _: 1
          })
        ]),
        _: 1
      })
    ]),
    _: 1
  }))
}
}

})