import { defineComponent as _defineComponent } from 'vue'
import { openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "badge text-bg-danger text-light"
}
const _hoisted_2 = {
  key: 1,
  class: "badge text-reset"
}


export default /*@__PURE__*/_defineComponent({
  __name: 'EnvironmentComponent',
  props: {
  production: {
    type: Boolean,
  },
},
  setup(__props) {



return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("span", null, [
    (__props.production)
      ? (_openBlock(), _createElementBlock("span", _hoisted_1, "production"))
      : (_openBlock(), _createElementBlock("span", _hoisted_2, "non-production"))
  ]))
}
}

})