import { defineComponent as _defineComponent } from 'vue'
import { toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, renderSlot as _renderSlot, unref as _unref, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "toggle-header" }
const _hoisted_2 = ["aria-expanded", "aria-controls"]

import { ref, watch } from 'vue';
import { BCollapse } from 'bootstrap-vue-next';


export default /*@__PURE__*/_defineComponent({
  __name: 'CollapsibleComponent',
  props: {
  isHeader: {
    type: Boolean,
  },
  header: {
    type: String,
    required: true,
  },
  toggleId: {
    type: String,
    required: true,
  },
  isVisible: {
    type: Boolean,
    default: false,
  },
},
  emits: ['update:isVisible'],
  setup(__props, { emit: __emit }) {

const props = __props;

const emit = __emit;

const isVisible = ref(props.isVisible);

const toggleVisibility = () => {
  isVisible.value = !isVisible.value;
  emit('update:isVisible', isVisible.value);
};

// Sync prop change to internal state
watch(() => props.isVisible, (newVal) => {
  isVisible.value = newVal;
});

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", null, [
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("button", {
        type: "button",
        class: "toggle-button",
        "aria-expanded": isVisible.value,
        "aria-controls": __props.toggleId,
        onClick: toggleVisibility
      }, [
        _createElementVNode("span", {
          class: _normalizeClass({ h5: __props.isHeader })
        }, _toDisplayString(__props.header), 3),
        _cache[0] || (_cache[0] = _createElementVNode("span", { class: "toggle-icon" }, null, -1))
      ], 8, _hoisted_2)
    ]),
    _createVNode(_unref(BCollapse), {
      id: __props.toggleId,
      class: "p-4",
      visible: isVisible.value,
      "aria-labelledby": __props.toggleId
    }, {
      default: _withCtx(() => [
        _renderSlot(_ctx.$slots, "default")
      ]),
      _: 3
    }, 8, ["id", "visible", "aria-labelledby"])
  ]))
}
}

})