<template>
  <div class="container">
    <BBreadcrumb>
      <BBreadcrumbItem :to="{ name: 'myTenants' }">
        My Tenants
      </BBreadcrumbItem>
      <BBreadcrumbItem
        :to="{
          name: 'tenantDetails',
          params:
            { tenantName: apiCall.loaded ? report.tenant : 'na' },
        }"
      >
        <template v-if="report">
          {{ report.tenant }}
        </template>
        <template v-else>
          ...
        </template>
      </BBreadcrumbItem>
      <BBreadcrumbItem
        :to="{
          name: 'tenantReports',
          params:
            { tenantName: apiCall.loaded ? report.tenant : 'na' },
        }"
      >
        Reports
      </BBreadcrumbItem>
      <BBreadcrumbItem active>
        Report Details
      </BBreadcrumbItem>
    </BBreadcrumb>

    <h3 class="mt-4">
      Firefighter Access Details
    </h3>
    <div
      class="card bg-white p-3 shadow-sm mb-3"
      style="margin-top: 20px"
    >
      <Bubblewrap
        id="report"
        :info="apiCall"
      >
        <template #loaded>
          <div class="row">
            <div class="col-6">
              <div class="row">
                <div class="col-2 mt-2 text-center">
                  <font-awesome-icon
                    icon="fire-alt"
                    size="2x"
                  />
                </div>
                <div class="col-10">
                  <dl>
                    <dt>Username</dt>
                    <dd>{{ report.user.firstName }} {{ report.user.lastName }}</dd>
                    <dt>Start at</dt>
                    <dd>{{ $filters.formatDate(report.startAt) }}</dd>
                    <dt>Revoke at</dt>
                    <dd>{{ $filters.formatDate(report.revokeAt) }}</dd>
                    <dt>Status</dt>
                    <dd>{{ humanReadableStatus }}</dd>
                    <dt>Reason</dt>
                    <dd>{{ report.reason }}</dd>
                  </dl>
                </div>
              </div>
            </div>
            <div class="col-6">
              <div class="row">
                <div class="col-2 mt-2 text-center">
                  <font-awesome-icon
                    icon="user-check"
                    size="2x"
                  />
                </div>
                <div class="col-10">
                  <dl>
                    <dt>Granted by</dt>
                    <dd>{{ report.grantedBy.firstName }} {{ report.grantedBy.lastName }}</dd>
                    <dt>Technical Id</dt>
                    <dd>{{ firefighterAccessId }}</dd>
                  </dl>
                </div>
              </div>
            </div>
          </div>
        </template>
        <template
          #placeholder
        >
          <div
            class="row ContentLoader"
          >
            <div class="col-6">
              <div class="row">
                <div class="col-2 mt-2 text-center">
                  <font-awesome-icon
                    icon="fire-alt"
                    size="2x"
                  />
                </div>
                <div class="col-10">
                  <dl>
                    <dt>Username</dt>
                    <dd>
                      <ContentLoader />
                    </dd>
                    <dt>Start at</dt>
                    <dd>
                      <ContentLoader />
                    </dd>
                    <dt>Revoke at</dt>
                    <dd>
                      <ContentLoader />
                    </dd>
                    <dt>Status</dt>
                    <dd>
                      <ContentLoader />
                    </dd>
                    <dt>Reason</dt>
                    <dd>
                      <ContentLoader />
                    </dd>
                  </dl>
                </div>
              </div>
            </div>
            <div class="col-4">
              <div class="row">
                <div class="col-2 mt-2 text-center">
                  <font-awesome-icon
                    icon="user-check"
                    size="2x"
                  />
                </div>
                <div class="col-10">
                  <dl>
                    <dt>Granted by</dt>
                    <dd>
                      <ContentLoader />
                    </dd>
                    <dt>Technical Id</dt>
                    <dd>
                      <ContentLoader />
                    </dd>
                  </dl>
                </div>
              </div>
            </div>
          </div>
        </template>
      </Bubblewrap>
    </div>

    <FirefighterSensitiveActions
      :status="reportStatus"
      :firefighter-access-id="firefighterAccessId"
    />

    <div class="pt-0 pb-28">
      <b-card
        class="mt-4 mb-2"
        header="After Action Report"
        data-toggle="collapse"
      >
        <b-card-text>
          You can download a report containing all performed actions by this user on the production
          account here. The report is currently in JSON format and lists all CloudTrail actions.
          <a href="">Click here to learn more about the CloudTrail Event structure.</a>
          <br>
          The report will be available approximately eight hours after the firefighter access was
          revoked. All tenant approvers and the firefighter user will receive an Email once
          the report is ready.
        </b-card-text>

        <div
          v-if="hasDownloadError"
          class="alert alert-danger"
          role="alert"
        >
          Could not download firefighter report: {{ status.message }}
        </div>

        <AsyncButton
          id="downloadReport"
          name="Download Report"
          initial-icon="download"
          :disabled="!report || report.status !== 'finished'"
          :fn="() => downloadReport()"
          @status="updateStatus"
        />
      </b-card>
    </div>
  </div>
</template>

<script setup lang="ts">

import {
  computed, onBeforeMount, ref, watch,
  ComputedRef,
} from 'vue';
import {
  BBreadcrumb, BBreadcrumbItem, BCard, BCardText,
} from 'bootstrap-vue-next';
import axios from 'axios';
import { useRoute } from 'vue-router';
import AsyncButton from '@/components/AsyncButton.vue';
import Bubblewrap from '@/components/BubblewrapComponent.vue';
import { getKeycloakRequestConfig } from '@/utils/http';
import {
  FirefighterAccessRepresentation,
  FirefighterStatus,
} from '@/models/firefighter';
import { AsyncButtonStatus } from '@/models/asyncButton';
import { userStore } from '@/store/modules/user';
import { useApiCall, useAsyncButton } from '@/composable/apiStates';
import FirefighterSensitiveActions from './FirefighterSensitiveActions.vue';

const route = useRoute();
const report = ref(null);

let reportStatus: string = '';

const user = userStore();
const status = useAsyncButton();
const apiCall = useApiCall();

const firefighterAccessId = ref(route.params.firefighterAccessId.toString());

watch(() => route.params, () => {
  firefighterAccessId.value = route.params.firefighterAccessId.toString();
});

const hasDownloadError = computed(() => status.status === AsyncButtonStatus.ERROR);

const humanReadableStatus: ComputedRef<string> = computed(() => ({
  [FirefighterStatus.PENDING]: 'pending',
  [FirefighterStatus.ACTIVE]: 'active',
  [FirefighterStatus.WAITING_FOR_LOGS]: 'waiting for logs',
  [FirefighterStatus.GATHERING_LOGS]: 'gathering logs',
  [FirefighterStatus.FINISHED]: 'finished',
}[report.value.status] || 'n/a'));

function updateStatus(updatedStatus: AsyncButtonStatus, message: string) {
  status.status = updatedStatus;
  status.message = message;
}

async function downloadReport() {
  const response = await axios
    .get(
      `/firefighter/accesses/${firefighterAccessId.value}/report`,
      getKeycloakRequestConfig(user.xsrfToken),
    );
  window.open(response.data.url, '_blank');
}

async function fetchReport(): Promise<FirefighterAccessRepresentation> {
  const response = await axios
    .get<FirefighterAccessRepresentation>(
      `/firefighter/accesses/${firefighterAccessId.value}`,
      getKeycloakRequestConfig(user.xsrfToken),
    );
  return response.data as FirefighterAccessRepresentation;
}

onBeforeMount(async () => {
  try {
    report.value = await fetchReport();

    reportStatus = report.value.status;
    report.value.startAt = new Date(report.value.startAt);
    report.value.revokeAt = new Date(report.value.revokeAt);
    apiCall.loaded = true;
  } catch (e) {
    apiCall.error = new Error('Could not load firefighter access details.');
  }
});

</script>

<style lang="scss" scoped>

</style>
