import { defineComponent as _defineComponent } from 'vue'
import { openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

import { useRouter } from 'vue-router';
import { userStore } from '@/store/modules/user';


export default /*@__PURE__*/_defineComponent({
  __name: 'LogoutComponent',
  setup(__props) {


const router = useRouter();
const user = userStore();
function logout() {
  window.sessionStorage.removeItem('IDTOKEN');
  user.logout();
  router.push({ path: '/' });
}


return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("button", {
    id: "logout",
    class: "btn btn-outline-secondary my-2 my-sm-0",
    type: "button",
    onClick: _cache[0] || (_cache[0] = ($event: any) => (logout()))
  }, " Logout "))
}
}

})