import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, resolveComponent as _resolveComponent, createVNode as _createVNode, createTextVNode as _createTextVNode, vShow as _vShow, withCtx as _withCtx, withDirectives as _withDirectives, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, resolveDynamicComponent as _resolveDynamicComponent, createBlock as _createBlock } from "vue"

const _hoisted_1 = {
  id: "onboarding-wizard",
  class: "container"
}
const _hoisted_2 = { class: "mt-4" }
const _hoisted_3 = ["href"]

import BulkIntroduction from '@/components/BulkIntroduction.vue';
import { storeToRefs } from 'pinia';
import { onBeforeMount } from 'vue';
import {
  BContainer, BCard, BButton, BButtonGroup, BButtonToolbar,
  BOverlay, BCol, BRow, BAlert,
} from 'bootstrap-vue-next';
import BulkRun from '@/components/BulkRun.vue';
import BulkReview from '@/components/BulkReview.vue';
import BulkSelectUsers from '@/components/BulkSelectUsers.vue';
import BulkSelectTenantRoles from '@/components/BulkSelectTenantRoles.vue';
import { useTenants } from '@/store/modules/useTenants';
import { useBulkPermissionWizard } from '@/store/modules/useBulkPermissionWizard';
import { useBulkPermissionAssignment } from '@/store/modules/useBulkPermissionAssignment';
import { useBulkPermissionManagement } from '@/store/modules/useBulkPermissionManagement';

const cheapEmailObfuscation = 'nagement@vwfs.io?subject=MPS Portal Feedback: Bulk Permission Management&body=Hi MPS Team, I have some feedback for you:';


export default /*@__PURE__*/_defineComponent({
  __name: 'BulkPermissionManagement',
  setup(__props) {


const store = useBulkPermissionWizard();
const {
  step, skipIntro, done, isValid,
} = storeToRefs(store);

const tenantStore = useTenants();

const assignmentStore = useBulkPermissionAssignment();

const permissionStore = useBulkPermissionManagement();

const next = () => {
  step.value += 1;
};

const back = () => {
  step.value -= 1;
};

const startExecution = () => {
  next();
  assignmentStore.assignPermissions();
};

const steps = [
  {
    name: 'How does it work?',
    description: '',
    component: BulkIntroduction,
  },
  {
    name: 'Select users',
    description: 'Choose all users you want to onboard to the same roles. You can select all users with an active bifrost account.',
    component: BulkSelectUsers,
  },
  {
    name: 'Select roles',
    description: 'On this page you see all tenants that you can manage. Select roles you want to assign to the users from the previous step.',
    component: BulkSelectTenantRoles,
  },
  {
    name: 'Review',
    description: 'Check all permissions below. If you are happy with the result, click on "Assign permissions" to start the onboarding process.',
    component: BulkReview,
  },
  {
    name: 'Execution',
    description: 'The onboarding process has started. You can see the progress below. Don\'t close this page until the process is finished.',
    component: BulkRun,
  },
];

const reset = async () => {
  store.$reset();
  assignmentStore.$reset();
  permissionStore.$reset();

  step.value = skipIntro.value ? 1 : 0;

  await tenantStore.load();
};

onBeforeMount(() => {
  reset();
});

return (_ctx: any,_cache: any) => {
  const _component_font_awesome_icon = _resolveComponent("font-awesome-icon")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_unref(BRow), null, {
      default: _withCtx(() => [
        _createVNode(_unref(BCol), { class: "col-offset-2" }, {
          default: _withCtx(() => [
            _createElementVNode("h3", _hoisted_2, [
              _cache[3] || (_cache[3] = _createTextVNode(" Multi-tenant onboarding ")),
              _withDirectives(_createVNode(_unref(BButton), {
                icon: true,
                pill: "",
                size: "sm",
                class: "mb-0",
                variant: "outline-primary",
                style: {"margin-top":"-2px"},
                onClick: _cache[0] || (_cache[0] = ($event: any) => (step.value = 0))
              }, {
                default: _withCtx(() => [
                  _cache[1] || (_cache[1] = _createTextVNode(" Help ")),
                  _createVNode(_component_font_awesome_icon, { icon: "question-circle" })
                ]),
                _: 1
              }, 512), [
                [_vShow, _unref(step) > 0 && _unref(step) < steps.length - 1]
              ]),
              _withDirectives(_createElementVNode("a", {
                href: `mailto:mps-product-ma${cheapEmailObfuscation}`,
                icon: false,
                class: "btn btn-outline-primary btn-sm rounded-pill mb-0 ms-1",
                variant: "outline-primary",
                style: {"margin-top":"-2px"}
              }, [
                _cache[2] || (_cache[2] = _createTextVNode(" Share your feedback with us! ")),
                _createVNode(_component_font_awesome_icon, { icon: "comment" })
              ], 8, _hoisted_3), [
                [_vShow, _unref(step) > 2]
              ])
            ]),
            _cache[9] || (_cache[9] = _createElementVNode("p", { class: "lead" }, " Simultaneously onboard multiple users across the tenants you manage ", -1)),
            _createVNode(_unref(BAlert), {
              variant: "warning",
              "model-value": true
            }, {
              default: _withCtx(() => _cache[4] || (_cache[4] = [
                _createElementVNode("div", null, [
                  _createElementVNode("strong", null, "Important Information")
                ], -1),
                _createTextVNode(" There is currently a known issue where user certification might be missing when assigning the same user to many tenants. Please check the user certification in the onboarded tenants after the process is finished while we are working on a fix. ")
              ])),
              _: 1
            }),
            (_openBlock(), _createElementBlock(_Fragment, null, _renderList(steps, (s, index) => {
              return _withDirectives(_createVNode(_unref(BOverlay), {
                key: s.name,
                show: _unref(tenantStore).loading && _unref(step) !== 0
              }, {
                default: _withCtx(() => [
                  _createVNode(_unref(BCard), { class: "mt-2 mb-2" }, {
                    default: _withCtx(() => [
                      _createVNode(_unref(BContainer), null, {
                        default: _withCtx(() => [
                          _createElementVNode("h4", null, _toDisplayString(s.name), 1),
                          _createElementVNode("p", null, _toDisplayString(s.description), 1),
                          (_openBlock(), _createBlock(_resolveDynamicComponent(s.component)))
                        ]),
                        _: 2
                      }, 1024)
                    ]),
                    _: 2
                  }, 1024)
                ]),
                _: 2
              }, 1032, ["show"]), [
                [_vShow, _unref(step) === index]
              ])
            }), 64)),
            _createVNode(_unref(BButtonToolbar), {
              justify: "",
              class: "mt-1 mb-2"
            }, {
              default: _withCtx(() => [
                _createVNode(_unref(BButtonGroup), { class: "mx-0" }, {
                  default: _withCtx(() => [
                    _withDirectives(_createVNode(_unref(BButton), { onClick: back }, {
                      default: _withCtx(() => _cache[5] || (_cache[5] = [
                        _createTextVNode(" Back ")
                      ])),
                      _: 1
                    }, 512), [
                      [_vShow, _unref(step) > 1 && _unref(step) < steps.length - 1]
                    ])
                  ]),
                  _: 1
                }),
                _withDirectives(_createVNode(_unref(BButtonGroup), { class: "mx-0" }, {
                  default: _withCtx(() => [
                    _createVNode(_unref(BButton), {
                      variant: "primary",
                      onClick: next
                    }, {
                      default: _withCtx(() => [
                        _cache[6] || (_cache[6] = _createTextVNode(" Next ")),
                        _withDirectives(_createElementVNode("span", { class: "badge" }, _toDisplayString(_unref(step)) + "/" + _toDisplayString(steps.length - 2), 513), [
                          [_vShow, _unref(step) >= 1]
                        ])
                      ]),
                      _: 1
                    })
                  ]),
                  _: 1
                }, 512), [
                  [_vShow, _unref(step) < steps.length - 2]
                ]),
                _withDirectives(_createVNode(_unref(BButtonGroup), { class: "mx-0" }, {
                  default: _withCtx(() => [
                    _createVNode(_unref(BButton), {
                      variant: "danger",
                      disabled: !_unref(isValid),
                      onClick: startExecution
                    }, {
                      default: _withCtx(() => [
                        _cache[7] || (_cache[7] = _createTextVNode(" Assign permissions ")),
                        _withDirectives(_createElementVNode("span", { class: "badge text-bg-secondary" }, _toDisplayString(_unref(step)) + "/" + _toDisplayString(steps.length - 2), 513), [
                          [_vShow, _unref(step) >= 1]
                        ])
                      ]),
                      _: 1
                    }, 8, ["disabled"])
                  ]),
                  _: 1
                }, 512), [
                  [_vShow, _unref(step) === steps.length - 2]
                ]),
                _withDirectives(_createVNode(_unref(BButtonGroup), { class: "mx-0" }, {
                  default: _withCtx(() => [
                    _createVNode(_unref(BButton), {
                      variant: "primary",
                      disabled: !_unref(done),
                      onClick: reset
                    }, {
                      default: _withCtx(() => _cache[8] || (_cache[8] = [
                        _createTextVNode(" Start over ")
                      ])),
                      _: 1
                    }, 8, ["disabled"])
                  ]),
                  _: 1
                }, 512), [
                  [_vShow, _unref(step) === steps.length - 1]
                ])
              ]),
              _: 1
            })
          ]),
          _: 1
        })
      ]),
      _: 1
    })
  ]))
}
}

})