import { defineComponent as _defineComponent } from 'vue'
import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, unref as _unref, withCtx as _withCtx, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

import {
  computed, PropType, Ref, ref,
} from 'vue';
import { BModal, BButton } from 'bootstrap-vue-next';
import FirefighterAccessForm from '@/components/FirefighterAccessForm.vue';
import { TenantRole } from '@/models/tenant';
/**
 * This Component represents a button and form in modal to request firefighter
 * access on the tenant detail page.
 */


export default /*@__PURE__*/_defineComponent({
  __name: 'FirefighterAccessRequest',
  props: {
  tenantName: {
    type: String,
    default() {
      return '';
    },
  },
  tenantRoles: {
    type: Array as PropType<TenantRole[]>,
    required: true,
  },
  successfulEvent: {
    type: Function,
    required: true,
  },
},
  setup(__props) {

const props = __props;

const modalRef = ref('') as unknown as Ref<BModal>;

const modalId = computed(() => `requestFirefighter_${props.tenantName}`);

function showModal() {
  modalRef.value.show();
}

function hideModal() {
  modalRef.value.hide();
}

return (_ctx: any,_cache: any) => {
  const _component_font_awesome_icon = _resolveComponent("font-awesome-icon")!

  return (_openBlock(), _createElementBlock("div", null, [
    _createVNode(_unref(BButton), {
      disabled: props.tenantRoles.length === 0,
      variant: "outline-danger",
      onClick: _cache[0] || (_cache[0] = ($event: any) => (showModal()))
    }, {
      default: _withCtx(() => [
        _createElementVNode("span", null, [
          _createVNode(_component_font_awesome_icon, {
            class: "cert-button-icon",
            icon: "fire-alt"
          })
        ]),
        _cache[1] || (_cache[1] = _createTextVNode(" Request Firefighter "))
      ]),
      _: 1
    }, 8, ["disabled"]),
    _createVNode(_unref(BModal), {
      id: modalId.value,
      ref_key: "modalRef",
      ref: modalRef,
      "hide-header": true,
      "no-footer": true,
      size: "lg"
    }, {
      default: _withCtx(() => [
        _cache[2] || (_cache[2] = _createElementVNode("h3", { class: "mt-3 mb-4" }, " Request Firefighter Access ", -1)),
        _createVNode(FirefighterAccessForm, {
          "tenant-name": __props.tenantName,
          "tenant-roles": __props.tenantRoles,
          "successful-event": __props.successfulEvent,
          "hide-modal": () => hideModal()
        }, null, 8, ["tenant-name", "tenant-roles", "successful-event", "hide-modal"])
      ]),
      _: 1
    }, 8, ["id"])
  ]))
}
}

})