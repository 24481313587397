<template>
  <div id="addUserRole">
    <div class="row">
      <div class="col-offset-2 col-12">
        <div>
          <div
            class="form-group"
            :onsubmit="formIsValid"
          >
            <div class="row-cols-md-8">
              <autocomplete
                ref="emailAutocomplete"
                base-class="uc-autocomplete"
                placeholder="Email address"
                :search="onUsernameChange"
                :get-result-value="(u: Suggestion) => u.username"
                :input="username"
                @change="onUpdateEmail"
                @submit="onSelectEmail"
              />
              <div
                v-if="typeaheadProcess"
                style="position: absolute; top: 25px; right: 36px; z-index: 1000"
              >
                <BSpinner
                  v-if="typeaheadProcess"
                  label="Loading..."
                  small
                />
              </div>
              <div
                v-if="!usernameIsValid"
                class="invalid-feedback d-block"
              >
                Please enter a valid email address.
              </div>
              <div
                v-if="usernameIsValid && userNotFound"
                id="newUserNotFoundError"
                class="invalid-feedback d-block"
              >
                We couldn't find any users with this email address.
              </div>
            </div>
            <br>
            <div class="row-cols-md-8">
              <DropDownListComponent
                id="roleSelector"
                v-model="role"
                class="mb-3"
                :default-option="SelectOptionsDropDown.ROLE"
                :options="roleOptions"
                @select-value="updateRole"
              />
              <div
                v-if="!roleIsValid"
                class="invalid-feedback d-block"
              >
                You need to choose a role that should be granted to the user.
              </div>
            </div>
          </div>
          <div class="d-flex flex-row-reverse gap-2 mt-4">
            <AsyncButton
              id="addMemberButton"
              variant="primary"
              size="md"
              class="ml-4"
              :restore-timeout="1000"
              :name="`Add Member`"
              initial-icon="user-plus"
              :disabled="!showAddButton"
              :fn="() => sendForm()"
              @status="handleUpdateStatus"
              @error="() => handleRemovalError()"
            />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import axios from 'axios';
import {
  computed, PropType, reactive, watch,
} from 'vue';
import { BButton, BSpinner } from 'bootstrap-vue-next';
import { useRoute } from 'vue-router';
import Autocomplete from '@trevoreyre/autocomplete-vue';
import { onBeforeMount, onMounted, ref } from '@vue/runtime-dom';
import AsyncButton from '@/components/AsyncButton.vue';
import DropDownListComponent from '@/components/DropDownListComponent.vue';
import { getKeycloakRequestConfig } from '@/utils/http';
import { Permission } from '@/models/keycloak';
import { TenantInformation, SelectOptionsDropDown } from '@/models/tenant';
import { BackendError } from '@/models/errors';
import { userStore } from '@/store/modules/user';
import { Suggestion } from '@/models/typeahead';
import useTenantComponent from '@/composable/tenantComponent';
import { AsyncButtonStatus } from '@/models/asyncButton';
import { useAsyncButton } from '@/composable/apiStates';
import { useTenantRoles } from '@/composable/useTenantRoles';
import Utils from '@/utils/utils';

const props = defineProps({
  tenantName: {
    type: String,
    required: true,
  },
  tenantInfo: {
    type: Object as PropType<TenantInformation>,
    required: true,
  },
  successfulEvent: {
    type: Function,
    required: true,
  },
  users: {
    type: Array as PropType<Permission[]>,
    required: true,
  },
});

const emits = defineEmits(['success', 'error', 'successfulEvent', 'hide']);
const route = useRoute();
const user = userStore();
const status = useAsyncButton();
let { userTypeaheadSuggestions } = useTenantComponent();
const { roleOptions } = useTenantRoles(props.tenantInfo.roles, false);
const {
  // eslint-disable-next-line max-len
  username, typeaheadProcess, usernameIsValid, getTypeaheadUser,
  role, roleIsValid, showUserMissing,
} = useTenantComponent();

const errorResponse: BackendError = reactive({
  type: null,
  message: null,
});

const updateRole = (selectedRole: string) => {
  role.value = selectedRole;
  status.status = AsyncButtonStatus.INITIAL;
  status.message = null;
  errorResponse.type = null;
  errorResponse.message = null;
};

const userNotFound = ref(false);

// Debounced check for user existence
const debouncedCheckUser = Utils.debounce(async (input: string) => {
  if (input.length >= 3) {
    userTypeaheadSuggestions = await getTypeaheadUser(input);
    const found = showUserMissing(userTypeaheadSuggestions);
    userNotFound.value = !found;
  } else {
    userNotFound.value = false;
  }
}, 100);

const hasRole = computed(() => {
  if (props.users && role) {
    return props.users.find(
      (u) => u.username === username.value && u.role === role.value && u.environment !== 'prod',
    );
  }
  return null;
});

const emailAutocomplete = ref(null);

// eslint-disable-next-line max-len
const formIsValid = computed(() => usernameIsValid.value && !hasRole.value && roleIsValid.value);
const showAddButton = computed(() => formIsValid.value);

async function onUpdateEmail(input: any) {
  userNotFound.value = false;
  if (input === null) {
    username.value = null;
    userNotFound.value = true;
  } else {
    username.value = input.target.value;
    debouncedCheckUser(username.value);
  }
}

async function onUsernameChange(input: string): Promise<Suggestion[]> {
  if (input.length < 3) {
    username.value = null;
    return [];
  }
  username.value = input;
  userTypeaheadSuggestions = await getTypeaheadUser(input);
  return userTypeaheadSuggestions;
}

async function onSelectEmail(input: Suggestion) {
  username.value = input.username;
}

const handleUpdateStatus = (statusBtn: AsyncButtonStatus, message: string) => {
  status.status = statusBtn;
  status.message = message;

  if (statusBtn === AsyncButtonStatus.SUCCESSFUL) {
    emailAutocomplete.value.setValue({ username: '' });
    username.value = '';
  }
};

watch(role, () => {
  status.status = AsyncButtonStatus.INITIAL;
  status.message = null;
  errorResponse.type = null;
  errorResponse.message = null;
});

function handleRemovalError() {
  errorResponse.message = null;
  errorResponse.type = null;
}

async function sendForm() {
  const suggestion = userTypeaheadSuggestions.find((u) => u.username === username.value);
  const url = `/tenants/${route.params.tenantName}/users/${suggestion.userId}/roles/${role.value}`;
  const body = {};

  try {
    const response = await axios.put<Permission>(
      url,
      body,
      getKeycloakRequestConfig(user.xsrfToken),
    );

    if (props.successfulEvent) {
      props.successfulEvent(response.data);
    }
  } catch (err) {
    if (err.response && err.response.data) {
      errorResponse.message = err.response.data.message;
      emits('error', errorResponse.message);
    }
    throw errorResponse;
  }
}
</script>
