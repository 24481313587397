import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock, createElementVNode as _createElementVNode, isRef as _isRef } from "vue"

const _hoisted_1 = { id: "addUserRole" }
const _hoisted_2 = { class: "row" }
const _hoisted_3 = { class: "col-offset-2 col-12" }
const _hoisted_4 = ["onsubmit"]
const _hoisted_5 = { class: "row-cols-md-8" }
const _hoisted_6 = {
  key: 0,
  style: {"position":"absolute","top":"25px","right":"36px","z-index":"1000"}
}
const _hoisted_7 = {
  key: 1,
  class: "invalid-feedback d-block"
}
const _hoisted_8 = {
  key: 2,
  id: "newUserNotFoundError",
  class: "invalid-feedback d-block"
}
const _hoisted_9 = { class: "row-cols-md-8" }
const _hoisted_10 = {
  key: 0,
  class: "invalid-feedback d-block"
}
const _hoisted_11 = { class: "d-flex flex-row-reverse gap-2 mt-4" }

import axios from 'axios';
import {
  computed, PropType, reactive, watch,
} from 'vue';
import { BButton, BSpinner } from 'bootstrap-vue-next';
import { useRoute } from 'vue-router';
import Autocomplete from '@trevoreyre/autocomplete-vue';
import { onBeforeMount, onMounted, ref } from '@vue/runtime-dom';
import AsyncButton from '@/components/AsyncButton.vue';
import DropDownListComponent from '@/components/DropDownListComponent.vue';
import { getKeycloakRequestConfig } from '@/utils/http';
import { Permission } from '@/models/keycloak';
import { TenantInformation, SelectOptionsDropDown } from '@/models/tenant';
import { BackendError } from '@/models/errors';
import { userStore } from '@/store/modules/user';
import { Suggestion } from '@/models/typeahead';
import useTenantComponent from '@/composable/tenantComponent';
import { AsyncButtonStatus } from '@/models/asyncButton';
import { useAsyncButton } from '@/composable/apiStates';
import { useTenantRoles } from '@/composable/useTenantRoles';
import Utils from '@/utils/utils';


export default /*@__PURE__*/_defineComponent({
  __name: 'AddUserToTenantRole',
  props: {
  tenantName: {
    type: String,
    required: true,
  },
  tenantInfo: {
    type: Object as PropType<TenantInformation>,
    required: true,
  },
  successfulEvent: {
    type: Function,
    required: true,
  },
  users: {
    type: Array as PropType<Permission[]>,
    required: true,
  },
},
  emits: ['success', 'error', 'successfulEvent', 'hide'],
  setup(__props, { emit: __emit }) {

const props = __props;

const emits = __emit;
const route = useRoute();
const user = userStore();
const status = useAsyncButton();
let { userTypeaheadSuggestions } = useTenantComponent();
const { roleOptions } = useTenantRoles(props.tenantInfo.roles, false);
const {
  // eslint-disable-next-line max-len
  username, typeaheadProcess, usernameIsValid, getTypeaheadUser,
  role, roleIsValid, showUserMissing,
} = useTenantComponent();

const errorResponse: BackendError = reactive({
  type: null,
  message: null,
});

const updateRole = (selectedRole: string) => {
  role.value = selectedRole;
  status.status = AsyncButtonStatus.INITIAL;
  status.message = null;
  errorResponse.type = null;
  errorResponse.message = null;
};

const userNotFound = ref(false);

// Debounced check for user existence
const debouncedCheckUser = Utils.debounce(async (input: string) => {
  if (input.length >= 3) {
    userTypeaheadSuggestions = await getTypeaheadUser(input);
    const found = showUserMissing(userTypeaheadSuggestions);
    userNotFound.value = !found;
  } else {
    userNotFound.value = false;
  }
}, 100);

const hasRole = computed(() => {
  if (props.users && role) {
    return props.users.find(
      (u) => u.username === username.value && u.role === role.value && u.environment !== 'prod',
    );
  }
  return null;
});

const emailAutocomplete = ref(null);

// eslint-disable-next-line max-len
const formIsValid = computed(() => usernameIsValid.value && !hasRole.value && roleIsValid.value);
const showAddButton = computed(() => formIsValid.value);

async function onUpdateEmail(input: any) {
  userNotFound.value = false;
  if (input === null) {
    username.value = null;
    userNotFound.value = true;
  } else {
    username.value = input.target.value;
    debouncedCheckUser(username.value);
  }
}

async function onUsernameChange(input: string): Promise<Suggestion[]> {
  if (input.length < 3) {
    username.value = null;
    return [];
  }
  username.value = input;
  userTypeaheadSuggestions = await getTypeaheadUser(input);
  return userTypeaheadSuggestions;
}

async function onSelectEmail(input: Suggestion) {
  username.value = input.username;
}

const handleUpdateStatus = (statusBtn: AsyncButtonStatus, message: string) => {
  status.status = statusBtn;
  status.message = message;

  if (statusBtn === AsyncButtonStatus.SUCCESSFUL) {
    emailAutocomplete.value.setValue({ username: '' });
    username.value = '';
  }
};

watch(role, () => {
  status.status = AsyncButtonStatus.INITIAL;
  status.message = null;
  errorResponse.type = null;
  errorResponse.message = null;
});

function handleRemovalError() {
  errorResponse.message = null;
  errorResponse.type = null;
}

async function sendForm() {
  const suggestion = userTypeaheadSuggestions.find((u) => u.username === username.value);
  const url = `/tenants/${route.params.tenantName}/users/${suggestion.userId}/roles/${role.value}`;
  const body = {};

  try {
    const response = await axios.put<Permission>(
      url,
      body,
      getKeycloakRequestConfig(user.xsrfToken),
    );

    if (props.successfulEvent) {
      props.successfulEvent(response.data);
    }
  } catch (err) {
    if (err.response && err.response.data) {
      errorResponse.message = err.response.data.message;
      emits('error', errorResponse.message);
    }
    throw errorResponse;
  }
}

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _createElementVNode("div", null, [
          _createElementVNode("div", {
            class: "form-group",
            onsubmit: formIsValid.value
          }, [
            _createElementVNode("div", _hoisted_5, [
              _createVNode(_unref(Autocomplete), {
                ref_key: "emailAutocomplete",
                ref: emailAutocomplete,
                "base-class": "uc-autocomplete",
                placeholder: "Email address",
                search: onUsernameChange,
                "get-result-value": (u) => u.username,
                input: _unref(username),
                onChange: onUpdateEmail,
                onSubmit: onSelectEmail
              }, null, 8, ["get-result-value", "input"]),
              (_unref(typeaheadProcess))
                ? (_openBlock(), _createElementBlock("div", _hoisted_6, [
                    (_unref(typeaheadProcess))
                      ? (_openBlock(), _createBlock(_unref(BSpinner), {
                          key: 0,
                          label: "Loading...",
                          small: ""
                        }))
                      : _createCommentVNode("", true)
                  ]))
                : _createCommentVNode("", true),
              (!_unref(usernameIsValid))
                ? (_openBlock(), _createElementBlock("div", _hoisted_7, " Please enter a valid email address. "))
                : _createCommentVNode("", true),
              (_unref(usernameIsValid) && _unref(userNotFound))
                ? (_openBlock(), _createElementBlock("div", _hoisted_8, " We couldn't find any users with this email address. "))
                : _createCommentVNode("", true)
            ]),
            _cache[2] || (_cache[2] = _createElementVNode("br", null, null, -1)),
            _createElementVNode("div", _hoisted_9, [
              _createVNode(DropDownListComponent, {
                id: "roleSelector",
                modelValue: _unref(role),
                "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => (_isRef(role) ? (role).value = $event : null)),
                class: "mb-3",
                "default-option": _unref(SelectOptionsDropDown).ROLE,
                options: _unref(roleOptions),
                onSelectValue: updateRole
              }, null, 8, ["modelValue", "default-option", "options"]),
              (!_unref(roleIsValid))
                ? (_openBlock(), _createElementBlock("div", _hoisted_10, " You need to choose a role that should be granted to the user. "))
                : _createCommentVNode("", true)
            ])
          ], 8, _hoisted_4),
          _createElementVNode("div", _hoisted_11, [
            _createVNode(AsyncButton, {
              id: "addMemberButton",
              variant: "primary",
              size: "md",
              class: "ml-4",
              "restore-timeout": 1000,
              name: `Add Member`,
              "initial-icon": "user-plus",
              disabled: !showAddButton.value,
              fn: () => sendForm(),
              onStatus: handleUpdateStatus,
              onError: _cache[1] || (_cache[1] = () => handleRemovalError())
            }, null, 8, ["disabled", "fn"])
          ])
        ])
      ])
    ])
  ]))
}
}

})