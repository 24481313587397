import { defineComponent as _defineComponent } from 'vue'
import { renderSlot as _renderSlot, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, withCtx as _withCtx } from "vue"

const _hoisted_1 = { class: "mt-4" }
const _hoisted_2 = { class: "table table-striped" }
const _hoisted_3 = { class: "align-middle" }
const _hoisted_4 = { class: "" }

import {
  onMounted, reactive,
} from 'vue';
import BubblewrapComponent from '@/components/BubblewrapComponent.vue';


export default /*@__PURE__*/_defineComponent({
  __name: 'AsyncTable',
  props: {
  fn: {
    type: Function,
    required: true,
  },
  headline: {
    type: String,
    required: true,
  },
  atWidth: {
    default: 1000,
    type: Number,
  },
  atHeight: {
    default: 20,
    type: Number,
  },
  defaultLines: {
    type: Number,
    default: 3,
  },
},
  setup(__props) {

const props = __props;

const apiCall = reactive({
  loaded: false,
  error: null,
});

onMounted(async () => {
  try {
    await props.fn();
  } catch (e) {
    apiCall.error = e;
  }
  apiCall.loaded = true;
});


return (_ctx: any,_cache: any) => {
  const _component_ContentLoader = _resolveComponent("ContentLoader")!

  return (_openBlock(), _createElementBlock("div", null, [
    _renderSlot(_ctx.$slots, "headline", {}, () => [
      _createElementVNode("h4", _hoisted_1, _toDisplayString(__props.headline), 1)
    ]),
    _createVNode(BubblewrapComponent, { info: apiCall }, {
      placeholder: _withCtx(() => [
        _createElementVNode("table", _hoisted_2, [
          _createElementVNode("thead", null, [
            _createElementVNode("tr", null, [
              _createElementVNode("th", _hoisted_3, [
                _createVNode(_component_ContentLoader, {
                  width: `${__props.atWidth}px`,
                  height: `${__props.atHeight}px`
                }, null, 8, ["width", "height"])
              ])
            ])
          ]),
          _createElementVNode("tbody", _hoisted_4, [
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(__props.defaultLines, (n) => {
              return (_openBlock(), _createElementBlock("tr", {
                key: n,
                class: ""
              }, [
                _createElementVNode("td", null, [
                  _createVNode(_component_ContentLoader, {
                    width: `${__props.atWidth}px`,
                    height: `${__props.atHeight}px`
                  }, null, 8, ["width", "height"])
                ])
              ]))
            }), 128))
          ])
        ])
      ]),
      loaded: _withCtx(() => [
        _renderSlot(_ctx.$slots, "default")
      ]),
      _: 3
    }, 8, ["info"])
  ]))
}
}

})