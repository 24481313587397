import { defineComponent as _defineComponent } from 'vue'
import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, unref as _unref, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "footer" }

import { computed } from 'vue';
import { BNavbar } from 'bootstrap-vue-next';
import app from '@/store/modules/app';


export default /*@__PURE__*/_defineComponent({
  __name: 'FooterComponent',
  setup(__props) {

const appVersion = app();
const version = computed(() => appVersion.getVersion);


return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("footer", _hoisted_1, [
    _createVNode(_unref(BNavbar), {
      variant: "dark",
      fixed: "bottom",
      class: "text-light px-3"
    }, {
      default: _withCtx(() => [
        _createElementVNode("span", null, "version: " + _toDisplayString(version.value), 1)
      ]),
      _: 1
    })
  ]))
}
}

})