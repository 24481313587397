<template>
  <BContainer id="profile">
    <div class="row">
      <div class="col-offset-2 col-12">
        <div class="row">
          <div class="col-12">
            <BBreadcrumb>
              <BBreadcrumbItem :to="{ name: 'myTenants' }">
                My Tenants
              </BBreadcrumbItem>
              <BBreadcrumbItem active>
                {{ tenantName }}
              </BBreadcrumbItem>
            </BBreadcrumb>
            <h3 class="mt-4">
              Tenant Options for: {{ tenantName }}
              <BLink
                v-if="isApprover"
                id="tenant-settings"
                :to="{ name: 'tenantSettings', params: { tenantName } }"
                class="float-end text-dark"
              >
                <font-awesome-icon size="xs" :icon="'cog'" />
              </BLink>
            </h3>
            <p id="roles" />
          </div>
          <BCol id="requestFirefighter">
            <FirefighterAccessRequest
              :tenant-name="tenantName"
              :tenant-info="tenantInfo"
              :tenant-roles="tenantRoles"
              :successful-event="(status) => handleRequestFirefighter(status)"
              @error="(err) => handleFirefighterError(err)"
            />
          </BCol>
          <div class="col-12 my-4">
            <div id="tenant-details">
              <BCard>
                <CollapsibleComponent
                  :is-header="true"
                  :header="'Tenant Information'"
                  :toggle-id="'tenant-info-collapsible'"
                  :is-visible="!isApprover && apiCall.loaded"
                >
                  <Bubblewrap :info="apiCall">
                    <template #placeholder>
                      <BContainer>
                        <BRow>
                          <BCol>
                            <div class="spacer" />
                            <ContentLoader />
                          </BCol>
                        </BRow>
                      </BContainer>
                    </template>

                    <template #loaded>
                      <BRow class="row-gap-4">
                        <BCol class="" md="6">
                          <TenantDetailComponent
                            :compliance-profile="tenantInfo.complianceProfile"
                            :region="tenantInfo.region"
                            :quick-sight="tenantInfo.quickSight"
                            :artifactory-repositories="tenantInfo.artifactoryRepositories"
                          />
                        </BCol>
                        <BCol md="6">
                          <h5 class="">
                            Accounts
                          </h5>

                          <BRow
                            cols="1"
                            class="gap-2"
                          >
                            <BCol
                              v-for="(account, idx) in tenantInfo.accounts"
                              :key="idx"
                            >
                              <CollapsibleComponent
                                class="gy-4"
                                :is-header="false"
                                :header="account.accountAlias"
                                :is-visible="false"
                                :toggle-id="`${account.accountAlias}-${idx}`"
                              >
                                <AccountDetailComponent
                                  class="gy-4"
                                  :account-alias="account.accountAlias"
                                  :account-id="account.accountID"
                                  :mps-connect-cidr="account.mpsConnectCIDR"
                                  :mps-connect-enabled="account.mpsConnectEnabled"
                                  :vpc-a-zs="account.vpcAZs"
                                  :vpc-cidr="account.vpcCIDR"
                                  :idx="idx"
                                />
                              </CollapsibleComponent>
                            </BCol>
                          </BRow>
                        </BCol>
                        <BCol md="12">
                          <TenantContacts
                            :approvers="tenantInfo.contacts.approver"
                            :escalators="tenantInfo.contacts.escalator"
                            :responsibles="tenantInfo.contacts.responsible"
                          />
                        </BCol>
                      </BRow>
                    </template>
                  </Bubblewrap>
                </CollapsibleComponent>
              </BCard>
            </div>
          </div>
          <BCol v-if="isApprover" lg="6" md="12" />
        </div>
        <div v-if="isApprover" id="tenantMembersAndAccess" class="p-1">
          <p class="lead">
            You can see all the users of the tenant below.
          </p>
          <h4 class="mt-4">
            <BRow>
              <BCol cols="10" md="8">
                Firefighter Accesses
                <BButton
                  id="show-tenant-reports"
                  size="sm"
                  :to="{ name: 'tenantReports', params: { tenantName } }"
                >
                  Show Reports
                </BButton>
              </BCol>
              <BCol cols="2" md="4">
                <BButton
                  id="add-firefighter"
                  class="float-end"
                  variant="primary"
                  @click="showAddFirefighterModal = !showAddFirefighterModal"
                >
                  Add Firefighter
                </BButton>

                <BModal
                  id="add-user-to-tenant-firefighter-modal"
                  v-model="showAddFirefighterModal"
                  :title="`Add Firefighter to ${tenantName}`"
                  size="lg"
                  no-footer
                >
                  <AddUserToTenantFirefighter
                    :visible="showAddFirefighterModal"
                    :tenant-name="tenantName"
                    :tenant-info="tenantInfo"
                    :tenant-members="getMembers"
                    :successful-event="(access, scheduled) => addFirefighter(access, scheduled)"
                    :firefighter-accesses="firefighterAccesses"
                    @hide="() => showAddFirefighterModal = false"
                    @error="(err) => handleFirefighterError(err)"
                  />
                </BModal>
              </BCol>
            </BRow>
          </h4>
          <BCard no-body>
            <BTabs id="firefighterTabs" card>
              <BTab>
                <template #title>
                  <span id="active-firefighters-title">
                    Active Firefighters
                    <template v-if="checkFireFighter">
                      ({{ firefighterAccesses.length }})
                    </template>
                  </span>
                </template>
                <AsyncTable headline="" :fn="() => loadActiveFirefighterTable()">
                  <div v-if="!checkFireFighter" class="alert alert-light">
                    There is no temporary production access at the moment.
                  </div>
                  <BTable
                    v-if="firefighterAccesses.length > 0"
                    id="firefighters"
                    striped
                    hover
                    responsive
                    :items="firefighterAccesses"
                    :fields="firefighterFields"
                    primary-key="id"
                  >
                    <template #cell(fullName)="data">
                      <a class="text-decoration-none" :href="`mailto:${data.item.user.email}`">
                        {{ data.item.user.firstName }} {{ data.item.user.lastName }}
                      </a>
                    </template>

                    <template #cell(grantedByFullName)="data">
                      <a class="text-decoration-none" :href="`mailto:${data.item.grantedBy.email}`">
                        {{ data.item.grantedBy.firstName }} {{ data.item.grantedBy.lastName }}
                      </a>
                    </template>
                    <template #cell(revokeAt)="data">
                      {{ formatRelative(data.item.revokeAt, new Date()) }}
                    </template>
                    <template #cell(startAt)="data">
                      {{ formatRelative(data.item.startAt, new Date()) }}
                    </template>
                    <template #cell(reason)="data">
                      <font-awesome-icon
                        :id="`scheduled_firefighter_${data.item.id}`"
                        v-b-tooltip="data.item.reason"
                        class="ff-button-icon"
                        icon="info-circle"
                      />
                    </template>

                    <template #cell(actions)="data">
                      <AsyncButton
                        id="cancelActiveFirefighter"
                        name="Cancel"
                        variant="secondary"
                        :confirm-modal="
                          'Are you sure you want to \ncancel this firefighter action?'
                        "
                        initial-icon="ban"
                        :fn="() => cancelFirefighter(data.item, true)"
                        @error="(err) => handleCancelFirefighterError(err, true)"
                        @status="handleUpdateStatus"
                      />
                    </template>
                  </BTable>
                </AsyncTable>
              </BTab>
              <BTab id="scheduled-firefighters">
                <template #title>
                  <span id="scheduled-firefighters-title">
                    Scheduled Firefighters
                    <template v-if="scheduledFirefighterAccesses">
                      ({{ scheduledFirefighterAccesses.length }})
                    </template>
                  </span>
                </template>
                <AsyncTable headline="" :fn="() => loadScheduledFirefighterTable()">
                  <div v-if="scheduledFirefighterAccesses.length === 0" class="alert alert-light">
                    There are currently no scheduled firefighter accesses.
                  </div>

                  <BTable
                    v-if="scheduledFirefighterAccesses.length > 0"
                    id="scheduledFirefighters"
                    striped
                    hover
                    responsive
                    :items="scheduledFirefighterAccesses"
                    :fields="scheduledFirefighterFields"
                    primary-key="id"
                  >
                    <template #cell(fullName)="data">
                      <a class="text-decoration-none" :href="`mailto:${data.item.user.email}`">
                        {{ data.item.user.firstName }} {{ data.item.user.lastName }}
                      </a>
                    </template>

                    <template #cell(grantedByFullName)="data">
                      <a class="text-decoration-none" :href="`mailto:${data.item.grantedBy.email}`">
                        {{ data.item.grantedBy.firstName }} {{ data.item.grantedBy.lastName }}
                      </a>
                    </template>
                    <template #cell(revokeAt)="data">
                      {{ formatRelative(data.item.revokeAt, new Date()) }}
                    </template>
                    <template #cell(startAt)="data">
                      {{ formatRelative(data.item.startAt, new Date()) }}
                    </template>
                    <template #cell(reason)="data">
                      <font-awesome-icon
                        :id="`scheduled_firefighter_${data.item.id}`"
                        v-b-tooltip="data.item.reason"
                        class="ff-button-icon"
                        icon="info-circle"
                      />
                    </template>

                    <template #cell(actions)="data">
                      <AsyncButton
                        id="cancelScheduledFirefighter"
                        name="Cancel"
                        variant="secondary"
                        :confirm-modal="
                          'Are you sure you want to cancel \nthis firefighter action?'
                        "
                        initial-icon="ban"
                        :fn="() => cancelFirefighter(data.item, true)"
                        @error="(err) => handleCancelFirefighterError(err, true)"
                        @status="handleUpdateStatus"
                      />
                    </template>
                  </BTable>
                </AsyncTable>
              </BTab>
            </BTabs>
          </BCard>
          <AsyncTable headline="" :fn="() => loadUsers()">
            <template #headline>
              <h4 class="mt-4">
                Members
                <JsonCSV
                  id="download-tenant-member-table"
                  class="btn btn-secondary btn-sm"
                  :data="csvData"
                  :name="`${route.params.tenantName}.members.csv`"
                >
                  Download as CSV
                </JsonCSV>
              </h4>
            </template>
            <div v-if="enabledUsers.length === 0" class="alert alert-light">
              There are no members in this tenant. Why don't you add some?
            </div>
            <div class="row mt-4 align-items-center">
              <div class="col-2">
                <BRow align-v="center">
                  <BCol cols="2">
                    <font-awesome-icon icon="filter" size="lg" class="text-secondary" />
                  </BCol>
                  <BCol>
                    <BButton
                      style="width: 100%"
                      variant="outline-secondary"
                      @click="filterMembers(FilterMembers.ALL)"
                    >
                      {{ FilterMembers.ALL }}
                    </BButton>
                  </BCol>
                </BRow>
              </div>
              <div class="col-2">
                <DropDownListComponent
                  id="expireDropDownList"
                  :default-option="'Expires in'"
                  :options="optionsSelector(expirePerPageOptions)"
                  @select-value="updateMemberExpireList"
                />
              </div>
              <BCol cols="2" offset="6" md="4" offset-md="4">
                <BPopover
                  id="add-user-to-tenant-modal"
                  :model-value="showAddUserToTenantModal"
                  placement="left"
                  :click="true"
                  :delay="{ show: 0, hide: 0 }"
                  noninteractive
                >
                  <template #target>
                    <BButton
                      class="float-end"
                      variant="primary"
                      @click="showAddUserToTenantModal = !showAddUserToTenantModal"
                    >
                      Add Member
                    </BButton>
                  </template>

                  <template #title>
                    <span class="fs-6">Add Member</span>
                  </template>

                  <AddUserToTenantRole
                    :tenant-name="tenantName"
                    :successful-event="(user) => addUser(user)"
                    :users="users"
                    :tenant-info="tenantInfo"
                    @hide="() => showAddUserToTenantModal = false"
                    @error="(err) => handleAddRoleError(err)"
                  />
                </BPopover>
              </BCol>
            </div>
            <PaginatedMembersTable
              id="members"
              :fields="fields"
              :disabled-fields="disabledFields"
              :toast="toast"
              :users="getMembers"
              :per-page="resultsPerPage"
              @removed="(user) => updateMemberList(user)"
            />
            <BRow>
              <div class="col">
                <div class="row g-0">
                  <div class="col text-end">
                    <p class="me-2 pt-1">
                      View
                    </p>
                  </div>
                  <div class="col-auto">
                    <DropDownListComponent
                      id="usersFilterDropDownList"
                      :default-option="membersPerPage.toString()"
                      :options="optionsSelector(membersPerPageOptions)"
                      @select-value="updateUsersTable"
                    />
                  </div>
                  <div class="col-auto">
                    <p class="ms-2 pt-1">
                      users per page
                    </p>
                  </div>
                </div>
              </div>
            </BRow>
          </AsyncTable>

          <AsyncTable headline="Offboarded Members" :fn="() => loadUsers()">
            <div v-if="deactivatedUsers.length === 0" class="alert alert-light">
              There are no deactivated members in this tenant.
            </div>

            <PaginatedMembersTable
              v-if="deactivatedUsers.length > 0"
              id="offboarded-members"
              :users="deactivatedUsers"
              :fields="disabledFields"
              :disabled-fields="disabledFields"
              :toast="toast"
              :per-page="resultsPerPage"
              :table-id="tableNameOffboard"
            />
          </AsyncTable>
        </div>
      </div>
    </div>
    <BModal
      v-if="isApprover"
      id="certification-info"
      title="(Re)certification"
      :ok-only="true"
      size="lg"
    >
      <p class="my-4">
        The re-certification process is mandated by compliance restrictions for the MPS platform.
      </p>

      <p class="my-4">
        To ensure that only members of your team have access to your accounts, access to every
        tenant (and every role) needs to be verified every 90 days. Both you and the user will
        receive email notifications when the certification period expires.
      </p>

      <p class="my-4">
        Only approvers can re-certify a user.
      </p>
    </BModal>
  </BContainer>
</template>

<script setup lang="ts">
import { formatRelative } from 'date-fns';
import axios, { AxiosError } from 'axios';
import {
  computed, onBeforeMount, onMounted, reactive, ref,
} from 'vue';
import {
  BTable, BBreadcrumb, BBreadcrumbItem, BLink, BCol, BRow,
  BContainer, BCard, BButton, BTab, BTabs, BModal, BPopover,
  useToastController,
  BaseColorVariant,
} from 'bootstrap-vue-next';
import { useRoute } from 'vue-router';
import JsonCSV from 'vue-json-csv';
import AddUserToTenantRole from '@/components/AddUserToTenantRole.vue';
import TenantDetailComponent from '@/components/TenantDetailComponent.vue';
import AccountDetailComponent from '@/components/AccountDetailComponent.vue';
import AddUserToTenantFirefighter from '@/components/AddUserToTenantFirefighter.vue';
import AsyncTable from '@/components/AsyncTable.vue';
import AsyncButton from '@/components/AsyncButton.vue';
import Bubblewrap from '@/components/BubblewrapComponent.vue';
import TenantContacts from '@/components/TenantContacts.vue';
import PaginatedMembersTable from '@/components/PaginatedMembersTable.vue';
import CollapsibleComponent from '@/components/CollapsibleComponent.vue';
import DropDownListComponent from '@/components/DropDownListComponent.vue';
import { Permission, TenantRepresentation } from '@/models/keycloak';
import { SelectOptionsDropDown, TenantInformation } from '@/models/tenant';
import { getKeycloakRequestConfig } from '@/utils/http';
import { userStore } from '@/store/modules/user';
import { useApiCall, useAsyncButton } from '@/composable/apiStates';
import { AsyncButtonStatus } from '@/models/asyncButton';
import { fetchTenant } from '@/api/tenant';
import { FirefighterAccessRepresentation, FirefighterStatus } from '@/models/firefighter';
import FirefighterAccessRequest from '../components/FirefighterAccessRequest.vue';

const defaultFields = [
  {
    key: 'lastName',
    label: 'Name',
    sortable: true,
    class: 'align-middle',
  },
  {
    key: 'environment',
    label: 'Environments',
    sortable: true,
    class: 'align-middle',
  },
  {
    key: 'role',
    label: 'Role',
    sortable: true,
    class: 'align-middle',
  },
];

// eslint-disable-next-line no-shadow
enum FilterMembers {
  ALL = 'All',
  EXPIRING_SOON = 'Expiring Soon',
}

const tenantName = ref();
const disabledFields = defaultFields;
const user = userStore();
const apiCall = useApiCall();
const status = useAsyncButton();
const { show } = useToastController();
const showAddUserToTenantModal = ref(false);
const showAddFirefighterModal = ref(false);

const fields = reactive([
  ...defaultFields,
  {
    key: 'certificationData',
    label: 'Certification expires in',
    sortable: true,
    class: 'align-middle',
  },
  {
    key: 'actions',
    label: 'Actions',
    sortable: false,
    class: 'align-middle',
  },
]);

const firefighterFields = ref([
  {
    key: 'fullName',
    label: 'Name',
    sortable: true,
    class: 'align-middle',
    formatter: (_value: any, _key: string, item: any) => `${item.user.firstName} ${item.user.lastName}`,
  },
  {
    key: 'role',
    label: 'Role',
    sortable: true,
    class: 'align-middle',
  },
  {
    key: 'grantedByFullName',
    label: 'Granted by',
    sortable: true,
    class: 'align-middle',
    formatter: (_value: any, _key: string, item: any) => `${item.grantedBy.firstName} ${item.grantedBy.lastName}`,
  },
  {
    key: 'revokeAt',
    label: 'Access until',
    sortable: true,
    class: 'align-middle',
  },
  {
    key: 'reason',
    label: 'Reason',
    class: 'align-middle',
  },
  {
    key: 'actions',
    label: 'Actions',
    sortable: false,
    class: 'align-middle',
  },
]);

const scheduledFirefighterFields = ref([
  {
    key: 'fullName',
    label: 'Name',
    sortable: true,
    class: 'align-middle',
    formatter: (_value: any, _key: string, item: any) => `${item.user.firstName} ${item.user.lastName}`,
  },
  {
    key: 'role',
    label: 'Role',
    sortable: true,
    class: 'align-middle',
  },
  {
    key: 'grantedByFullName',
    label: 'Granted by',
    sortable: true,
    class: 'align-middle',
    formatter: (_value: any, _key: string, item: any) => `${item.grantedBy.firstName} ${item.grantedBy.lastName}`,
  },
  {
    key: 'startAt',
    label: 'Start time',
    sortable: true,
    class: 'align-middle',
  },
  {
    key: 'revokeAt',
    label: 'Access until',
    sortable: true,
    class: 'align-middle',
  },
  {
    key: 'reason',
    label: 'Reason',
    class: 'align-middle',
  },
  {
    key: 'actions',
    label: 'Actions',
    sortable: false,
    class: 'align-middle',
  },
]);

const route = useRoute();

const users = ref<Array<Permission & { _uniqueId: string }>>([]);

const firefighterAccesses = ref([]);

const scheduledFirefighterAccesses = ref([]);

const tenantInfo: TenantInformation = reactive({
  accounts: [],
  region: '',
  complianceProfile: '',
  artifactoryRepositories: { virtualRepo: [], releaseRepo: [], snapshotRepo: [] },
  roles: [],
  contacts: {
    approver: [] as string[],
    escalator: [] as string[],
    responsible: [] as string[],
  },
  quickSight: false,
});

const tenantRoles = computed(() => [...tenantInfo.roles]);

const tenantPermissions = reactive([]);

const isApprover = ref(false);

const formattedDate = ref('');

const dateToFormat = new Date();

const activeFilter = ref(FilterMembers.ALL);

const expireSoon = ref('');

const expirePerPageOptions = [
  SelectOptionsDropDown.EXPIRES,
  '30 days',
  '50 days',
  '70 days',
  '80 days',
];

const membersPerPageOptions = ['50', '100', 'All'];

const membersPerPage = ref(100);

const deactivatedUsers = computed(() => users.value.filter((u) => u.enabled === false));

const getUniqueId = (userId: string, role: string, environment: string) => `${userId}.${role}.${environment}`;

const getMembers = computed(() => {
  const enabledUsers = users.value.filter((u) => u.enabled === true);
  const matches = expireSoon.value.match(/(\d+)/);
  if (activeFilter.value === FilterMembers.ALL) {
    return enabledUsers;
  }

  if (activeFilter.value === FilterMembers.EXPIRING_SOON) {
    const expiredMembers = enabledUsers.filter((u) => {
      if (u.certificationData) {
        return u.certificationData.expiresIn <= matches[0];
      }
      return false;
    });
    return expiredMembers;
  }

  return users.value;
});

const updateMemberList = (member) => {
  const indexToRemove = users.value.findIndex((u) => u.id === member.id && u.role === member.role);

  if (indexToRemove !== -1) {
    // eslint-disable-next-line max-len
    const newUsersArray = [
      ...users.value.slice(0, indexToRemove),
      ...users.value.slice(indexToRemove + 1),
    ];
    users.value = newUsersArray;
  }
};

const optionsSelector = (items) => {
  const options = [];
  // eslint-disable-next-line array-callback-return
  items.map((element) => {
    options.push(
      ...[
        {
          item: element,
          title: element,
          disabled: element === 'Expires in',
        },
      ],
    );
  });
  return options;
};

const updateUsersTable = (selectedValue) => {
  if (selectedValue === 'All') {
    membersPerPage.value = users.value.length;
  } else {
    membersPerPage.value = Number(selectedValue);
  }
};

const resultsPerPage = computed(() => membersPerPage.value);

const updateMemberExpireList = (selectedValue) => {
  expireSoon.value = selectedValue;
  activeFilter.value = FilterMembers.EXPIRING_SOON;
};

async function fetchMyTenantPermissions(): Promise<any> {
  return axios
    .get<TenantRepresentation>(
      `/users/${user.id}/tenants/${tenantName.value}`,
      getKeycloakRequestConfig(user.xsrfToken),
    )
    .then((response) => response.data);
}

const checkFireFighter = computed(() => firefighterAccesses.value.length > 0);

const enabledUsers = computed(() => users.value.filter((u) => u.enabled === true));

onMounted(() => {
  formattedDate.value = formatRelative(dateToFormat, new Date());
});

onBeforeMount(async () => {
  tenantName.value = route.params.tenantName;

  try {
    const hasFirefighterInUrl = window.location.hash.includes('username');

    if (hasFirefighterInUrl) {
      showAddFirefighterModal.value = true;
    }
  } catch (error) {
    return;
  }

  let data: TenantInformation;
  try {
    const tenant: TenantRepresentation = await fetchMyTenantPermissions();

    Object.assign(tenantPermissions, tenant.permissions);
    data = await fetchTenant(tenantName.value);

    isApprover.value = tenantPermissions.includes('approver');

    apiCall.loaded = true;

    Object.assign(tenantInfo, data);
  } catch (error) {
    console.log('error', error);
  }
});

const toast = (variant: string, title: string, message: string) => {
  show?.({
    props: {
      body: message,
      variant: variant as keyof BaseColorVariant,
      title,
      value: 10000,
    },
  });
};

const handleUpdateStatus = (statusCode: { status: AsyncButtonStatus; message: string }) => {
  status.status = statusCode.status;
  status.message = statusCode.message;
};

function handleRequestFirefighter(statusCode: number) {
  if (statusCode === 204) {
    toast(
      'success',
      'Firefighter Access requested',
      `Firefighter access requested for tenant ${tenantName.value}. Please ask an Approver to check
      their mails and accept the request`,
    );
  }
}

function handleAddRoleError(error: Error) {
  toast('danger', ' Could not add user to role', `${error}`);
}
function handleFirefighterError(error: Error) {
  toast('danger', ' Could not add user', `${error}`);
}

/**
 * fetchUsers loads the logged-in approver tenants from the API
 */
async function fetchUsers() {
  return (
    axios
      .get<Permission[]>(
        `/tenants/${route.params.tenantName}/users`,
        getKeycloakRequestConfig(user.xsrfToken),
      )
      .then((response) => response.data)
      // Generate a unique id so that the table does not get confused about the content of the
      // rows when adding or removing users
      .then((usersData) => usersData.map((u) => ({
        ...u,
        _uniqueId: getUniqueId(u.id, u.role, u.environment),
      })))
  );
}

async function filterMembers(type: FilterMembers) {
  activeFilter.value = type;
  expireSoon.value = '';
}

// const hasCertificationData = (tenantMember: Permission) => {
//   const { environment, role } = tenantMember;
//   return environment !== 'prod' && role !== 'escalator' && role !== 'responsible';
// };

const csvData = computed(() => enabledUsers.value.map((u) => ({
  tenant: route.params.tenantName,
  name: `${u.firstName} ${u.lastName}`,
  email: u.email,
  environment: u.environment,
  role: u.role,
  certificationExpiresAt: u.certificationData ? u.certificationData.expiresAt : 'not certified',
})));

async function loadFirefighterTable(
  fireFighterStatus: FirefighterStatus,
): Promise<FirefighterAccessRepresentation[]> {
  const response = await axios.get<FirefighterAccessRepresentation[]>(
    `/tenants/${route.params.tenantName}/firefighters?status=${fireFighterStatus}`,
    getKeycloakRequestConfig(user.xsrfToken),
  );

  return response.data.map((access) => ({
    ...access,
    startAt: new Date(access.startAt),
    revokeAt: new Date(access.revokeAt),
  }));
}

// /**
//  * Returns a list of firefighter roles a user can request
//  */
// const requestableFirefighterRoles = computed(
//   () => tenantInfo.roles.filter((r) => r.hasFirefighterRole),
// );

/**
 * Generates the firefighter confirmation message.
 */
// const confirmationFirefighter = (
//   username: string,
//   fireFighterTenantName: string,
//   role: string,
//   duration: number,
// ) => 'You are about to '
//   + `grant production access to ${username} for your `
//   + `tenant ${fireFighterTenantName} with the role ${role} for ${duration}h. `
//   + 'Do you want to continue?';

async function loadUsers() {
  const usersData = await fetchUsers();
  users.value = usersData;
  return users;
}

async function loadActiveFirefighterTable() {
  const firefighterAccessesData = (await loadFirefighterTable(FirefighterStatus.ACTIVE))
    // Filter firefighters that are in the process of being cancelled
    .filter((access) => !access.canceledAt);

  firefighterAccesses.value = firefighterAccessesData;
}

async function loadScheduledFirefighterTable() {
  const scheduledFirefighterAccessesData = await loadFirefighterTable(FirefighterStatus.SCHEDULED);
  scheduledFirefighterAccesses.value = scheduledFirefighterAccessesData;
}

/**
 * Calls Firefighter access cancellation service for both scheduled and active FF.
 * Then it removes the FF from the table.
 */
async function cancelFirefighter(data: FirefighterAccessRepresentation, scheduled: boolean) {
  await axios.put(
    `/firefighter/accesses/${data.id}/cancel`,
    {},
    getKeycloakRequestConfig(user.xsrfToken),
  );

  if (scheduled) {
    scheduledFirefighterAccesses.value = scheduledFirefighterAccesses.value.filter(
      (elem) => elem.id !== data.id,
    );
  } else {
    // eslint-disable-next-line max-len
    firefighterAccesses.value = firefighterAccesses.value.filter((elem) => elem.id !== data.id);
  }

  users.value = users.value.filter(
    // eslint-disable-next-line no-underscore-dangle
    (elem) => elem._uniqueId !== getUniqueId(data.user.id, data.role, 'prod'),
  );
  toast(
    'success',
    'Firefighter successfully canceled',
    `${data.user.email} ${scheduled ? 'scheduled' : 'active'} firefighter is canceled.`,
  );
}

function handleCancelFirefighterError(error: AxiosError<any>, scheduled: boolean) {
  let errorMessage = error;

  if (error.response && error.response.data.message) {
    errorMessage = error.response.data.message;
  }

  toast(
    'danger',
    'Cancellation failed',
    `Could not cancel the ${scheduled ? 'scheduled' : 'active'} firefighter: ${errorMessage}`,
  );
}

function addUser(newUser: Permission, silent: boolean = false) {
  users.value.unshift({
    ...newUser,
    _uniqueId: getUniqueId(newUser.id, newUser.role, newUser.environment),
  });

  if (!silent) {
    toast(
      'success',
      'User added',
      `User ${newUser.email} with role ${newUser.role} added to tenant ${tenantName.value}`,
    );
  }
}

/**
 * Callback for firefighter access.
 */
function addFirefighter(access: FirefighterAccessRepresentation, scheduled: boolean) {
  showAddFirefighterModal.value = false;

  if (scheduled) {
    scheduledFirefighterAccesses.value.unshift({
      ...access,
      startAt: new Date(access.startAt),
      revokeAt: new Date(access.revokeAt),
    });

    toast(
      'success',
      'Firefighter access scheduled',
      `Access will be granted to ${access.user.email} at ${access.startAt}`,
    );
  } else {
    firefighterAccesses.value.unshift({
      ...access,
      startAt: new Date(access.startAt),
      revokeAt: new Date(access.revokeAt),
    });

    toast(
      'success',
      'Firefighter access granted',
      `Access granted to ${access.user.email} until ${access.revokeAt}`,
    );

    // Push into the users array to prevent additional firefighter addition
    addUser(
      {
        id: access.user.id,
        enabled: true,
        group: `${access.tenant}.${access.role}.prod`,
        groupId: 'n/a',
        username: access.user.email,
        firstName: access.user.firstName,
        lastName: access.user.lastName,
        email: access.user.email,
        environment: 'prod',
        role: access.role,
      },
      true,
    );
  }
}

const tableNameOffboard = 'offboarded-members';
</script>

<style lang="scss" scoped>
.modal-cta {
  cursor: pointer;
}

.table.b-table th::before {
  display: none !important;
}
</style>
