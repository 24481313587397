import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock, createElementVNode as _createElementVNode, isRef as _isRef, withCtx as _withCtx, createTextVNode as _createTextVNode, toDisplayString as _toDisplayString, resolveComponent as _resolveComponent } from "vue"

const _hoisted_1 = { id: "addFirefighter" }
const _hoisted_2 = { class: "row" }
const _hoisted_3 = { class: "col-offset-2 col-12" }
const _hoisted_4 = { class: "form-group" }
const _hoisted_5 = { class: "row-cols-md-8" }
const _hoisted_6 = {
  key: 0,
  style: {"position":"absolute","top":"25px","right":"36px","z-index":"1000"}
}
const _hoisted_7 = {
  key: 1,
  class: "invalid-feedback d-block"
}
const _hoisted_8 = {
  key: 2,
  id: "ffUserNotFoundError",
  class: "invalid-feedback d-block"
}
const _hoisted_9 = { class: "row-cols-md-8" }
const _hoisted_10 = {
  key: 0,
  class: "invalid-feedback d-block"
}
const _hoisted_11 = { class: "form-row form-group mt-1" }
const _hoisted_12 = { class: "col-6" }
const _hoisted_13 = {
  key: 0,
  class: "invalid-feedback d-block"
}
const _hoisted_14 = { class: "col-6" }
const _hoisted_15 = {
  key: 0,
  class: "invalid-feedback d-block"
}
const _hoisted_16 = { class: "form-row form-group" }
const _hoisted_17 = {
  key: 0,
  class: "invalid-feedback d-block"
}
const _hoisted_18 = {
  key: 0,
  id: "scheduledFirefighterPicker",
  class: "form-row form-group"
}
const _hoisted_19 = { class: "col" }
const _hoisted_20 = {
  key: 0,
  class: "invalid-feedback d-block"
}
const _hoisted_21 = { class: "col" }
const _hoisted_22 = {
  key: 0,
  class: "invalid-feedback d-block"
}
const _hoisted_23 = { class: "d-flex flex-row-reverse gap-2 mt-4" }
const _hoisted_24 = { class: "mb-4" }
const _hoisted_25 = { key: 0 }

import {
  computed, nextTick, onBeforeMount, PropType, reactive, ref, watch,
  watchEffect,
} from 'vue';
import {
  BAlert, BButton, BCollapse, BSpinner, BFormTextarea, BFormCheckbox, BContainer,
  BRow, BTooltip, BFormInput, BTable,
} from 'bootstrap-vue-next';
import axios from 'axios';
import { parseISO } from 'date-fns';
import { formatInTimeZone, zonedTimeToUtc } from 'date-fns-tz';
import { useRoute } from 'vue-router';
import Autocomplete from '@trevoreyre/autocomplete-vue';
import AsyncButton from '@/components/AsyncButton.vue';
import DropDownListComponent from '@/components/DropDownListComponent.vue';
import { getKeycloakRequestConfig } from '@/utils/http';
import { AsyncButtonStatus } from '@/models/asyncButton';
import { Permission } from '@/models/keycloak';
import { SelectOptionsDropDown, TenantInformation } from '@/models/tenant';
import useTenantComponent from '@/composable/tenantComponent';
import Validator from '@/utils/validator';
import { FirefighterAccessRepresentation } from '@/models/firefighter';
import { userStore } from '@/store/modules/user';
import { BackendError } from '@/models/errors';
import { useAsyncButton } from '@/composable/apiStates';
import { Suggestion } from '@/models/typeahead';
import { useTenantRoles } from '@/composable/useTenantRoles';
import { createDuration } from '@/composable/durationSelector';
import Utils from '@/utils/utils';


export default /*@__PURE__*/_defineComponent({
  __name: 'AddUserToTenantFirefighter',
  props: {
  tenantName: {
    type: String,
    required: true,
  },
  tenantInfo: {
    type: Object as PropType<TenantInformation>,
    required: true,
  },
  tenantMembers: {
    type: Array,
    required: true,
  },
  visible: {
    type: Boolean,
    required: true,
  },
  successfulEvent: {
    type: Function,
    required: true,
  },
  firefighterAccesses: {
    type: Object as PropType<FirefighterAccessRepresentation[]>,
    required: true,
  },
},
  emits: ['success', 'error', 'successfulEvent', 'hide'],
  setup(__props, { emit: __emit }) {


const props = __props;

const emit = __emit;

const route = useRoute();
const user = userStore();
const scheduledTime = ref('08:00');
const isGrantNow = ref(true);
const scheduledDate = ref(null);
const timeZone = ref(null);
const localTimeZone = Intl.DateTimeFormat()
  .resolvedOptions().timeZone || 'UTC';
const status = useAsyncButton();
const emailAutocomplete = ref(null);
const { roleOptions } = useTenantRoles(props.tenantInfo.roles, true);
const error: BackendError = reactive({
  type: null,
  message: null,
});
let { userTypeaheadSuggestions } = useTenantComponent();
const {
  username, duration, reason, typeaheadProcess,
  usernameIsValid, showUserMissing, getTypeaheadUser,
  role, roleIsValid,
} = useTenantComponent();

const userIsTenantMember = computed(() => props.tenantMembers
  .map((m: any) => m.username)
  .includes(username.value));

const { durationOptions } = createDuration();

const scheduledTimestampIsValid = computed(() => {
  if (isGrantNow.value) {
    return true;
  }
  return Validator.isTimestampValid(scheduledDate.value, scheduledTime.value);
});

const scheduledDateIsValid = computed(() => {
  if (isGrantNow.value) {
    return true;
  }
  return Validator.isDateIsValid(scheduledDate.value);
});

const userNotFound = ref(false);

// Debounced check for user existence
const debouncedCheckUser = Utils.debounce(async (input: string) => {
  if (input.length >= 3) {
    userTypeaheadSuggestions = await getTypeaheadUser(input);
    const found = showUserMissing(userTypeaheadSuggestions);
    userNotFound.value = !found;
  } else {
    userNotFound.value = false;
  }
}, 100);

const updateRole = (selectedRole: string) => {
  role.value = selectedRole;
  status.status = AsyncButtonStatus.INITIAL;
  status.message = null;
  error.type = null;
  error.message = null;
};

const updateDuration = (selectedDuration: string) => {
  // @ts-ignore
  duration.value = selectedDuration;
};

const getScheduledString = () => {
  if (!scheduledTimestampIsValid.value) {
    return '';
  }

  return `${scheduledDate.value} ${scheduledTime.value} (${timeZone.value})`;
};

const hasActiveFirefighterAccess = computed(() => {
  if (props.firefighterAccesses === undefined) return false;

  if (!role || !username) return false;

  return (
    props.firefighterAccesses.find(
      (access) => access.user.email === username.value && access.role === role.value,
    ) !== undefined
  );
});

const scheduledTimeIsValid = computed(() => {
  if (isGrantNow.value) {
    return true;
  }
  return Validator.isTimeIsValid(scheduledTime.value);
});

const durationIsValid = computed(() => {
  if (duration.value !== SelectOptionsDropDown.DURATION) {
    // @ts-ignore
    return duration.value > 0;
  }
  return false;
});

const reasonIsValid = computed(() => {
  if (reason.value != null) {
    return reason.value.trim().length > 0;
  }
  return false;
});

// Watch for changes in scheduledTime
// eslint-disable-next-line @typescript-eslint/no-unused-vars
watch(scheduledTime, (newValue, oldValue) => {
  // Remove seconds from scheduledTime on change
  scheduledTime.value = newValue ? newValue.split(':')
    .slice(0, 2)
    .join(':')
    : scheduledTime.value;
});

/**
* Resets the time zone to the users local timezone
*/
function resetTimeZone() {
  timeZone.value = Intl.DateTimeFormat()
    .resolvedOptions().timeZone || 'UTC';
}

/**
* Parses and decodes an anchor part of an URL (window.location.hash style) to a map.
* Example: '#key1=value1&key2=value2' -> { key: value1, key2: value2 }
* @param anchor part of a URL encoded key value pairs
* @returns key/value map
*/
function parseAnchor(encodedAnchorPart: string) {
  return encodedAnchorPart
    .slice(1)
    .split('&')
    .reduce((acc, pair) => {
      const [, key, value] = /^([^=]*)=(.*)$/g.exec(pair);
      acc[key] = decodeURIComponent(value);
      return acc;
    }, {});
}

async function onUpdateEmail(input: any) {
  userNotFound.value = false;
  if (input === null) {
    username.value = null;
    userNotFound.value = true;
  } else {
    username.value = input.target.value;
    debouncedCheckUser(username.value);
  }
}

async function onSelectEmail(input: Suggestion) {
  username.value = input.username;
}

const updateStatus = (statusBtn: AsyncButtonStatus, message: string) => {
  status.status = statusBtn;
  status.message = message;
  if (status.status === AsyncButtonStatus.INITIAL) {
    role.value = SelectOptionsDropDown.ROLE;
    username.value = '';
    emailAutocomplete.value.setValue({ username: '' });
    duration.value = SelectOptionsDropDown.DURATION;
    reason.value = '';
    scheduledTime.value = '';
    scheduledDate.value = '';
  }
};

onBeforeMount(async () => {
  resetTimeZone();
  // pre-populates the fields with values of hash parameters
  nextTick(async () => {
    let prefill: {
          username: string;
          role: string;
          duration: string;
          reason: string;
          startNow: string;
          startAt: string;
          timeZone: string;
        };

    try {
      prefill = parseAnchor(window.location.hash) as any;
    } catch (err) {
      // this can happen if there is nothing to parse in the URL
      return;
    }
    if (prefill.username) {
      const suggestions = await getTypeaheadUser(prefill.username);
      userTypeaheadSuggestions.push(...suggestions);
      username.value = prefill.username;
      emailAutocomplete.value.setValue({ username: prefill.username });

      // If the prefill includes a time zone, use that instead
      if (prefill.timeZone) {
        timeZone.value = prefill.timeZone;
      }

      try {
        const startAt = parseISO(prefill.startAt);
        scheduledTime.value = formatInTimeZone(startAt, timeZone.value, 'HH:mm');
        scheduledDate.value = formatInTimeZone(startAt, timeZone.value, 'yyyy-MM-dd');
      } catch (err) {
        scheduledDate.value = null;
        scheduledTime.value = '08:00';
      }

      const isValidRoleOption = roleOptions.value.map((o) => o.title).includes(prefill.role);
      if (isValidRoleOption) {
        role.value = prefill.role;
      }
      reason.value = prefill.reason;
      // @ts-ignore
      duration.value = parseInt(prefill.duration, 10);
      isGrantNow.value = prefill.startNow === 'true';
    }
  });
});

/**
* Resets all form fields
*/
function reset() {
  console.log('reset');
  // Reset form fields
  role.value = SelectOptionsDropDown.ROLE;
  username.value = '';
  if (emailAutocomplete.value) {
    emailAutocomplete.value.setValue({ username: '' });
  }

  duration.value = SelectOptionsDropDown.DURATION;
  reason.value = '';
  scheduledTime.value = '';
  scheduledDate.value = '';

  // Reset time zone in case it was changed, mainly from the prefill URL
  resetTimeZone();
}

function onHide() {
  reset();
}

async function search(input: string): Promise<Suggestion[]> {
  if (input.length < 3) {
    username.value = null;
    return [];
  }
  username.value = input;
  userTypeaheadSuggestions = await getTypeaheadUser(input);
  return userTypeaheadSuggestions;
}

async function sendForm() {
  const suggestion = userTypeaheadSuggestions.find((u) => u.username === username.value);

  const url = `/firefighter/tenants/${route.params.tenantName}/users/${suggestion.userId}/roles/${role.value}`;
  const body = {
    duration: Number(duration.value),
    reason: reason.value,
    startNow: isGrantNow.value,
    startAt: isGrantNow.value
      ? undefined
      : zonedTimeToUtc(
        `${scheduledDate.value} ${scheduledTime.value}:00`,
        timeZone.value,
      )
        .toISOString(),
    timeZone: timeZone.value,
  };
  try {
    const response = await axios.put<Permission>(
      url,
      body,
      getKeycloakRequestConfig(user.xsrfToken),
    );
    resetTimeZone();
    if (props.successfulEvent) {
      props.successfulEvent(response.data, !isGrantNow.value);
      emit('successfulEvent', response.data, !isGrantNow.value);

      reset();
    }
  } catch (err) {
    if (err.response && err.response.data) {
      error.message = err.response.data.message;
      emit('error', error.message);
    }
    throw error;
  }
}

const formIsValid = computed(() => {
  let hasActiveFirefighter = hasActiveFirefighterAccess.value;

  if (!isGrantNow.value) {
    // When trying to schedule a firefighter, it should
    // not matter whether the user already has the role
    //
    // In case of conflicts, the backend will throw an
    // error for this.
    hasActiveFirefighter = false;
  }

  return (
    usernameIsValid.value
    && !hasActiveFirefighter
    && roleIsValid.value
    && durationIsValid.value
    && reasonIsValid.value
    && scheduledDateIsValid.value
    && scheduledTimeIsValid.value
    && scheduledTimestampIsValid.value
    && !userNotFound.value
  );
});

watch(() => props.visible, () => {
  if (!props.visible) {
    reset();
  }
});

const showAddButton = computed(() => formIsValid.value);


return (_ctx: any,_cache: any) => {
  const _component_font_awesome_icon = _resolveComponent("font-awesome-icon")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _createElementVNode("div", null, [
          _createElementVNode("div", _hoisted_4, [
            _createElementVNode("div", _hoisted_5, [
              _createVNode(_unref(Autocomplete), {
                ref_key: "emailAutocomplete",
                ref: emailAutocomplete,
                "base-class": "uc-autocomplete",
                placeholder: "Email address",
                search: search,
                "get-result-value": (u) => u.username,
                onSubmit: onSelectEmail,
                onChange: onUpdateEmail
              }, null, 8, ["get-result-value"]),
              (_unref(typeaheadProcess))
                ? (_openBlock(), _createElementBlock("div", _hoisted_6, [
                    (_unref(typeaheadProcess))
                      ? (_openBlock(), _createBlock(_unref(BSpinner), {
                          key: 0,
                          label: "Loading...",
                          small: ""
                        }))
                      : _createCommentVNode("", true)
                  ]))
                : _createCommentVNode("", true),
              (!_unref(usernameIsValid))
                ? (_openBlock(), _createElementBlock("div", _hoisted_7, " Please enter a valid email address. "))
                : _createCommentVNode("", true),
              (_unref(usernameIsValid) && userNotFound.value)
                ? (_openBlock(), _createElementBlock("div", _hoisted_8, " We couldn't find any users with this email address. "))
                : _createCommentVNode("", true)
            ]),
            _cache[7] || (_cache[7] = _createElementVNode("br", null, null, -1)),
            _createElementVNode("div", _hoisted_9, [
              _createVNode(DropDownListComponent, {
                id: "roleSelectorFirefighter",
                modelValue: _unref(role),
                "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => (_isRef(role) ? (role).value = $event : null)),
                class: "mb-3",
                "default-option": _unref(SelectOptionsDropDown).ROLE,
                options: _unref(roleOptions),
                onSelectValue: updateRole
              }, null, 8, ["modelValue", "default-option", "options"]),
              (!_unref(roleIsValid))
                ? (_openBlock(), _createElementBlock("div", _hoisted_10, " You need to choose a role that should be granted to the user. "))
                : _createCommentVNode("", true)
            ])
          ]),
          _createElementVNode("div", _hoisted_11, [
            _createVNode(_unref(BRow), null, {
              default: _withCtx(() => [
                _createElementVNode("div", _hoisted_12, [
                  _createVNode(DropDownListComponent, {
                    modelValue: _unref(duration),
                    "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => (_isRef(duration) ? (duration).value = $event : null)),
                    name: "duration",
                    class: "mb-3",
                    options: _unref(durationOptions),
                    "default-option": _unref(SelectOptionsDropDown).DURATION,
                    onSelectValue: updateDuration
                  }, null, 8, ["modelValue", "options", "default-option"]),
                  (!durationIsValid.value)
                    ? (_openBlock(), _createElementBlock("div", _hoisted_13, " Please enter a duration. "))
                    : _createCommentVNode("", true)
                ]),
                _createElementVNode("div", _hoisted_14, [
                  _createVNode(_unref(BFormTextarea), {
                    modelValue: _unref(reason),
                    "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => (_isRef(reason) ? (reason).value = $event : null)),
                    placeholder: "Reason..."
                  }, null, 8, ["modelValue"]),
                  (!reasonIsValid.value)
                    ? (_openBlock(), _createElementBlock("div", _hoisted_15, " Please enter a reason. "))
                    : _createCommentVNode("", true)
                ])
              ]),
              _: 1
            })
          ]),
          _createElementVNode("div", _hoisted_16, [
            _createVNode(_unref(BFormCheckbox), {
              modelValue: isGrantNow.value,
              "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((isGrantNow).value = $event)),
              switch: "",
              name: "isGrantNow"
            }, {
              default: _withCtx(() => _cache[8] || (_cache[8] = [
                _createTextVNode(" Grant now ")
              ])),
              _: 1
            }, 8, ["modelValue"]),
            (!scheduledTimestampIsValid.value)
              ? (_openBlock(), _createElementBlock("div", _hoisted_17, " The chosen date and time need to be valid and in the future. "))
              : _createCommentVNode("", true)
          ]),
          (!isGrantNow.value)
            ? (_openBlock(), _createElementBlock("div", _hoisted_18, [
                _createVNode(_unref(BContainer), null, {
                  default: _withCtx(() => [
                    _createVNode(_unref(BRow), null, {
                      default: _withCtx(() => [
                        _createElementVNode("p", {
                          id: "time-zone",
                          class: "text-muted",
                          onClick: resetTimeZone
                        }, [
                          _createTextVNode(" Time zone: " + _toDisplayString(timeZone.value) + " ", 1),
                          (timeZone.value !== _unref(localTimeZone))
                            ? (_openBlock(), _createBlock(_component_font_awesome_icon, {
                                key: 0,
                                icon: "exclamation-triangle",
                                color: "orange",
                                class: "ms-1"
                              }))
                            : _createCommentVNode("", true)
                        ]),
                        (timeZone.value !== _unref(localTimeZone))
                          ? (_openBlock(), _createBlock(_unref(BTooltip), {
                              key: 0,
                              target: "time-zone",
                              triggers: "hover"
                            }, {
                              default: _withCtx(() => _cache[9] || (_cache[9] = [
                                _createTextVNode(" You followed a firefighter request link with a different time zone. Click to use your local time zone instead ")
                              ])),
                              _: 1
                            }))
                          : _createCommentVNode("", true)
                      ]),
                      _: 1
                    })
                  ]),
                  _: 1
                }),
                _createElementVNode("div", _hoisted_19, [
                  _createVNode(_unref(BFormInput), {
                    id: "datePicker",
                    modelValue: scheduledDate.value,
                    "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event: any) => ((scheduledDate).value = $event)),
                    type: "date",
                    name: "scheduledDate",
                    locale: "en"
                  }, null, 8, ["modelValue"]),
                  (!scheduledDateIsValid.value)
                    ? (_openBlock(), _createElementBlock("div", _hoisted_20, " Please enter a valid date "))
                    : _createCommentVNode("", true)
                ]),
                _createElementVNode("div", _hoisted_21, [
                  _createVNode(_unref(BFormInput), {
                    id: "scheduled-time",
                    modelValue: scheduledTime.value,
                    "onUpdate:modelValue": _cache[5] || (_cache[5] = ($event: any) => ((scheduledTime).value = $event)),
                    type: "time",
                    "button-only": "",
                    right: "",
                    "now-button": "",
                    "label-now-button": "Now",
                    name: "scheduledTime",
                    "aria-controls": "scheduled-time"
                  }, null, 8, ["modelValue"]),
                  (!scheduledTimeIsValid.value)
                    ? (_openBlock(), _createElementBlock("div", _hoisted_22, " Please enter a valid time (HH:mm) "))
                    : _createCommentVNode("", true)
                ])
              ]))
            : _createCommentVNode("", true),
          _createElementVNode("div", _hoisted_23, [
            _createVNode(AsyncButton, {
              id: "addFirefighterAction",
              size: "md",
              variant: "primary",
              class: "ml-4",
              name: `Add Firefighter`,
              "initial-icon": "user-plus",
              "confirm-modal": `Confirm`,
              disabled: !showAddButton.value,
              "confirm-modal-config": 'No message',
              fn: () => sendForm(),
              "modal-size": "lg",
              onStatus: updateStatus
            }, {
              default: _withCtx(() => [
                _createElementVNode("div", _hoisted_24, [
                  _cache[11] || (_cache[11] = _createElementVNode("p", null, " Please take a few seconds to review the information. You are about to grant the following critical production access ", -1)),
                  _createVNode(_unref(BTable), {
                    small: "",
                    fields: [
                    { key: 'username', label: 'User' },
                    { key: 'tenantName', label: 'Tenant' },
                    { key: 'role', label: 'Role' },
                    { key: 'duration', label: 'Duration' },
                  ],
                    items: [{
                    username: _unref(username), tenantName: __props.tenantName, role: _unref(role), duration: _unref(duration),
                  }]
                  }, null, 8, ["items"]),
                  (!isGrantNow.value && scheduledTimestampIsValid.value)
                    ? (_openBlock(), _createElementBlock("p", _hoisted_25, " The access will be granted at " + _toDisplayString(getScheduledString()) + ". ", 1))
                    : _createCommentVNode("", true),
                  _createVNode(_unref(BAlert), {
                    id: "external-member-warning",
                    "model-value": !userIsTenantMember.value,
                    variant: "danger"
                  }, {
                    default: _withCtx(() => _cache[10] || (_cache[10] = [
                      _createElementVNode("strong", null, "Attention:", -1),
                      _createTextVNode(" User does not have any roles in your tenant. Please take this into account when deciding whether to grant the Firefighter access. Granting Firefighter access to a user who is not currently in the tenant will also grant them temporary access to non-production stages and resources. ")
                    ])),
                    _: 1
                  }, 8, ["model-value"]),
                  _cache[12] || (_cache[12] = _createElementVNode("p", null, "Do you want to continue?", -1))
                ])
              ]),
              _: 1
            }, 8, ["disabled", "fn"]),
            _createVNode(_unref(BButton), {
              variant: "secondary",
              onClick: _cache[6] || (_cache[6] = ($event: any) => {onHide(); emit('hide')})
            }, {
              default: _withCtx(() => _cache[13] || (_cache[13] = [
                _createTextVNode(" Cancel ")
              ])),
              _: 1
            })
          ])
        ])
      ])
    ])
  ]))
}
}

})