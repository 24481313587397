import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, createVNode as _createVNode, createTextVNode as _createTextVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, createElementVNode as _createElementVNode, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "d-flex flex-row align-items-center flex-md-column gap-2" }
const _hoisted_2 = { class: "d-flex flex-row align-items-center flex-md-column gap-2" }

import { computed } from 'vue';
import {
  BNavbar, BCollapse, BNavbarNav, BNavbarToggle, BNavItem,
} from 'bootstrap-vue-next';
import Config from '@/utils/config';
import { userStore } from '@/store/modules/user';
import { usePermissions } from '@/store/modules/usePermissions';
import Login from './LoginComponent.vue';
import Logout from './LogoutComponent.vue';


export default /*@__PURE__*/_defineComponent({
  __name: 'HeaderComponent',
  setup(__props) {

const user = userStore();
const permissions = usePermissions();

const loggedIn = computed(() => user.loggedIn);

const bifrostUrl = `${Config.BIFROST_URL}/auth/realms/digitalunit/account/`;


return (_ctx: any,_cache: any) => {
  const _component_font_awesome_icon = _resolveComponent("font-awesome-icon")!

  return (_openBlock(), _createElementBlock("header", null, [
    _createVNode(_unref(BNavbar), {
      toggleable: "md",
      type: "dark",
      variant: "dark",
      fixed: "top",
      class: "navbar-dark px-3"
    }, {
      default: _withCtx(() => [
        _createVNode(_unref(BNavbarToggle), { target: "nav-collapse" }),
        _createVNode(_unref(BCollapse), {
          id: "nav-collapse",
          "is-nav": ""
        }, {
          default: _withCtx(() => [
            _createVNode(_unref(BNavbarNav), null, {
              default: _withCtx(() => [
                _createVNode(_unref(BNavItem), {
                  class: "navbar-brand",
                  to: "/",
                  "active-class": "navbar-brand active"
                }, {
                  default: _withCtx(() => _cache[0] || (_cache[0] = [
                    _createTextVNode(" MPS-NotAPortal ")
                  ])),
                  _: 1
                }),
                (loggedIn.value)
                  ? (_openBlock(), _createBlock(_unref(BNavItem), {
                      key: 0,
                      class: "nav-link",
                      to: "/permissions"
                    }, {
                      default: _withCtx(() => _cache[1] || (_cache[1] = [
                        _createTextVNode(" My Permissions ")
                      ])),
                      _: 1
                    }))
                  : _createCommentVNode("", true),
                (loggedIn.value)
                  ? (_openBlock(), _createBlock(_unref(BNavItem), {
                      key: 1,
                      class: "nav-link",
                      to: "/tenants"
                    }, {
                      default: _withCtx(() => _cache[2] || (_cache[2] = [
                        _createTextVNode(" My Tenants ")
                      ])),
                      _: 1
                    }))
                  : _createCommentVNode("", true),
                (loggedIn.value && _unref(permissions).isApprover)
                  ? (_openBlock(), _createBlock(_unref(BNavItem), {
                      key: 2,
                      class: "nav-link",
                      to: "/bulk-permission-management"
                    }, {
                      default: _withCtx(() => _cache[3] || (_cache[3] = [
                        _createTextVNode(" Multi-Tenant Actions ")
                      ])),
                      _: 1
                    }))
                  : _createCommentVNode("", true),
                _createVNode(_unref(BNavItem), {
                  class: "ms-3 d-none d-md-block",
                  style: {"border-left":"2px solid grey"}
                }),
                _createVNode(_unref(BNavItem), {
                  target: "_blank",
                  rel: "noopener noreferrer",
                  class: "nav-link ms-md-2",
                  href: bifrostUrl
                }, {
                  default: _withCtx(() => _cache[4] || (_cache[4] = [
                    _createTextVNode(" Bifröst ")
                  ])),
                  _: 1
                })
              ]),
              _: 1
            }),
            _createVNode(_unref(BNavbarNav), { class: "ms-auto" }, {
              default: _withCtx(() => [
                (loggedIn.value)
                  ? (_openBlock(), _createBlock(_unref(BNavItem), {
                      key: 0,
                      class: "nav-link text-md-center",
                      to: "/firefighter"
                    }, {
                      default: _withCtx(() => [
                        _createElementVNode("div", _hoisted_1, [
                          _createVNode(_component_font_awesome_icon, {
                            icon: "fire-alt",
                            class: "text-danger",
                            size: "2x"
                          }),
                          _cache[5] || (_cache[5] = _createElementVNode("div", { class: "text-danger" }, " Firefighter ", -1))
                        ])
                      ]),
                      _: 1
                    }))
                  : _createCommentVNode("", true),
                (loggedIn.value)
                  ? (_openBlock(), _createBlock(_unref(BNavItem), {
                      key: 1,
                      class: "nav-link text-md-center",
                      to: "/profile"
                    }, {
                      default: _withCtx(() => [
                        _createElementVNode("div", _hoisted_2, [
                          _createVNode(_component_font_awesome_icon, {
                            icon: "user-circle",
                            size: "2x"
                          }),
                          _cache[6] || (_cache[6] = _createElementVNode("div", null, "Profile", -1))
                        ])
                      ]),
                      _: 1
                    }))
                  : _createCommentVNode("", true),
                _createVNode(_unref(BNavItem), { class: "my-auto" }, {
                  default: _withCtx(() => [
                    (!loggedIn.value)
                      ? (_openBlock(), _createBlock(Login, { key: 0 }))
                      : _createCommentVNode("", true),
                    (loggedIn.value)
                      ? (_openBlock(), _createBlock(Logout, { key: 1 }))
                      : _createCommentVNode("", true)
                  ]),
                  _: 1
                })
              ]),
              _: 1
            })
          ]),
          _: 1
        })
      ]),
      _: 1
    })
  ]))
}
}

})