<template>
  <div
    id="onboarding-wizard"
    class="container"
  >
    <BRow>
      <BCol class="col-offset-2">
        <h3 class="mt-4">
          Multi-tenant onboarding
          <BButton
            v-show="step > 0 && step < steps.length - 1"
            :icon="true"
            pill
            size="sm"
            class="mb-0"
            variant="outline-primary"
            style="margin-top: -2px;"
            @click="step = 0"
          >
            Help
            <font-awesome-icon icon="question-circle" />
          </BButton>
          <a
            v-show="step > 2"
            :href="`mailto:mps-product-ma${cheapEmailObfuscation}`"
            :icon="false"
            class="btn btn-outline-primary btn-sm rounded-pill mb-0 ms-1"
            variant="outline-primary"
            style="margin-top: -2px;"
          >
            Share your feedback with us!
            <font-awesome-icon icon="comment" />
          </a>
        </h3>

        <p class="lead">
          Simultaneously onboard multiple users across the tenants you manage
        </p>

        <BAlert
          variant="warning"
          :model-value="true"
        >
          <div><strong>Important Information</strong></div>

          There is currently a known issue where user certification might be missing when
          assigning the same user to many tenants.
          Please check the user certification in the onboarded tenants after the process
          is finished while we are working on a fix.
        </BAlert>

        <BOverlay
          v-for="(s, index) in steps"
          v-show="step === index"
          :key="s.name"
          :show="tenantStore.loading && step !== 0"
        >
          <BCard class="mt-2 mb-2">
            <BContainer>
              <h4>{{ s.name }}</h4>
              <p>{{ s.description }}</p>

              <component :is="s.component" />
            </BContainer>
          </BCard>
        </BOverlay>

        <BButtonToolbar
          justify
          class="mt-1 mb-2"
        >
          <BButtonGroup class="mx-0">
            <BButton
              v-show="step > 1 && step < steps.length - 1"
              @click="back"
            >
              Back
            </BButton>
          </BButtonGroup>
          <BButtonGroup
            v-show="step < steps.length - 2"
            class="mx-0"
          >
            <BButton
              variant="primary"
              @click="next"
            >
              Next
              <span
                v-show="step >= 1"
                class="badge"
              >
                {{ step }}/{{ steps.length - 2 }}
              </span>
            </BButton>
          </BButtonGroup>
          <BButtonGroup
            v-show="step === steps.length - 2"
            class="mx-0"
          >
            <BButton
              variant="danger"
              :disabled="!isValid"
              @click="startExecution"
            >
              Assign permissions
              <span
                v-show="step >= 1"
                class="badge text-bg-secondary"
              >
                {{ step }}/{{ steps.length - 2 }}
              </span>
            </BButton>
          </BButtonGroup>
          <BButtonGroup
            v-show="step === steps.length - 1"
            class="mx-0"
          >
            <BButton
              variant="primary"
              :disabled="!done"
              @click="reset"
            >
              Start over
            </BButton>
          </BButtonGroup>
        </BButtonToolbar>
      </BCol>
    </BRow>
  </div>
</template>

<script setup lang="ts">

import BulkIntroduction from '@/components/BulkIntroduction.vue';
import { storeToRefs } from 'pinia';
import { onBeforeMount } from 'vue';
import {
  BContainer, BCard, BButton, BButtonGroup, BButtonToolbar,
  BOverlay, BCol, BRow, BAlert,
} from 'bootstrap-vue-next';
import BulkRun from '@/components/BulkRun.vue';
import BulkReview from '@/components/BulkReview.vue';
import BulkSelectUsers from '@/components/BulkSelectUsers.vue';
import BulkSelectTenantRoles from '@/components/BulkSelectTenantRoles.vue';
import { useTenants } from '@/store/modules/useTenants';
import { useBulkPermissionWizard } from '@/store/modules/useBulkPermissionWizard';
import { useBulkPermissionAssignment } from '@/store/modules/useBulkPermissionAssignment';
import { useBulkPermissionManagement } from '@/store/modules/useBulkPermissionManagement';

const store = useBulkPermissionWizard();
const {
  step, skipIntro, done, isValid,
} = storeToRefs(store);

const tenantStore = useTenants();

const assignmentStore = useBulkPermissionAssignment();

const permissionStore = useBulkPermissionManagement();

const cheapEmailObfuscation = 'nagement@vwfs.io?subject=MPS Portal Feedback: Bulk Permission Management&body=Hi MPS Team, I have some feedback for you:';

const next = () => {
  step.value += 1;
};

const back = () => {
  step.value -= 1;
};

const startExecution = () => {
  next();
  assignmentStore.assignPermissions();
};

const steps = [
  {
    name: 'How does it work?',
    description: '',
    component: BulkIntroduction,
  },
  {
    name: 'Select users',
    description: 'Choose all users you want to onboard to the same roles. You can select all users with an active bifrost account.',
    component: BulkSelectUsers,
  },
  {
    name: 'Select roles',
    description: 'On this page you see all tenants that you can manage. Select roles you want to assign to the users from the previous step.',
    component: BulkSelectTenantRoles,
  },
  {
    name: 'Review',
    description: 'Check all permissions below. If you are happy with the result, click on "Assign permissions" to start the onboarding process.',
    component: BulkReview,
  },
  {
    name: 'Execution',
    description: 'The onboarding process has started. You can see the progress below. Don\'t close this page until the process is finished.',
    component: BulkRun,
  },
];

const reset = async () => {
  store.$reset();
  assignmentStore.$reset();
  permissionStore.$reset();

  step.value = skipIntro.value ? 1 : 0;

  await tenantStore.load();
};

onBeforeMount(() => {
  reset();
});
</script>
