import { defineComponent as _defineComponent } from 'vue'
import { createTextVNode as _createTextVNode, unref as _unref, withCtx as _withCtx, createVNode as _createVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createBlock as _createBlock } from "vue"

const _hoisted_1 = {
  id: "settings",
  class: "container"
}
const _hoisted_2 = { class: "row" }
const _hoisted_3 = { class: "col-offset-2 col-12" }
const _hoisted_4 = { class: "mt-4" }
const _hoisted_5 = {
  key: 0,
  class: "alert alert-success",
  role: "alert"
}
const _hoisted_6 = {
  key: 1,
  class: "alert alert-danger",
  role: "alert"
}

import axios from 'axios';
import {
  computed, ComputedRef, onMounted, reactive, ref,
} from 'vue';
import { useRoute } from 'vue-router';
import {
  BBreadcrumb, BBreadcrumbItem, BFormGroup, BFormTags,
} from 'bootstrap-vue-next';
import Bubblewrap from '@/components/BubblewrapComponent.vue';
import AsyncButton from '@/components/AsyncButton.vue';
import { getKeycloakRequestConfig } from '@/utils/http';
import { AsyncButtonStatus } from '@/models/asyncButton';
import { userStore } from '@/store/modules/user';
import { useApiCall, useAsyncButton } from '@/composable/apiStates';
import { TenantSettingsRepresentation } from '../models/settings';
import Validator from '../utils/validator';


export default /*@__PURE__*/_defineComponent({
  __name: 'TenantSettings',
  setup(__props) {

const route = useRoute();
const user = userStore();
const settings: TenantSettingsRepresentation = reactive({ notificationEmails: [] });
const tagStatus = ref(false);
const successful = ref(false);
const isFormValid: ComputedRef<boolean> = computed(() => !tagStatus.value);
const apiCall = useApiCall();
const status = useAsyncButton();

function onTagState(_valid: string[], invalid: string[], duplicate: string[]) {
  tagStatus.value = invalid.length === 0 && duplicate.length === 0;
}

function emailValidator(email: string): boolean {
  return Validator.isValidEmail(email);
}

function updateStatus(updatedStatus: AsyncButtonStatus, message: string) {
  status.status = updatedStatus;
  status.message = message;
}

async function fetchSettings(): Promise<TenantSettingsRepresentation> {
  const response = await axios.get<TenantSettingsRepresentation>(
    `/tenants/${route.params.tenantName}/settings`,
    getKeycloakRequestConfig(user.xsrfToken),
  );
  // Add "notificationEmails" field to response
  if (!response.data.notificationEmails) {
    response.data.notificationEmails = [];
  }
  return response.data as TenantSettingsRepresentation;
}

async function saveSettings() {
  const response = await axios.put<TenantSettingsRepresentation>(
    `/tenants/${route.params.tenantName}/settings`,
    settings,
    getKeycloakRequestConfig(user.xsrfToken),
  );
  successful.value = response.status === 200;
}

onMounted(async () => {
  try {
    const responseData = await fetchSettings();
    Object.assign(settings, responseData);
  } catch (e) {
    apiCall.error = new Error('Could not load settings.');
  }
  apiCall.loaded = true;
  tagStatus.value = true;
});


return (_ctx: any,_cache: any) => {
  const _component_ContentLoader = _resolveComponent("ContentLoader")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _createVNode(_unref(BBreadcrumb), null, {
          default: _withCtx(() => [
            _createVNode(_unref(BBreadcrumbItem), { to: { name: 'myTenants' } }, {
              default: _withCtx(() => _cache[1] || (_cache[1] = [
                _createTextVNode(" My Tenants ")
              ])),
              _: 1
            }),
            _createVNode(_unref(BBreadcrumbItem), {
              to: { name: 'tenantDetails', params: { tenantName: _unref(route).params.tenantName } }
            }, {
              default: _withCtx(() => [
                _createTextVNode(_toDisplayString(_unref(route).params.tenantName), 1)
              ]),
              _: 1
            }, 8, ["to"]),
            _createVNode(_unref(BBreadcrumbItem), { active: "" }, {
              default: _withCtx(() => _cache[2] || (_cache[2] = [
                _createTextVNode(" Settings ")
              ])),
              _: 1
            })
          ]),
          _: 1
        }),
        _createElementVNode("h3", _hoisted_4, " Tenant Settings for " + _toDisplayString(_unref(route).params.tenantName), 1),
        _cache[5] || (_cache[5] = _createElementVNode("p", { class: "lead" }, " On this page you can manage the settings of your tenant. ", -1)),
        _createVNode(Bubblewrap, { info: _unref(apiCall) }, {
          placeholder: _withCtx(() => [
            _createVNode(_component_ContentLoader, { height: "18px" })
          ]),
          loaded: _withCtx(() => [
            (successful.value)
              ? (_openBlock(), _createElementBlock("div", _hoisted_5, " Saved settings "))
              : _createCommentVNode("", true),
            (_unref(status).status === _unref(AsyncButtonStatus).ERROR)
              ? (_openBlock(), _createElementBlock("div", _hoisted_6, " Failed to save settings "))
              : _createCommentVNode("", true),
            _cache[3] || (_cache[3] = _createElementVNode("h4", null, "Notification settings", -1)),
            _cache[4] || (_cache[4] = _createElementVNode("p", null, " If you want to have notifications about permission changes besides the approver, please add further email addresses. ", -1)),
            _createVNode(_unref(BFormGroup), { class: "mb-2" }, {
              default: _withCtx(() => [
                settings
                  ? (_openBlock(), _createBlock(_unref(BFormTags), {
                      key: 0,
                      id: "notificationEmails",
                      modelValue: settings.notificationEmails,
                      "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((settings.notificationEmails) = $event)),
                      value: settings.notificationEmails,
                      "tag-validator": emailValidator,
                      placeholder: "Enter email(s)",
                      separator: " ",
                      "invalid-tag-text": "Invalid email address",
                      "duplicate-tag-text": "Email already added",
                      "add-on-change": "",
                      input: "settings.notificationEmails = $event",
                      "tag-state": onTagState
                    }, null, 8, ["modelValue", "value"]))
                  : _createCommentVNode("", true)
              ]),
              _: 1
            }),
            _createVNode(AsyncButton, {
              id: "saveTenantSettings",
              name: "Save",
              "initial-icon": "save",
              fn: saveSettings,
              disabled: isFormValid.value,
              onStatus: updateStatus
            }, null, 8, ["disabled"])
          ]),
          _: 1
        }, 8, ["info"])
      ])
    ])
  ]))
}
}

})