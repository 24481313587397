<template>
  <div>
    <div class="form-row form-group">
      <BCol>
        <DropDownListComponent
          v-model="requestRole"
          name="role"
          :default-option="SelectOptionsDropDown.ROLE"
          :options="roleOptions"
          :class="{ 'mb-3': requestRoleIsValid, 'mb-1': !requestRoleIsValid }"
          :state="requestRoleIsValid"
        />
        <BFormInvalidFeedback
          :state="requestRoleIsValid"
          :text="'Please select a role.'"
        />
      </BCol>
    </div>
    <div class="form-row form-group">
      <BCol class="mb-3">
        <BFormTextarea
          v-model="requestReason"
          placeholder="Reason..."
          :class="{ 'mb-3': requestReasonIsValid, 'mb-1': !requestReasonIsValid }"
          :state="requestReasonIsValid"
        />
        <BFormInvalidFeedback
          :state="requestReasonIsValid"
          :text="'Please enter a reason.'"
        />
      </BCol>
    </div>
    <div class="form-row form-group">
      <BCol>
        <DropDownListComponent
          v-model="requestDuration"
          name="duration"
          :class="{ 'mb-3': requestDurationIsValid, 'mb-1': !requestDurationIsValid }"
          :default-option="SelectOptionsDropDown.DURATION"
          :options="durationOptions"
          :state="requestDurationIsValid"
          @select-value="updateDuration"
        />
        <BFormInvalidFeedback
          :state="requestDurationIsValid"
          :text="'Please enter a duration.'"
        />
      </BCol>
    </div>
    <div class="form-row form-group">
      <BFormCheckbox
        v-model="isGrantNow"
        switch
        name="isGrantNow"
        :class="{ 'mb-3': scheduledTimestampIsValid, 'mb-1': !scheduledTimestampIsValid }"
      >
        Grant now
      </BFormCheckbox>
      <BFormInvalidFeedback
        :state="scheduledTimestampIsValid"
        :text="'The chosen date and time need to be valid and in the future.'"
      />
    </div>
    <div
      v-if="!isGrantNow"
      id="scheduledFirefighterPicker"
      class="form-row form-group"
    >
      <BCol>
        <p
          id="time-zone"
          class="text-muted"
        >
          Time zone: {{ timeZone }}
        </p>
      </BCol>

      <BCol>
        <BFormInput
          id="datePicker"
          v-model="requestDate"
          type="date"
          name="requestDate"
          :class="{ 'mb-3': requestDateIsValid, 'mb-1': !requestDateIsValid }"
          :state="requestDateIsValid"
        />
        <BFormInvalidFeedback
          :state="requestDateIsValid"
          :text="'Please enter a valid date.'"
        />
      </BCol>
      <BCol>
        <BInputGroup class="mb-3">
          <BFormInput
            id="scheduled-time"
            v-model="requestTime"
            type="text"
            placeholder="HH:mm"
            :class="{ 'mb-3': requestTimeIsValid, 'mb-1': !requestTimeIsValid }"
            :state="requestTimeIsValid"
          />
          <BFormInvalidFeedback
            :state="requestTimeIsValid"
            :text="'Please enter a valid time (HH:mm)'"
          />
        </BInputGroup>
      </BCol>
    </div>
    <div class="form-row form-group mb-0">
      <BCol class="d-flex justify-content-end">
        <BButton v-if="props.hideModal !== null" id="close-ff-request-modal" class="mt-2 mx-2" secondary @click="resetForm">
          Cancel
        </BButton>
        <AsyncButton
          id="requestFireFighterBtn"
          class="mt-2 text-light"
          :name="`Request Firefighter Access`"
          initial-icon="fire-alt"
          :disabled="!formIsValid"
          :fn="sendRequest"
          size="md"
          variant="danger"
          @status="updateStatus"
        />
      </BCol>
    </div>
  </div>
</template>

<script setup lang="ts">
import axios from 'axios';
import {
  computed, PropType, ref, watch, watchEffect,
} from 'vue';
import {
  BFormTextarea, BFormCheckbox, BFormInvalidFeedback,
  BInputGroup, BFormInput, BCol, BButton,
} from 'bootstrap-vue-next';
import moment from 'moment';
import AsyncButton from '@/components/AsyncButton.vue';
import DropDownListComponent from '@/components/DropDownListComponent.vue';
import { getKeycloakRequestConfig } from '@/utils/http';
import { SelectOptionsDropDown, TenantRole } from '@/models/tenant';
import Validator from '@/utils/validator';
import { userStore } from '@/store/modules/user';
import { AsyncButtonStatus } from '@/models/asyncButton';
import { useAsyncButton } from '@/composable/apiStates';
import { createDuration } from '@/composable/durationSelector';
import { useTenantRoles } from '@/composable/useTenantRoles';
import { FirefighterAccessRequestRequest } from '../models/firefighter';

const props = defineProps({
  tenantName: {
    type: String,
    default: '',
  },
  tenantRoles: {
    type: Array as PropType<TenantRole[]>,
    required: true,
  },
  // Optional validation state in case there are more fields in the form
  validationState: {
    type: Boolean,
    required: false,
    default: true,
  },
  successfulEvent: {
    type: Function,
    required: true,
  },
  hideModal: {
    type: Function,
    required: false,
    default: null,
  },
});

const emit = defineEmits(['successfulEvent', 'error']);
const status = useAsyncButton();
const isGrantNow = ref(true);
const requestDuration = ref(SelectOptionsDropDown.DURATION);
const requestReason = ref(null);
const requestDate = ref(null);
const requestTime = ref('08:00');
const user = userStore();
const requestRole = ref(SelectOptionsDropDown.ROLE);
const timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone || 'UTC';
const { durationOptions } = createDuration();

const roleOptions = ref([]);

watch(
  () => props.tenantRoles,
  (newRoles) => {
    roleOptions.value = useTenantRoles(newRoles, true).roleOptions.value;
  },
  { immediate: true },
);

const updateDuration = (selectedDuration: string) => {
  requestDuration.value = selectedDuration as SelectOptionsDropDown;
};

const updateStatus = (statusCode: { status: AsyncButtonStatus, message: string }) => {
  status.status = statusCode.status;
  status.message = statusCode.message;
};

const requestStartAt = computed(() => {
  if (isGrantNow.value) {
    return null;
  }
  const parsedDate = moment(`${requestDate.value} ${requestTime.value}:00`, 'YYYY-MM-DD HH:mm:ss', true);

  return parsedDate.toISOString();
});

const resetForm = () => {
  requestRole.value = SelectOptionsDropDown.ROLE;
  requestReason.value = null;
  requestDuration.value = SelectOptionsDropDown.DURATION;
  requestDate.value = null;
  requestTime.value = '08:00';
  isGrantNow.value = true;
  props.hideModal?.();
};

async function sendRequest() {
  const firefighterAccessRequest: FirefighterAccessRequestRequest = {
    role: requestRole.value,
    reason: requestReason.value,
    startNow: isGrantNow.value,
    startAt: requestStartAt.value,
    timeZone,
    duration: Number(requestDuration.value),
  };

  try {
    const response = await axios.post(
      `firefighter/requests/${props.tenantName}/users/${user.id}`,
      firefighterAccessRequest,
      getKeycloakRequestConfig(user.xsrfToken),
    );
    if (props.successfulEvent) {
      props.successfulEvent(response.status);
      emit('successfulEvent', response.status);
      resetForm();
    }
  } catch (err) {
    if (err.response && err.response.data) {
      emit('error', err.response.data.message);
    }
  }
}

const requestDurationIsValid = computed(() => Number(requestDuration.value) > 0);
const requestReasonIsValid = computed(() => requestReason.value?.trim().length > 0);
const requestDateIsValid = computed(
  () => isGrantNow.value || Validator.isDateIsValid(requestDate.value),
);
const scheduledTimestampIsValid = computed(
  () => isGrantNow.value || Validator.isTimestampValid(requestDate.value, requestTime.value),
);
const requestTimeIsValid = computed(
  () => isGrantNow.value || Validator.isTimeIsValid(requestTime.value),
);
const requestRoleIsValid = computed(() => requestRole.value !== SelectOptionsDropDown.ROLE);

const formIsValid = computed(() => props.validationState === true
  && requestDurationIsValid.value
  && requestReasonIsValid.value
  && requestRoleIsValid.value
  && (isGrantNow.value || (
    requestDateIsValid.value && requestTimeIsValid.value && scheduledTimestampIsValid.value
  )));

watchEffect(() => {
  if (requestTime.value) {
    requestTime.value = requestTime.value.split(':').slice(0, 2).join(':');
  }
});
</script>
