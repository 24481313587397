import { defineComponent as _defineComponent } from 'vue'
import { createVNode as _createVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { key: 0 }
const _hoisted_2 = {
  class: "btn-group",
  role: "group",
  "aria-label": "Basic actions"
}

import axios, { AxiosError } from 'axios';
import {
  computed, PropType, reactive, ref,
} from 'vue';
import { useRoute } from 'vue-router';
import AsyncButton from '@/components/AsyncButton.vue';
import { getKeycloakRequestConfig } from '@/utils/http';
import { Permission, RecertifyResponse } from '@/models/keycloak';
import { userStore } from '@/store/modules/user';
import { BackendError } from '@/models/errors';

/**
 * This Component represents a button bar for tenant approvers to
 * perform actions on individual users in their tenant such as
 * re-certifying or removing a user.
 */


export default /*@__PURE__*/_defineComponent({
  __name: 'TenantUserActions',
  props: {
  tenantMember: {
    type: Object as PropType<Permission>,
    required: true,
  },
},
  emits: ['error', 'removed', 'recertified', 'status'],
  setup(__props, { emit: __emit }) {

const props = __props;
const emits = __emit;

const user = userStore();
const loggedInUserId = ref(user.id);
const route = useRoute();
const removalConfirmationMessage = computed(() => `You are about to remove access from ${props.tenantMember.username}
     for your tenant ${route.params.tenantName}
     with the role ${props.tenantMember.role}.
     Do you want to continue?`);
const error: BackendError = reactive({
  type: null,
  message: null,
});

const hasBasicActions = computed(() => {
  const { environment, role } = props.tenantMember;
  return (
    environment !== 'prod' && role !== 'escalator' && role !== 'responsible'
  );
});

function handleRecertificationError(axiosError: AxiosError) {
  emits('error', {
    error,
    action: 'recertification',
    title: 'Recertification failed',
    message: `Could not recertify ${props.tenantMember.username}: ${axiosError.response.data}`,
  });
}

function handleRemovalError() {
  emits('error', {
    action: 'remove',
    title: 'Removal failed',
    message: `Could not remove ${props.tenantMember.username}`,
  });
}

/**
     * This function makes a request to the mps portal api to remove a user from the
     * tenant (only for the given role).
     *
     * We do not handle errors in the promise chain because the AsyncButton takes care of this.
     * */
async function removeUser() {
  try {
    const response = await axios
      .delete(
        `/tenants/${route.params.tenantName}/users/${props.tenantMember.id}/roles/${props.tenantMember.role}`,
        getKeycloakRequestConfig(user.xsrfToken),
      );
    if (response.status === 204) {
      emits('removed', {
        title: 'Removal successful',
        message: `${props.tenantMember.username} has been removed from the role ${props.tenantMember.role}.`,
      });
    }
  } catch (err) {
    if (err.response && err.response.data) {
      error.message = err.response.data.message;
      emits('error', error.message);
    }
    throw error;
  }
}

/**
     * This function makes a call to the mps portal api to recertify a user
     * for the tenant role.
     *
     * We do not handle errors in the promise chain because the AsyncButton takes care of this.
     */
async function recertifyUser() {
  return axios
    .post<RecertifyResponse>(
      `/tenants/${route.params.tenantName}/recertification/${props.tenantMember.id}/${props.tenantMember.role}`,
      null,
      getKeycloakRequestConfig(user.xsrfToken),
    )
    .then((response) => {
      if (response.data) {
        emits('recertified', {
          title: 'Recertification successful',
          message: `${props.tenantMember.username} has been recertified for role ${props.tenantMember.role}.`,
          certificationData: {
            certifiedAt: response.data.certifiedAt,
            certifiedBy: response.data.certifiedBy,
            expiresAt: response.data.expiresAt,
            expiresIn: response.data.expiresIn,
          },
        });
      }

      return response.data;
    });
}


return (_ctx: any,_cache: any) => {
  return (hasBasicActions.value)
    ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
        _createElementVNode("div", _hoisted_2, [
          _createVNode(AsyncButton, {
            id: "recertifyUser",
            name: "Recertify",
            "initial-icon": "sync",
            variant: "secondary",
            fn: () => recertifyUser(),
            disabled: props.tenantMember.id === loggedInUserId.value,
            onError: _cache[0] || (_cache[0] = (err) => handleRecertificationError(err))
          }, null, 8, ["fn", "disabled"]),
          _createVNode(AsyncButton, {
            id: "removeUser",
            name: "Remove",
            variant: "secondary",
            "initial-icon": "trash-alt",
            "confirm-modal": removalConfirmationMessage.value,
            fn: () => removeUser(),
            onError: _cache[1] || (_cache[1] = () => handleRemovalError())
          }, null, 8, ["confirm-modal", "fn"])
        ])
      ]))
    : _createCommentVNode("", true)
}
}

})